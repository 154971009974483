import axios from 'axios'
const { REACT_APP_API_URL } = process.env;
import { createUrlParams } from 'utils/isValidate';

export const authenticateApi = async data => {
    const { ...params } = data;
    return axios.post(`${REACT_APP_API_URL}users/login`, params);
};

export const changePasswordApi = async (payload) => {
    return axios.post(`${REACT_APP_API_URL}users/change-password`, payload)
};

export const getUserProfileApi = async () => {
    return axios.get(`${REACT_APP_API_URL}users/profile`)
};

export const setUserProfileApi = async (data) => {
    return axios.patch(`${REACT_APP_API_URL}users/update`, data);
};

export const getNotificationListApi = async (queryParams) => {
    return axios.get(`${REACT_APP_API_URL}notifications/list${queryParams}`);
}

export const getNotificationStatusApi = async () => {
    return axios.get(`${REACT_APP_API_URL}users/setting`);
}

export const updateNotificationStatusApi = async (queryParams) => {
    return axios.post(`${REACT_APP_API_URL}users/upsert-setting?${createUrlParams(queryParams)}`);
}

export const fireMarkAllAsReadApi = async (data) => {
    return axios.post(`${REACT_APP_API_URL}notifications/mark-read`, data);
}

export const getPagesApi = async (slug) => {
    return axios.get(`${REACT_APP_API_URL}pages/detail/${slug}`);
}

export const forgotPasswordApi = async (payload) => {
    return axios.post(`${REACT_APP_API_URL}users/forgot-password`, payload)
};

export const sendDeviceTokenApi = async (payload) => {
    return axios.post(`${REACT_APP_API_URL}users/upsert-token`, payload)
};