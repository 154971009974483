import { createAsyncThunk } from '@reduxjs/toolkit';
import { ADD_DRIVER, DRIVER_LIST, EDIT_DRIVER, GET_JOB_LIST, DELETE_DRIVER, ASSIGN_ORDER_TO_ME, GET_ASSSUMPTION, GET_LIST_OF_LOADINGS, GET_COMPLETED_LIST_OF_LOADINGS, GET_INVOICE_LIST, GET_BANK_DETAILS, ADD_BANK_DETAILS, CANCELLED_JOBS, DELETE_CANCELLED_JOBS } from "../actionTypes"
import { addDriverApi, driverListApi, editDriverApi, deleteCancelledJobsApi, getJobListApi,cancelledJobsApi, deleteDriverApi, assignOrderApi, listOfLoadingApi, completedListOfLoadingApi, invoiceListApi, addBankDetailApi, getBankDetailApi, getLoadAssumptionsApi } from '../../services';

export const getDriverList = createAsyncThunk(DRIVER_LIST, async () => {
    const response = await driverListApi();
    return response.data;
});

export const cancelledJobs = createAsyncThunk(CANCELLED_JOBS, async () => {
    const response = await cancelledJobsApi();
    return response.data;
});

export const deleteCancelledJobs = createAsyncThunk(DELETE_CANCELLED_JOBS, async (data) => {
    try {
        const response = await deleteCancelledJobsApi(data);
        return response.data;
    } catch (error) {
        return error.response.data
    }
});


export const addDriver = createAsyncThunk(ADD_DRIVER, async (payload) => {
    try {
        const response = await addDriverApi(payload);
        return response.data;
    } catch (error) {
        return error.response.data
    }
});

export const editDriver = createAsyncThunk(EDIT_DRIVER, async (payload) => {
    try {
        const response = await editDriverApi(payload);
        return response.data;
    } catch (error) {
        return error.response.data
    }
});

export const getJobList = createAsyncThunk(GET_JOB_LIST, async (payload) => {
    try {
        const response = await getJobListApi(payload);
        return response.data;
    } catch (error) {
        return error.response.data
    }
});

export const deleteDriver = createAsyncThunk(DELETE_DRIVER, async (driverId) => {
    try {
        const response = await deleteDriverApi(driverId);
        return response.data;
    } catch (error) {
        return error.response.data
    }
});

export const assignOrderToMe = createAsyncThunk(ASSIGN_ORDER_TO_ME, async (payload) => {
    try {
        const response = await assignOrderApi(payload);
        return response.data;
    } catch (error) {
        return error.response.data
    }
});

export const getListOfLoadings = createAsyncThunk(GET_LIST_OF_LOADINGS, async (payload) => {  
    try {
        const response = await listOfLoadingApi(payload);
        return response.data;
    } catch (error) {
        return error.response.data
    }
});

export const getCompletedListOfLoadings = createAsyncThunk(GET_COMPLETED_LIST_OF_LOADINGS, async (payload) => {  
    try {
        const response = await completedListOfLoadingApi(payload);
        return response.data;
    } catch (error) {
        return error.response.data
    }
});

export const getInvoiceList = createAsyncThunk(GET_INVOICE_LIST, async (queryParams) => {  
    console.log("queryParams",queryParams);
    try {
        const response = await invoiceListApi(queryParams);
        return response.data;
    } catch (error) {
        return error.response.data
    }
});

export const getBankDetailsData = createAsyncThunk(GET_BANK_DETAILS, async (payload) => {  
    try {
        const response = await getBankDetailApi(payload);
        return response.data;
    } catch (error) {
        return error.response.data
    }
});

export const addBankDetailsData = createAsyncThunk(ADD_BANK_DETAILS, async (payload) => {  
    try {
        const response = await addBankDetailApi(payload);
        return response.data;
    } catch (error) {
        return error.response.data
    }
});

export const getLoadAssumption = createAsyncThunk(GET_ASSSUMPTION, async () => {
    const response = await getLoadAssumptionsApi();
    return response.data;
});