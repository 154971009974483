/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { useLocation, Route, Switch } from "react-router-dom";
import { useDispatch } from "react-redux";
import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import Sidebar from "components/Sidebar/Sidebar";
import { firebase, getNotifyToken } from 'services';
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import { getLoadAssumption } from "store/driver/actions";
import { sendDeviceToken } from "store/auth/actions";
import routes from "routes.js";
import sidebarImage from "assets/img/sidebar-3.jpg";
import PrivacyPolicy from "views/PrivacyPolicy";
import TermsCondition from "views/TermsCondition";
// import { getNotifyToken } from "services";

// console.log('main', main);

function Admin() {
  // firebase;
  const dispatch = useDispatch();
  const [image, setImage] = React.useState(sidebarImage);
  const [color, setColor] = React.useState("black");
  const [hasImage, setHasImage] = React.useState(true);
  const location = useLocation();
  const mainPanel = React.useRef(null);
  const GetRoutes = (props) => {
    return props.routes.map((prop, key) => {
      return (
        <Route
          path={prop.path}
          component={prop.component}
          key={key}
          exact
        />
      );
    });
  };
  React.useEffect(() => {
    if(!condition()){
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      mainPanel.current.scrollTop = 0;
      if (
        window.innerWidth < 993 &&
        document.documentElement.className.indexOf("nav-open") !== -1
      ) {
        document.documentElement.classList.toggle("nav-open");
        var element = document.getElementById("bodyClick");
        element.parentNode.removeChild(element);
      }
    }
  }, [location]);

  React.useEffect(() => {
    getLoadAssumptionData();
    // addNotifyToken();
  }, []);

  const getLoadAssumptionData = () => {
    dispatch(getLoadAssumption());
  }

  const addNotifyToken = () => {
    getNotifyToken().then(res => {
      if(res){
        let data = {
          type: "web",
          token: res,
      }
        dispatch(sendDeviceToken(data))
      }
    })
  }

  const condition = () => {
    return location.pathname === "/termsCondition" || location.pathname === "/privacyPolicy" ||
     location.pathname === "/TermsCondition" || location.pathname === "/PrivacyPolicy"
  }

  return (
    <>
      {!condition() ? <div className="wrapper">
        <Sidebar color={color} image={hasImage ? image : ""} routes={routes} />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar />
          <div className="content">
            <Switch>
              <GetRoutes routes={routes} />
            </Switch>
          </div>
          <Footer />
        </div>
      </div> : <Switch> 
        <Route path="/privacyPolicy" component={() => <PrivacyPolicy />}  exact />
        <Route path="/termsCondition" component={() => <TermsCondition />} exact  />
      </Switch>}
    </>
  );
}

export default Admin;
