import React, { useEffect, useState } from "react";
// react-bootstrap components
import { Card, Table, Container, Row, Col } from "react-bootstrap";
import Drivers from "../assets/img/drivers.png";
import Jobs from "../assets/img/jobs.png";
import Loads from "../assets/img/loads.png";
import Transit from "../assets/img/transit.png";
import InfoCard from "components/Cards/Cards";
import Chart from "components/Charts/Charts";
import DeliveryAnalytics from "components/DeliveryAnalytics/DeliveryAnalytics";
import KsPic from "../assets/img/KsPic.png";
import { dashboardCount } from "store/dashboard/actions";
import { useDispatch, useSelector } from "react-redux";
import { orderList } from "store/dashboard/actions";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import { getUserProfile } from "store/auth/actions";

const styles = {
  welcomeContainer: {
    flexDirection: "column",
    display: "flex",
    marginBottom: "10px !important",
  },
  welcomeTxt: {
    fontSize: "20px",
    fontFamily: "Montserrat",
    color: "black",
    fontWeight: "bold",
    paddingBottom: "3px",
  },
  overviewTxt: {
    fontSize: "16px",
    fontFamily: "Montserrat",
    color: "rgb(139, 139, 139)",
  },
  cardContainer: {
    // width:'200px',
    backgroundColor: "white",
    border: "none",
    boxShadow: "1px, 1px, 2px, 2px, rgb(0,126,229,0.5)",
  },
  infoNameTxt: {
    fontSize: "20px",
    fontFamily: "Montserrat",
    fontWeight: "500",
    color: "rgb(23, 32, 63)",
  },
  totalInfoConatiner: {
    flexdirection: "row",
    display: "flex",
    justifyContent: "space-between",
  },
  totalInfoTxt: {
    fontSize: "40px",
    fontFamily: "Montserrat",
    color: "black",
    fontWeight: "900",
  },
  infoTxt: {
    fontSize: "10px",
    fontFamily: "Montserrat",
    color: "rgb(139, 139, 139)",
    fontWeight: "500",
  },
  driversImgStyle: {
    width: "45px",
    height: "45px",
    marginTop: "5px",
  },
  deliveryDetailStyle: {
    backgroundColor: "white",
    flexDirection: "column",
    display: "flex",
    borderRadius: "20px",
    boxShadow: "1px 1px 2px 1px rgba(0, 126, 229, 0.2)",
  },
  deliveryDetailTxt: {
    fontSize: "15px",
    fontFamily: "Montserrat",
    color: "rgb(23,32,63)",
    letterSpacing: 0,
    lineHeight: 0,
    fontWeight: "600",
    paddingTop: "20px",
    paddingLeft: "7px",
  },
  tableHeading: {
    // width: "100%",
    backgroundColor: "rgb(238, 245, 253)",
    borderLeftRadius: "10px",
    alignItems: "center",
  },
  tableSecondValue: {
    fontSize: "15px",
    color: "black",
    fontFamily: "Montserrat",
    fontWeight: "500",
    borderBottom: "0.5px solid rgb(223, 223, 223)",
  },
  tableHeadingTxt: {
    fontSize: "12px",
    fontFamily: "Montserrat",
    fontWeight: "700",
    color: "black",
    textTransform: "none",
  },
  tablevalueTxt: {
    fontFamily: "Montserrat",
    fontSize: "12px",
    color: "rgb(23,32,63)",
    fontWeight: "500",
  },
  paidContainer: {
    width: "50px",
    height: "25px",
    borderRadius: "20px",
    backgroundColor: "rgb(154, 255, 170)",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
    textAlign: "center",
  },
  unpaidContainer: {
    width: "60px",
    height: "25px",
    borderRadius: "20px",
    backgroundColor: "rgb(154, 255, 170)",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
    textAlign: "center",
  },
  deliveryAnalyticsConatiner: {
    // height: 19px;
    flexDirection: "row",
    marginTop: "20px",
    display: "flex",
    justifyContent: "space-between",
    marginRight: "10px",
  },
  deliveryAnalTxt: {
    fontSize: "14px",
    fontFamily: "Montserrat",
    color: "rgb(23,32,63)",
    fontWeight: "500",
    paddingLeft: "10px",
  },
  statusTxt: {
    fontSize: "13px",
    fontFamily: "Montserrat",
    color: "rgb(139 139 139)",
    fontWeight: "500",
    marginRight: "10px",
    //  marginTop: '3px'
  },
  statusContainer: {
    width: "50px",
    height: "20px",
    backgroundColor: "rgb(2,196,34,0.1)",
    borderRadius: "10px",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
    textAlign: "center",
    //   marginLeft: '10px',
  },
  statusTxt: {
    fontSize: "10px",
    color: "rgb(2,196,34)",
    alignSelf: "center",
    paddingTop: "2px",
  },
  inactiveTxt: {
    fontSize: "10px",
    color: "rgb(255, 0, 26)",
    alignSelf: "center",
    paddingTop: "2px",
  },
  inactiveContainer: {
    width: "50px",
    height: "20px",
    backgroundColor: "rgb(255, 0, 26, 0.1)",
    borderRadius: "10px",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
    textAlign: "center",
  },
  jobStatusTxt: {
    fontSize: "12px",
    fontFamily: "Montserrat",
    color: "rgb(255,111,26)",
    // marginTop: '7px',
  },
  jobStatusContainer: {
    width: "70px",
    height: "20px",
    alignSelf: "center",
    textAlign: "center",
    borderRadius: "15px",
    justifyContent: "center",
  },
  scroll: {
    maxHeight: "255px",
    overflowY: "auto",
  },
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
};

function Dashboard() {
  const dispatch = useDispatch();

  const dashboardCountList = useSelector(
    (state) => state?.dashboardReducer?.dashboardCounts
  );
  const orders = useSelector((state) => state?.dashboardReducer?.ordersLists);
  const [loading, setLoading] = useState(false);
  const [countData, setCountData] = useState({});

  useEffect(() => {
    dispatch(getUserProfile()).then((response) =>
      console.log("response", response)
    );
  });

  const userDetails = useSelector((state) => state.authReducer.userDetails);

  // const toggleLoader = () =>
  // setLoading(true)

  useEffect(() => {
    setLoading(true);
    dispatch(dashboardCount()).then((response) => {
      if (response?.error?.message == "Request failed with status code 500") {
        // Toast("Oops! something went wrong")
      }
      //  setCountData(response?.payload?.data)
      setLoading(false);
    });
  }, []);
  useEffect(() => {
    dispatch(orderList()).then((response) => {
      // toggleLoader();
    });
  }, []);

  const DataSheet = () => {
    if (loading === true) {
      return (
        <div style={styles.spinnerContainer}>
          <Spinner animation="border" variant="primary" />
        </div>
      );
    } else {
    }
  };

  return (
    <>
      <Container fluid>
        <Row className="m-0" style={{ display: "flex" }}>
          <div style={styles.welcomeContainer}>
            <span style={styles.welcomeTxt}>
              Welcome back, {userDetails?.name}
            </span>
            <span style={styles.overviewTxt}>Dashboard Overview</span>
          </div>
        </Row>
        <Row className="mt-3">
          <Col lg="3" sm="6">
            {/* <DataSheet /> */}
            <InfoCard
              infoName={"Drivers"}
              totalInfo={
                dashboardCountList?.activeDriverCount
                  ? dashboardCountList?.activeDriverCount +
                    `/` +
                    dashboardCountList?.totalDriverCount
                  : 0
              }
              infoImg={Drivers}
              totalInfoTxt={"Active Drivers"}
            />
          </Col>
          <Col lg="3" sm="6">
            {/* <DataSheet /> */}
            <InfoCard
              infoName={"Jobs"}
              totalInfo={
                dashboardCountList?.availableJobsCount
                  ? dashboardCountList?.availableJobsCount
                  : 0
              }
              infoImg={Jobs}
              totalInfoTxt={"Available Jobs"}
            />
          </Col>
          <Col lg="3" sm="6">
            {/* <DataSheet /> */}
            <InfoCard
              infoName={"Loads"}
              totalInfo={
                dashboardCountList?.completedLoadsCount
                  ? dashboardCountList?.completedLoadsCount +
                    `/` +
                    dashboardCountList?.totalLoadsCount
                  : 0
              }
              infoImg={Loads}
              totalInfoTxt={"Completed Deliveries"}
            />
          </Col>
          <Col lg="3" sm="6">
            {/* <DataSheet /> */}
            <InfoCard
              infoName={"In Transit"}
              totalInfo={
                dashboardCountList?.transitCount
                  ? dashboardCountList?.transitCount
                  : 0
              }
              infoImg={Transit}
              totalInfoTxt={"Active Jobs"}
              backgroundColor={"rgb(23,32,63)"}
              color={"white"}
            />
          </Col>
        </Row>
        <Row>
          <Col md="9">
            <Card
              style={{
                boxShadow: "0 0 6px 0 rgba(0, 126, 229, 0.26)",
                border: "none",
                borderRadius: "30px",
              }}
            >
              <Chart />
            </Card>
          </Col>
          <Col md="3">
            <Card
              style={{
                ...styles.scroll,
                boxShadow: "0 0 6px 0 rgba(0, 126, 229, 0.26)",
                border: "none",
                borderRadius: "30px",
                height: "700px",
              }}
            >
              <div style={styles.deliveryAnalyticsConatiner}>
                <span style={styles.deliveryAnalTxt}>Delivery Analytics</span>
                <span style={{...styles.statusTxt, fontWeight: '700'}}>Status</span>
              </div>
              {orders.map((item) => {
                return (
                  <DeliveryAnalytics
                    picName={
                      item?.schedule?.image ? item?.schedule?.image : KsPic
                    }
                    name={item?.site?.name}
                    status={item?.status}
                  />
                );
              })}
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <div style={styles.deliveryDetailStyle}>
              <span style={styles.deliveryDetailTxt}>Delivery Details</span>
              <Table
                style={{ marginTop: "15px" }}
                className="table table-striped"
              >
                <thead>
                  <tr style={styles.tableHeading}>
                    <th style={styles.tableHeadingTxt}>Order ID</th>
                    <th style={styles.tableHeadingTxt}>Site Name</th>
                    <th style={styles.tableHeadingTxt}>Delivery Date</th>
                    <th style={styles.tableHeadingTxt}>Loads</th>
                    <th style={styles.tableHeadingTxt}>Transporter</th>
                    {/* <th style={styles.tableHeadingTxt}>$</th> */}
                    <th style={styles.tableHeadingTxt}>Job Status</th>
                    <th style={styles.tableHeadingTxt}>Payment Status</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((obj, index, key) => {
                    const Date = moment(obj?.schedule?.date).format(
                      "DD-MM-YYYY"
                    );
                    const rate =
                      Math.round(obj?.total_price * Math.pow(10, 2)) /
                      Math.pow(10, 2);

                    return (
                      <tr style={styles.tableSecondValue}>
                        <td style={styles.tablevalueTxt}>{obj?.order_id}</td>
                        <td style={styles.tablevalueTxt}>{obj?.site?.name}</td>
                        <td style={styles.tablevalueTxt}>
                          {Date ? Date : "-----"}
                        </td>
                        <td style={styles.tablevalueTxt}>
                          {obj?.quantity + obj?.unit
                            ? obj?.quantity + obj?.unit
                            : "------"}
                        </td>
                        <td style={styles.tablevalueTxt}>
                          {obj?.transporter?.name
                            ? obj?.transporter?.name
                            : "-----"}
                        </td>
                        {/* <td style={styles.tablevalueTxt}>{obj?.total_price ? `$`+rate : "------"}</td> */}
                        <td
                          style={{
                            ...styles.tablevalueTxt,
                            ...styles.jobStatusTxt,
                          }}
                        >
                          {obj?.status === "delivered"
                            ? "Completed"
                            : "Assigned"}
                        </td>
                        <td style={styles.td}>
                          <div
                            style={
                              obj?.shipments[0]?.invoice?.status === "paid"
                                ? styles.statusContainer
                                : styles.inactiveContainer
                            }
                          >
                            <p
                              style={
                                obj?.shipments[0]?.invoice?.status === "paid"
                                  ? styles.statusTxt
                                  : styles.inactiveTxt
                              }
                            >
                              {obj?.shipments[0]?.invoice?.status
                                ? obj?.shipments[0]?.invoice?.status
                                : "unpaid"}
                            </p>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Dashboard;
