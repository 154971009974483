import { createAsyncThunk } from '@reduxjs/toolkit';
import { DASHBOARD_COUNT, ORDERS_LIST } from 'store/actionTypes';
import { dashboardCountApi } from 'services';
import { ordersListApi } from 'services';


export const dashboardCount = createAsyncThunk(DASHBOARD_COUNT, async () => {
    const response = await dashboardCountApi();
    return response.data;
});

export const orderList = createAsyncThunk(ORDERS_LIST, async () => {
    const response = await ordersListApi();
    return response.data;
});