import React from 'react'
import profile from "../../assets/img/profile.png";
import "../../assets/css/demo.css"
import { Spinner } from 'react-bootstrap';

const NotificationContent = (props) => {
  const { content, day, loader } = props;

  return (
    <div className='notification-border'>
     {!loader ? <><div style={{ flexDirection: "row", display: "flex", justifyContent: 'space-between' }} className="modalBackground" >
        <img src={profile} alt="" height={"37px"} width={"37px"} className="notificationimg" />
        <span className="contentTxt">{content} </span>
      </div>
      <div>
        <h5 className="dayTxt">{day}</h5>
      </div></> : <div style={{ display: 'flex', justifyContent:'center', alignItems:'center', marginTop: '10px'}} className="modalBackground" >
      <Spinner animation="border" variant="primary" />
      </div>}
    </div>
  )
}

export default NotificationContent