import React from "react";

const DeliveryAnalytics = (props) => {
  const { picName, name, status, color, marginLeft } = props;
  const style2 = { color: color, marginLeft: marginLeft };

  const styles = {
    deliveryView: {
      display: "flex",
      paddingTop: "15px",
      paddingBottom: '10px',
      borderBottom: "1px solid rgb(223, 223, 223)",
      flex:1,
      // paddingLeft: '10px'
      flexDirection: "row",
      justifyContent: "space-between",
      marginLeft: "10px",
    },
    infraStyle: {
      width: "33px",
      height: "30px",
      borderRadius: "15px"
    },
    nameContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      display: "flex",
      flex: 1,
      // marginLeft: "10px",
      backgroundColor:'pink'
    },
    nameTxt: {
      fontSize: "12px",
      fontFamily: "Montserrat",
      color: "rgb(23,32,63)",
      // letterSpacing: 0,
      // lineHeight: 0,
      fontWeight: "500",
      // paddingTop: "14px",
      // width:'100px',
      marginRight:'10px'
    },
    statusDelivery: {
      fontSize: "10px",
      fontFamily: "Montserrat",
      color: "rgb(2, 196, 34)",
      letterSpacing: 0,
      // lineHeight: 0,
      // marginTop: "15px",
      fontWeight: "500",
      // paddingLeft:'10px'
      // paddingRight: '10px'
    },
    jobStatusTxt: {
      fontSize: '12px',
      fontFamily: 'Montserrat',
      color: 'rgb(255,111,26)',
      marginRight: '5px',
      // width:'100px'
      // marginTop: '7px',
    },
  };

  console.log("name",name.length);

  return (
    <div style={styles.deliveryView}>
      {/* <div style={{...styles.nameContainer,display:'flex',justifyContent:'space-between',flex:1}}> */}
      <img src={picName} alt="" style={styles.infraStyle} />
        <span style={styles.nameTxt}>{name.length > 15 ? name.substr(0,14)+'...' : name}</span>
        <span style={{ ...styles.statusDelivery, ...style2, ...styles.jobStatusTxt }}>{status}</span>
      {/* </div> */}
    </div>
  );
};

export default DeliveryAnalytics;
