import React from "react";
import Table from "react-bootstrap/Table";
import weight from "../../assets/img/weight.png";
import arrow from "../../assets/img/arrowImg.png";
import phone from "../../assets/img/phone.png";
import date from "../../assets/img/date.png";
import timing from "../../assets/img/timing.png";
// import '../views/jobs/jobs.css'
import { useHistory, Link } from 'react-router-dom';
import {MdDeleteForever}  from 'react-icons/md';
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";


const styles = {
  trHeading: {
    backgroundColor: "rgb(109,109,109)",
    // fontSize: "15px",
    fontFamily: "Montserrat",
    fontWeight: "600",
    color: "white",
    borderRadius: "30px",
    marginTop: "10px",
    // width: '800px',
  },
  textHeading: {
    fontSize: "12px",
    fontFamily: "Montserrat",
    fontWeight: "700",
    color: "white",
    textTransform: "none",
  },
  tableRowShaddow: {
    boxShadow: "1px 1px 1px 1px rgba(0, 126, 229, 0.2)",
    // backgroundColor: "rgb(255,255,255)",
    borderRadius: "10px",
    marginBottom: "1em",
  },
  tdView: {
    fontSize: "12px",
    fontFamily: "Montserrat",
    // fontWeight: "600",
    color: "rgb(23,32,63)",
    // display: 'flex',
    flexDirection: "row",
    // marginTop: 7,
    // marginBottom: 7
  },
  weightStyle: {
    width: "13px",
    height: "13px",
  },
  statusViewContainer: {
    width: "50px",
    height: "7px",
    backgroundColor: "rgb(191, 190, 190)",
    // marginTop: "7px",
  },
  buttonStyle: {
    width: "70px",
    height: "40px",
    // backgroundColor: "rgb(0, 126, 229)",
    marginTop: "7px",
    borderRadius: "7px",
    border: "none",
    color: "white",
    fontSize: "11px",
    fontWeight: "700",
    // marginLeft: "25px",
  },
  table: {
    border: "1px solid #CCC",
    borderCollapse: "collapse",
  },
};

const CancelJobTable = ({
  heading,
  handleShow,
  backgroundColor,
  title,
  fontSize,
  onClick,
  handleDelete
}) => {

  let history = useHistory()


  const onTrack = () => {
    alert('---')
  }

  const list = useSelector(state => state?.driverReducer?.cancelledJobsList)
  console.log("list",list);

  const handleDel = (item) => {
     handleDelete(item)
  }


  return (
    <>
      <tbody className="p-0 mt-3">
       {list.map((i) => {
        console.log("i",i);
        let date = moment.utc(i?.order?.shipments[0]?.date).local().format("YY-MM-DD")
        let lastDate = moment.utc(i?.order?.shipments[4]?.date).local().format("YY-MM-DD")
        let cancelledDate = moment.utc(i?.created_at).local().format("YY-MM-DD")
        let cancelledTime = moment.utc(i?.created_at).local().format("hh:mm a")
        let startTime = i?.order?.schedule?.start_time
        let endTime = i?.order?.schedule?.end_time
        return(
         <tr style={styles.tableRowShaddow} className="pt-3">
          <td style={{ ...styles.tdView }}>{i?.order?.order_id}</td>
          <td>
            <div style={{ width: "90px", display: "flex", padding: 0 }}>
              <span style={styles.tdView}>{i?.order?.supplier_material?.material?.name}</span>
            </div>
            <div style={{ display: "flex", paddingTop: "3px" }}>
              <img
                src={weight}
                alt=""
                style={{ ...styles.weightStyle, ...styles.tdView }}
              />
              <span style={{ ...styles.tdView, paddingLeft: "5px" }}>{i?.order?.quantity+i?.order?.unit}</span>
            </div>
          </td>
          <td>
            <div style={{ flexDirection: "column", display: "flex" }}>
              {/* <td> */}
              <div style={{ border: "none", width: "160px" }}>
                <img src={arrow} alt="" style={styles.weightStyle} />
                <span style={styles.tdView}>{` `+i?.order?.supplier_material?.supplier?.name}</span>
              </div>
              {/* </td> */}
            </div>
            <div style={{ ...styles.tdView, border: "none" }}>
              <img src={phone} alt="" style={styles.weightStyle} /> {` `+i?.order?.supplier_material?.supplier?.user?.mobile}
            </div>
          </td>
          <td>
            <span style={{ ...styles.tdView }}>{i?.order?.distance+i?.order?.d_unit}</span>
          </td>
          <td>
            <div style={{ flexDirection: "column" }}>
              <div style={{ ...styles.tdView, border: "none" }}>
                <img src={arrow} alt="" style={styles.weightStyle} />
                <span style={{ ...styles.tdView }}>{` `+i?.order?.site?.name}</span>
              </div>
              <div style={{ ...styles.tdView, border: "none", width: "120px" }}>
                <img src={phone} alt="" style={styles.weightStyle} />
                <span style={{ ...styles.tdView }}>{` `+i?.order?.site?.site_members[0]?.user?.mobile}</span>
              </div>
            </div>
          </td>
          <td>
            <div style={{ flexDirection: "column" }}>
              <div
                style={{ ...styles.tdView, border: "none", width: "119.55px" }}
              >
                <img src={date} alt="" style={styles.weightStyle} />
                <span style={{ ...styles.tdView }}>{date} to {lastDate}</span>
              </div>
            </div>
            <div style={{ ...styles.tdView, border: "none" }}>
              <img src={timing} alt="" style={styles.weightStyle} />
              <span style={styles.tdView}> b/w {startTime} - {endTime}</span>
            </div>
          </td>
          <td>
            <div style={{ flexDirection: "row", display: "flex", paddingTop: '6px' }}>
              <div
                style={{
                  ...styles.statusViewContainer,
                  //   marginLeft: "5px",
                  backgroundColor: "rgb(255,0,26)",
                }}
              />
              <div
                style={{ ...styles.statusViewContainer, marginLeft: "5px", backgroundColor: "rgb(255,0,26)", }}
              />
              <div
                style={{ ...styles.statusViewContainer, marginLeft: "5px", backgroundColor: "rgb(255,0,26)", }}
              />
            </div>
            <div style={{...styles.tdView,paddingTop: '8.5px'}}>
              <span style={{...styles.tdView}}>Cancelled
              <span style={{...styles.tdView}}> {cancelledTime} on {cancelledDate}</span>
              </span>
            </div>
          </td>
          <td>
            <div style={{ ...styles.tdView }}>
              <MdDeleteForever
                color="rgb(255,72,91)"
                size={32}
                className="dustbin"
                onClick={() => handleDel(i)}
              />
            </div>
          </td>
        </tr>
        )
      })}
      </tbody>
      {/* </table> */}
      {/* </div> */}
    </>
  );
};

export default CancelJobTable;
