import React, { useState } from 'react';
import { MdOutlineArrowDropDown } from 'react-icons/md';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import date from '../../assets/img/date.png';
import { IoMdArrowDropdown } from 'react-icons/io'
import Form from 'react-bootstrap/Form';
import { BiShow, BiHide } from "react-icons/bi";
import InputGroup from 'react-bootstrap/InputGroup';
import { RxLockClosed } from "react-icons/rx";
import { changePassword } from 'store/auth/actions';

const AddDriverForm = (props) => {
  const { label_name, placeholder_name,maxLength, dataSave, value, icon, style, type, Itemicon, truckTypeList, showPass
    , onChange, assignTruck, pass, changePassword  } = props;
  const [state, setState] = useState({
    list: assignTruck || ''
  })

  // const passwordVisible = () => {
  //    changePassword()
  // }

  // const [pass,setPass] = useState(false)

  return (
    <>
      <div className='box_container'>
        <div>
          <label className="label_name">{label_name} </label>
          {icon ?
            <Dropdown>
              <Dropdown.Toggle variant='No style' id="dropdown-basic" style={{ width: "100%" }}>
                {state?.list || "Choose Truck Type"}
                <IoMdArrowDropdown size={'30px'} />
              </Dropdown.Toggle>
              <Dropdown.Menu style={{ width: "100%", maxHeight: '250px', overflowY: 'auto' }} onClick={
                (e) => {
                  setState((prev) => ({ ...prev, list: e.target.text }))
                  onChange(Number(e.target.id))
                }}>
                {truckTypeList !== null ? truckTypeList.map((value, index) => <Dropdown.Item id={value?.id} className="dropdown-item" key={index.toString()}>
                  <img src={value?.image} alt="" style={{ width: 29, height: 20, marginRight: 10 }} />
                  {value?.vehicle_type?.name}  &nbsp;&nbsp; {value?.rego}
                  <Dropdown.Divider />
                </Dropdown.Item>) : null}
              </Dropdown.Menu>
            </Dropdown>
            :
            <div>
             {!showPass ? <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control placeholder={placeholder_name} onChange={onChange} value={value || ''} maxLength={maxLength} type={type}/>
                </Form.Group>
              </Form> :
              <InputGroup className="mb-4 ">
                                {/* <InputGroup.Text id="basic-addon1" className="manageBorderRadius"><RxLockClosed /></InputGroup.Text> */}
                                <Form.Control
                                    placeholder={placeholder_name}
                                    aria-label="password"
                                    aria-describedby="basic-addon1"
                                    value={value || ''}
                                    onChange={onChange}
                                    type={pass ? "text" : "password"}
                                />
                                <InputGroup.Text>
                                <div onClick={changePassword}>
                                {pass ? <BiShow size={20} color={"black"} /> : <BiHide size={20} color={"black"} />}
                                </div>
                            </InputGroup.Text>
                            </InputGroup>}
            </div>
          }
        </div>
      </div>
    </>
  )
}

export default AddDriverForm;