import React, { useState } from "react";
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Spinner
} from "react-bootstrap";
import { Link, useHistory, useLocation, useParams, withRouter } from "react-router-dom";
import uploadIcon from "../assets/img/uploadIcon.png";
import check from "../assets/img/check.png";
import "../views/AddDriver.css";
import AddDriverForm from "components/AddDriverForm/AddDriverForm";
import truck from "../assets/img/truck.png"
import DropdownIcon from "components/Icon/Icon";
import { useDispatch } from "react-redux";
import { getTruckList } from "store/vechileType/actions";
import { useSelector } from "react-redux";
import { addDriver, editDriver } from "store/driver/actions";
import { uploadImage } from "store/uploadImage/actions";
import Toast, { MainToastContainer } from "utils/Toast";
import { isValidDigit } from "utils/isValidate";
import { isValidEmail } from "utils/isValidate";
import { isValidName } from "utils/isValidate";

const styles = {
  dashboardTxt: {
    fontSize: "14px",
    fontFamily: "Montserrat",
    color: "rgb(139,139,139)",
  },
  jobsTxt: {
    fontSize: "14px",
    fontFamily: "Montserrat",
    textDecoration: "underline",
    fontWeight: "500",
  },
  breadcrumb: {
    padding: 0,
  },
};


const AddDriver = (props) => {

  const [pass,setPass] = useState(false)
  const [confirmPass,setConfirmPass] = useState(false)


  const location = useLocation();
  const [state, setState] = useState({
    fileName: location?.state?.image || "",
    driverName: location?.state?.name || '',
    mobile: location?.state?.mobile || '',
    email: location?.state?.email || '',
    veh_id: location?.state?.driver_vehicle?.vehicle_id || '',
    licenseFile: location?.state?.document?.image || "",
    doc_number: location?.state?.document?.number || '',
    isLoading: false,
    currentPassword: location?.state?.password || "",
    confirmPassword: ''
  });
  const truckTypeList = useSelector(state => state?.truckTypeReducer?.truckTypeList)
  const dispatch = useDispatch();
  const history = useHistory()
  console.log("location",location.state);
  console.log("re",state.currentPassword);


  useState(() => {
    dispatch(getTruckList())
  }, [])

  const getImage = async (file, fileName) => {
    var formData = new FormData();
    formData.append("image", file, fileName)

    let driverImageUrl;
      await dispatch(uploadImage(formData)).then((response) => {
        driverImageUrl = response?.payload?.data?.url
      })

      return driverImageUrl;
  }

  const handleAddDriver = async (e) => {
    e.preventDefault()
  // if(state.currentPassword != ''){
  //   if(state.currentPassword.length < 8){
  //     alert("Password must be at least 8 characters")
  //   }
  //   else if(state.currentPassword !== state.confirmPassword){
  //     alert("Password and Confirm password must be same")
  //   }
  // }
   if (state.driverName && state.mobile && state.email) {
      let payload = {
        name: state.driverName,
        mobile: state.mobile,
        email: state.email,
        document: {
          type: 'License'
        },
        vehicle_id: state.veh_id,
        password: state.currentPassword
      }

      setState(prev => ({ ...prev, isLoading: true }))

      // if(state.mobile === )

      if(state?.fileName){
        let driverImageUrl = await getImage(state?.fileName, state?.fileName?.name);
        if(driverImageUrl){
          payload.image = driverImageUrl;
        }
      }

      if(!isValidEmail(state.email)) {
        Toast("please enter valid email")
      }

      if(state?.licenseFile){
        let licenseImageUrl = await getImage(state?.licenseFile, state?.licenseFile?.name);
        if(licenseImageUrl){
          payload.document.image = licenseImageUrl;
        }
      }

      if(state?.doc_number){
        payload.document.number = state.doc_number;
      }
      
      

      await dispatch(addDriver(payload)).then((response) => {
        
        setState(prev => ({ ...prev, isLoading: false }))

        if(state?.licenseFile && state?.doc_number === ''){
          alert("Driving Licence number is required when driving licence image is present")
        }
 if (response?.payload?.success) {
          alert("Successfully added!")
          history.goBack()
        } else {
          Toast(response?.payload?.message)
        }
        
        // else {
        //   Toast(response?.payload?.message)
        //   // history.goBack()
        // }
      })
    } 
    
    else {
      Toast('All fields are required.')
    }
    setState(prev => ({ ...prev, isLoading: false }))
  }

  const handleEditDriver = async (e) => {
    e.preventDefault()
    
    var formData = new FormData();
    var formDataForDocument = new FormData();
    let driverImageUrl;
    let licenseImageUrl;

    if (state?.fileName?.name && state?.licenseFile?.name) {
      formData.append("image", state?.fileName, state?.fileName?.name)
      formDataForDocument.append("image", state?.licenseFile, state?.licenseFile?.name);
      await dispatch(uploadImage(formData)).then((response) => {
        if (response?.payload?.success) {
          driverImageUrl = response?.payload?.data?.url
        }
        else {
          driverImageUrl = ""
        }
      })
      await dispatch(uploadImage(formDataForDocument)).then((response) => {
        if (response?.payload?.success) {
          licenseImageUrl = response?.payload?.data?.url
        }
        else {
          licenseImageUrl = ""
        }
      })
    } else {
      if (state?.fileName?.name) {
        formData.append("image", state?.fileName, state?.fileName?.name)
        await dispatch(uploadImage(formData)).then((response) => {
          if (response?.payload?.success) {
            driverImageUrl = response?.payload?.data?.url
          }
          else {
            driverImageUrl = ""
          }
        })
      }
      else if (state?.licenseFile?.name) {
        formDataForDocument.append("image", state?.licenseFile, state?.licenseFile?.name);
        await dispatch(uploadImage(formDataForDocument)).then((response) => {
          if (response?.payload?.success) {
            licenseImageUrl = response?.payload?.data?.url
          }
          else {
            licenseImageUrl = ""
          }
        })
      }
     
      else {
        driverImageUrl = ""
        licenseImageUrl = ""
      }
    }
    
      if(state.currentPassword.length != '' && state.currentPassword.length < 8){
        alert("password must be at least 8 characters")
      }
      else if(state.currentPassword.length != '' && state.currentPassword !== state.confirmPassword){
        alert("Password and Confirm password must be same")
      }
     else if(state?.licenseFile && state?.doc_number === ''){
      alert("Driving Licence number is required when driving licence image is present")
        }
        
    else  {

      // if(state.currentPassword !== ''){
      //   if(state.currentPassword.length < 8){
      //     alert("password must be at least 8 characters")
      //   }
      //   else if(state.currentPassword !== state.confirmPassword){
      //     alert("Password and Confirm password must be same")
      //   }
      // }
      // else{

    const payload = {
      driverId: location?.state?.id,
      name: state.driverName,
      mobile: state.mobile,
      email: state.email,
      document: {
        image: licenseImageUrl,
        number: state.doc_number,
        type: 'License'
      },
      image: driverImageUrl,
      vehicle_id: state.veh_id,
      password: state.currentPassword
    }

    
    dispatch(editDriver(payload)).then((res) => {
      console.log("res",res);
      if(res?.payload?.success === true){
        alert("Successfully updated driver details")
        history.goBack()
        // alert("success")
      }
      // if(state?.licenseFile && state?.doc_number === ''){
      //   alert(res?.payload?.data?.["document.number"])
      // }
      // else if(state.currentPassword !== ''){
      //   if(state.currentPassword.length < 8){
      //     alert("password must be at least 8 characters")
      //   }
      //   else if(state.currentPassword !== state.confirmPassword){
      //     alert("Password and Confirm password must be same")
      //   }
      // }
      

      else {
        // alert("fail")
          Toast(res?.payload?.message)
          // history.goBack()
        } 
      // else if(res?.payload?.success == true){
      //   Toast(res?.payload?.message)
      // }
      
    })
  }
// }
    // }
    
  }

  const handleCancel = () => {
    history.goBack()
  }

  const passwordVisible = () => {
    setPass(!pass)
  }


  return (
    <Container fluid>
      <Row>
        <Col md="12">
          <ul className="breadcrumb ps-0 ms-0" style={styles.breadcrumb}>
            <li className="p-0">
              <Link to="/" style={{ ...styles.dashboardTxt }}>Dashboard</Link>
            </li>
            <li>
              <a href="/driver-management" style={styles.dashboardTxt}>
                Management
              </a>
            </li>
            <li>
              <Link to="/driver-management" style={styles.jobsTxt}>
                Driver Management
              </Link>
            </li>
            <li>
              <Link to="AddDriver" style={styles.jobsTxt}>
                Add driver
              </Link>
            </li>
          </ul>
        </Col>
      </Row>
      <Row className="m-0 p-0" style={{ display: "flex" }}>
        <span className="profile_Tab_Txt">Add Driver</span>
      </Row>
      <Row className="m-0 p-0">
        <Col
          md={12}
          style={{ backgroundColor: "rgb(247,247,247)" }}
          className="pb-5"
        >
          <Row className="form-containers">
            <Col md={4}>
              <div className="container-input">
                <div className="input-box">
                  <label className="label_name">Driver Image</label>
                  <div className="icon-box" style={{ marginTop: "10px", flexDirection: 'row', height: state.fileName ? '100%' : null,  display: 'flex',  width: state.fileName ? '100%' : null }}>
                    <div className="image-upload">
                      <label htmlFor="file-input">
                        <img
                          src={state.fileName ? check : uploadIcon}
                          className="inputIcon"
                          width="30px"
                          height="25px"
                        />
                      </label>
                      <input
                        id="file-input"
                        accept="image/png, image/gif, image/jpeg"
                        type="file"
                        onChange={(e) => {
                          console.log("fileName: ",e.target.files[0]);
                          if(e.target.files[0].type === "image/png" || e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/gif"){
                          setState((prev) => ({
                            ...prev,
                            fileName: e.target.files[0],
                          }));
                        }
                      
                      else{
                        alert("please upload valid image")
                      }
                    }
                  }
                      // value={location.state.image}
                      />
                      <div style={{width:'300px', display: 'flex', flex:1, flexGrow: '-moz-initial', margin: '10px'}}>
                        <p>{state.fileName.name}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <AddDriverForm
                label_name={"Driver Name*"}
                placeholder_name={"Enter Driver Name"}
                maxLength={20}
                onChange={e => { 
                  if(e.target.value == '' || isValidName(e.target.value)){
                  setState(prev => ({ ...prev, driverName: e.target.value })) 
                  }
                  else{
                    alert("Please enter valid name")
                  }
                }}
                value={state.driverName}
              />
              <AddDriverForm
                label_name={"Mobile Number*"}
                placeholder_name={"Enter mobile number"}
                maxLength={10}
                onChange={e => { 
                  if(isValidDigit(e.target.value)){
                  setState(prev => ({ ...prev, mobile: e.target.value })) 
                }
                else{
                  alert("please enter valid numbers")
                }
              }
                }
                value={state.mobile}

              />
                <AddDriverForm
                label_name={"Email*"}
                placeholder_name={"Enter email"}
                maxLength={30}
                onChange={e => { 
                 
                    setState(prev => ({ ...prev, email: e.target.value })) 
                  
                }
              }
                value={state.email}

              />
            </Col>
            <Col md={4}>
              <div className="container-input">
                <div className="input-box">
                  <label className="label_name"> Driving License Image</label>
                  <div className="icon-box" style={{ marginTop: "10px", height: state.licenseFile ? '100%' : null, flexDirection: 'row', display: 'flex',  width: state.licenseFile ? '100%' : null }}>
                    <div className="image-upload">
                      <label htmlFor="file-input1">
                        <img
                          src={state.licenseFile ? check : uploadIcon}
                          className="inputIcon"
                          width="30px"
                          height="25px"
                        />
                      </label>
                      <input
                        id="file-input1"
                        accept="image/png, image/gif, image/jpeg"
                        type="file"
                        onChange={(e) => {
                          console.log("eef", e.target.files[0]);
                          if(e.target.files[0].type === "image/png" || e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/gif"){
                          setState((prev) => ({
                            ...prev,
                            licenseFile: e.target.files[0],
                          }));
                        }
                        else{
                          alert("please upload valid image")

                        }
                      }
                    }
                      />
                       </div>

                      <div style={{width:'300px', display: 'flex', flex:1, flexGrow: '-moz-initial', margin: '10px'}}>
                        <p>{state.licenseFile.name}</p>
                      </div>
                   
                  </div>
                  
                </div>
                
              </div>
              
              <AddDriverForm
                label_name={"Driving License Number"}
                placeholder_name={"Enter Driving License Number"}
                maxLength={15}
                onChange={e => { 
                  if(isValidDigit(e.target.value)){
                  setState(prev => ({ ...prev, doc_number: e.target.value }))

                 }
                 else{
                  alert("Please enter valid  Driving License Number")
                 }
                }
                }
                value={state.doc_number}
              />
              <AddDriverForm
                label_name={"Assign Truck"}
                placeholder_name={"Choose Truck Type"}
                icon={true}
                Itemicon={<DropdownIcon />}
                truckTypeList={truckTypeList}
                onChange={e => setState(prev => ({ ...prev, veh_id: e }))}
                assignTruck={location?.state?.driver_vehicle?.vehicle?.vehicle_type?.name}
              />
              <AddDriverForm
                label_name={"Password"}
                placeholder_name={"Enter Password"}
                maxLength={15}
                // passwordVisible={passwordVisible()}
                // type= {pass ? "text" : "password"}
                pass={pass}
                changePassword={() => setPass(!pass)}
                onChange={e => { 
                  setState(prev => ({ ...prev, currentPassword: e.target.value }))
                 }
                }
                showPass={true}
                value={state.currentPassword}
              />
               <AddDriverForm
                label_name={"Confirm Password"}
                placeholder_name={"Enter Confirm Password"}
                maxLength={15}
                // type={ confirmPass ? "text" : "password"}
                pass={confirmPass}
                changePassword={() => setConfirmPass(!confirmPass)}
                onChange={e => { 
                  setState(prev => ({ ...prev, confirmPassword: e.target.value }))
                 }
                }
                showPass={true}
                value={state.confirmPassword}
              />
              <Row style={{ justifyContent: "flex-end" }}>
                {location?.state ? (
                  <div className="addButton" style={{ marginTop: '20px', marginBottom: '20px', }}>
                    <Button className="cancelBtn" onClick={handleCancel}>Cancel</Button>
                    <Button className="saveChange" onClick={handleEditDriver}>Save Changes</Button>
                  </div>
                ) : (
                  <div className="addButton" style={{ marginTop: '20px', marginBottom: '20px', }}>
                    <Button variant="primary" type="submit" className="saveButton" size="md" onClick={handleAddDriver} >
                      {
                        state.isLoading ?
                          <Spinner animation="border" className="text-white" size="sm" />
                          :
                          <span className="text-white"> Add Driver</span>
                      }
                    </Button>
                  </div>
                )}
              </Row>
              <Row style={{ justifyContent: "flex-end", margin: '10px 2px', color: 'red' }}>
                *required
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <MainToastContainer />
    </Container>
  );
};

export default withRouter(AddDriver);
