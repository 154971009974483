import React from 'react'
import { useState } from 'react';
import { AiOutlineEye , AiOutlineEyeInvisible} from "react-icons/ai";
import "../../views/ChangePassword.css"


const PasswordForm = (props) => {
    const [visible , setVisible] = useState(false);
  const { label_name, placeholder_name , dataSave  , value, icon} = props;
const handleClick =()=>{
setVisible(prev=>!prev);
}
  return (
    <>
    <div className="password-content-container">
      <div className="form-content">
        <label className="label_name">{label_name} </label>
        <div className='password-wrapper' >   
        <input
          type={visible ? "text" : "password"} 
          placeholder={placeholder_name} onChange={dataSave} value={value}
          className="placeholder_name ">   
        </input>  
        {icon ?
            <div onClick={handleClick} className="eye">
           {visible ? <AiOutlineEye size={21}/> : <AiOutlineEyeInvisible size={21} /> }
           </div> 
            : null} 
        
        </div>
      </div>
    </div>
  </>
  )
}

export default PasswordForm