import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './auth/slice';
import driverReducer from './driver/slice';
import truckTypeReducer from './vechileType/slice'
import dashboardReducer from './dashboard/slice'
// import uploadImageReducer from './uploadImage/slice'
const rootReducer = combineReducers({
    authReducer,
    driverReducer,
    truckTypeReducer,
    dashboardReducer
    // uploadImageReducer
});

export default rootReducer;
