import React from "react";
import Card from "react-bootstrap/Card";

const InfoCard = (props) => {
  const { infoName, totalInfo, infoImg, totalInfoTxt, backgroundColor, color } =
    props;
  const style1 = { backgroundColor: backgroundColor };
  const style2 = { color: color };

  const styles = {
    cardContainer: {
      // width:'200px',
      backgroundColor: "white",
      border: "none",
      boxShadow: "1px, 1px, 2px, 2px, rgb(0,126,229,0.5)",
    },
    infoNameTxt: {
      fontSize: "20px",
      fontFamily: "Montserrat",
      fontWeight: "500",
      color: "rgb(23, 32, 63)",
    },
    totalInfoConatiner: {
      flexdirection: "row",
      display: "flex",
      justifyContent: "space-between",
    },
    totalInfoTxt: {
      fontSize: "40px",
      fontFamily: "Montserrat",
      color: "black",
      fontWeight: "900",
    },
    infoTxt: {
      fontSize: "13px",
      fontFamily: "Montserrat",
      color: "gray",
      fontWeight: "550",
    },
    driversImgStyle: {
      width: "45px",
      height: "45px",
      marginTop: "5px",
    },
  };

  return (
    <Card
      style={{
        boxShadow: "0 0 6px 0 rgba(0, 126, 229, 0.26)",
        border: "none",
        ...style1,
      }}
    >
      <Card.Body>
        <Card.Text>
          <span style={{ ...styles.infoNameTxt, ...style2 }}>{infoName}</span>
        </Card.Text>
        <Card.Text>
          <span style={styles.totalInfoConatiner}>
            <span style={{ ...styles.totalInfoTxt, ...style2 }}>
              {totalInfo}
            </span>
            <img src={infoImg} alt="" style={styles.driversImgStyle} />
          </span>
        </Card.Text>
        <Card.Text>
          <span style={{ marginBottom: "5px" }}>
            <span style={{ ...styles.infoTxt, ...style2, marginBottom: "5px" }}>{totalInfoTxt}</span>
          </span>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default InfoCard;
