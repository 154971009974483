import React from "react";
import { useHistory } from "react-router-dom";
import CustomTable from "components/CustomTable/CustomTable";
import "../../TopTabBar.css";

const CompletedList = (props) => {
  const history = useHistory();

  const handleClick = () => {
    history.push("/track");
  };

  return (
    <CustomTable
      orderId={props?.orderId}
      materialName={props?.materialName}
      quantity={props?.quantity}
      distance={props?.distance}
      loaderName={props?.loaderName}
      loaderNumber={props?.loaderNumber}
      unloaderName={props?.unloaderName}
      unloaderNumber={props?.unloaderNumber}
      deliveryDate={props?.deliveryDate}
      deliveryTime={props?.deliveryTime}
      deliveryLastTime={props?.deliveryLastTime}
      startedTime={props?.startedTime}
      seeDetails={props?.seeDetails}
      onPressSeeDetails={props?.handleClick}

      // backgroundColor={"rgb(255,111,26)"}
      // title={"Track"}
      // fontSize={"14px"}
      // handleClick={() => {}}
      // track={false}
      // status={'props?.status'}


      // completed={props?.completed}
      // backgroundColor={"rgb(255,111,26)"}
      // title={"Track"}
      // fontSize={"14px"}
      // handleShow={handleClick}
    />
  );
};
export default CompletedList;
