import React, { useState } from "react";
import {
    Row,
    Col,
} from "react-bootstrap";
import { useDispatch } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { FaRegUser } from "react-icons/fa";
import { RxLockClosed } from "react-icons/rx";
import './Login.css'
import { authenticate, forgotPassword } from "store/auth/actions";
import Toast, { MainToastContainer } from "utils/Toast";
import axios from 'axios';
import { Link } from 'react-router-dom'
import { useHistory } from "react-router";
import { BiShow, BiHide } from "react-icons/bi";



const Login = () => {
    const [state, setState] = useState({
        username: '',
        password: '',
        loading: false,
        enableForgotPassword: false,
        email:'',
        
    })

    const [showPass,setShowPass] = useState(false)

    const dispatch = useDispatch()
    const history = useHistory();


    const onPressLogin = (e) => {
        e.preventDefault()
        if (state?.username !== "" && state?.password.length >= 6) {
            setState(prev => ({ ...prev, loading: true }))
            dispatch(authenticate(state))
                .then((res) => {
                    if (res?.payload?.data?.user?.company?.company_type?.name === "Transporter") {
                        localStorage.setItem('token', `${res?.payload?.data?.accessToken}`);
                        axios.defaults.headers.common['Authorization'] = `Bearer ${res?.payload?.data?.accessToken}`;
                    } else {
                        Toast("unauthorized access");
                    }
                    setState(prev => ({ ...prev, loading: false }))
                })
                .catch((error) => {
                    Toast(error)
                    setState(prev => ({ ...prev, loading: false }))
                })
        }
        else {
            Toast("invalid credentials");
        }
    }

    const onPressForgotPassword = () => {
        setState(prev => ({ ...prev, enableForgotPassword: true }))
    }

    const onChangeUserName = (e) => {
        setState(prev => ({ ...prev,  username: e.target.value, }))
    }

    const onChangeEmail = (e) => {
        setState(prev => ({ ...prev,  email: e.target.value, }))

    }

    const handelSubmit = async (e) => {
        e.preventDefault()
        if (state) {
        //   if (!state?.email) {
        //    Toast('Please Enter Email')
        //     // alert("Please Enter UserName")
        //   }
         
            let paylaod = {
              email: state.email,
            }
            dispatch(forgotPassword(paylaod)).unwrap()
          .then(res => {
            if (res?.success) {
              // console.log("aa",res);
              Toast("Temporary password sent.")
              setState(prev => ({ ...prev, enableForgotPassword: false, email:'' }))
            }
            else{
              Toast(res?.payload?.message)
            }
          })
          
        }
      }

    return (
        <Row className="mainContainer " >
            <Col as={Row} className="columnStyle m-3 p-0 " md='10' lg='8' >
                <Col className="loginForm " sm='7' md='6' >
                    <h3 className="text-black" style={{ fontWeight: "bold" }}>{state.enableForgotPassword ? "Forgot Password" : "Login"}</h3>
                    <p className="text-secondary">{ state.enableForgotPassword ? null : "Sign in to your account"}</p>
                    <div className="mt-4">
                        <Form>
                            <InputGroup className="mb-4 " >
                                <InputGroup.Text id="basic-addon1" className="manageBorderRadius"><FaRegUser /></InputGroup.Text>
                                <Form.Control
                                    placeholder={state.enableForgotPassword ? "Email" : "Username/Email"}
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    value={state?.enableForgotPassword ? state?.email : state.username}
                                    onChange={state?.enableForgotPassword ?  onChangeEmail : onChangeUserName}
                                    type="text"
                                />
                            </InputGroup>
                           {state.enableForgotPassword ? null : 
                           <InputGroup className="mb-4 ">
                                <InputGroup.Text id="basic-addon1" className="manageBorderRadius"><RxLockClosed /></InputGroup.Text>
                                <Form.Control
                                    placeholder="password"
                                    aria-label="password"
                                    aria-describedby="basic-addon1"
                                    value={state.password}
                                    onChange={e => { setState(prev => ({ ...prev, password: e.target.value, })) }}
                                    type={showPass ? "text" : "password"}
                                />
                                <InputGroup.Text> 
                                <div onClick={() => setShowPass(!showPass)}>
                               {showPass ? <BiShow size={20} color={"black"} /> : <BiHide size={20} color={"black"} />}
                                </div>
                            </InputGroup.Text>
                            </InputGroup>}
                        {state.enableForgotPassword ?  null :   <div className="mt-4">
                    <Link onClick={onPressForgotPassword}>Forgot password</Link>
                    </div>}
                            <Button variant="primary" type="submit" className="mt-2 bg-primary mb-3 " size="md" onClick={state.enableForgotPassword ? handelSubmit : onPressLogin} style={{ width: 100, maxHeight: 60 }}>
                                {
                                    state.loading ?
                                        <Spinner animation="border" className="text-white" size="sm" />
                                        :
                                     <span className="text-white"> { state.enableForgotPassword ? "Send" : "Login"}</span>
                                }
                            </Button>

                        </Form>
                    </div>
                </Col>
                <Col className="panelStyle pt-3" sm='5' md='6'>
                    <img src={require("../assets/img/rubblLogo.png")} alt="R RUBBL" style={{ maxHeight: 100, maxWidth: 200, objectFit: "contain" }} />
                </Col>
            </Col>
            <MainToastContainer />
        </Row>
    )
}

export default Login;