import React, { useEffect, useState } from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getPages } from "store/auth/actions";
import './TermsCondition.css'
import { Link } from "react-router-dom";

const styles = {
  dashboardTxt: {
    fontSize: "14px",
    fontFamily: "Montserrat",
    color: "rgb(139,139,139)",
  },
  jobsTxt: {
    fontSize: "14px",
    fontFamily: "Montserrat",
    textDecoration: "underline",
    fontWeight: "500",
  },
  breadcrumb: {
    padding: 0,
  },
}


const About = () => {
  const [state, setState] = useState({
    loading: false,
    about: '',
  })

  const dispatch = useDispatch();

  useEffect(() => {
    getTermsAndCondition();
  }, [])

  const getTermsAndCondition = () => {
    setState(prev => ({...prev, loading: true}));
    dispatch(getPages('a')).then(res => {
      if (res?.payload?.success === true) {
        setState(prev => ({...prev, about: res?.payload?.data?.description, loading: false}))
      } else {
        setState(prev => ({...prev, loading: false}))
      }
    })
  }

  const createMarkup = () => {
    return { __html: state.about };
  }

  return (

    <Container fluid>
      <ul className="breadcrumb ps-0 ms-0" style={styles.breadcrumb}>
      <li className="p-0">
              <Link to="/profile" style={{ ...styles.dashboardTxt }}>Profile</Link>
            </li>
        <li>
          <a href="/About" style={styles.jobsTxt}>
            About
          </a>
        </li>
      </ul>
      <Row className="m-0 p-0" style={{ display: "flex" }}>
        <span className="profile_Tab_Txt">About</span>
      </Row>
      <Row className="about-container">
      {state.loading ? <Spinner animation="border" variant="primary" /> : <Col>
          <div dangerouslySetInnerHTML={createMarkup()} />
        </Col>}
      </Row>
    </Container>
  )
}

export default About