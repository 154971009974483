import { async } from '@firebase/util';
import axios from 'axios'
const { REACT_APP_API_URL } = process.env;


export const truckListApi = async (token) => {
    return axios.get(`${REACT_APP_API_URL}vehicles/list`)
};

export const truckTypeApi = async () => {
    return axios.get(`${REACT_APP_API_URL}vehicletypes`)
};

export const addVehicleInTransportApi = async payload => {
    return axios.post(`${REACT_APP_API_URL}orders/transporter/add-vehicle`, payload)
}

export const addTruckTypeApi = async (payload) => {
    return axios.post(`${REACT_APP_API_URL}vehicle/create`, payload)
};

export const editVehicleApi = async (payload) => {
    return axios.patch(`${REACT_APP_API_URL}vehicle/update/${payload.vehicleId}`, payload)
};
export const deleteVehicleApi = async (vehicleId) => {
    return axios.delete(`${REACT_APP_API_URL}vehicle/delete/${vehicleId}`)
};

