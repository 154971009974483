import React, { useEffect, useState } from "react";
// react-bootstrap components
import {
  Container,
  Row,
  Col,
  Modal,
  Spinner
} from "react-bootstrap";
import DropDownToggle from "components/DropDownToggle/DropDownToggle";
import { IoIosSearch } from "react-icons/io";
import { AiOutlinePlusCircle } from "react-icons/ai";
import "../vehicleManagement/vehicleManagement.css";
import VehicleComponent from "./components/VehicleComponent";
import VehicleTable from "./components/VehicleComponent";
import { Link, useHistory } from "react-router-dom";
import { GoAlert } from "react-icons/go";
import VehicleModal from "./components/VehicleModal";
import truckImage from "../../assets/img/truck_image.jpg";
import { IoIosClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { getTruckList, getTruckType, deleteVehicle } from "store/vechileType/actions";

const styles = {
  dashboardTxt: {
    fontSize: "14px",
    fontFamily: "Montserrat",
    color: "rgb(139,139,139)",
  },
  jobsTxt: {
    fontSize: "14px",
    fontFamily: "Montserrat",
    textDecoration: "underline",
    fontWeight: "500",
  },
  breadcrumb: {
    padding: 0,
  },
  searchJobScreen: {
    width: "300px",
    height: "28px",
    backgroundColor: "white",
    border: "1px solid rgb(223,223,223)",
  },
  searchIcon: {
    paddingLeft: "3px",
  },

  searchJobInput: {
    // paddingLeft: '10px',
    border: "0px solid",
    outline: "none",
    height: "17px",
    fontSize: "14px",
    fontFamily: "Montserrat",
    color: "rgb(223,223,223)",
  },
  trHeading: {
    backgroundColor: "rgb(109,109,109)",
    // fontSize: "15px",
    fontFamily: "Montserrat",
    fontWeight: "600",
    color: "white",
    borderRadius: "30px",
    marginTop: "10px",
    // width: '800px',
  },
  textHeading: {
    fontSize: "12px",
    fontFamily: "Montserrat",
    fontWeight: "700",
    color: "white",
    textTransform: "none",
  },
  customHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    // marginLeft: '30px'
  },
  CustomTitle: {
    color: "rgb(109, 109, 109)",
    fontSize: "12px",
    fontWeight: "500",
    opacity: "60",
    fontFamily: "Montserrat",
    marginLeft: "10px",
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "10px",
    paddingTop: "8px",
    width: "300px",
  },
  sureTxt: {
    textAlign: "center",
    fontSize: "20px",
    fontFamily: "Montserrat",
    color: "black",
    fontWeight: "600",
  },
  deleteTxt: {
    textAlign: "center",
    fontSize: "15px",
    fontFamily: "Montserrat",
    color: "rgb(109,109,109)",
    fontWeight: "600",
  },
  backBtnStyle: {
    color: "rgb(0, 118, 255)",
    border: "1px solid rgb(223, 223, 223)",
    fontFamily: "Montserrat",
    backgroundColor: "#fff",
    borderRadius: "7px",
    fontSize: "15px",
    width: "80px",
    height: "30px",
    fontWeight: "700",
  },
  buttonStyle: {
    backgroundColor: "rgb(255, 72, 91)",
    borderRadius: "7px",
    border: "none",
    color: "white",
    marginLeft: "21px",
    marginRight: "10px",
    borderRadius: "7px ",
    width: "120px",
    height: "30px",
  },
  spinnerStyles: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "70vh",
  }
};

function VehicleManagement() {

  const [driverDetail, setDriverDetail] = useState(false);
  const [show, setShow] = useState(false);
  const [del, setDel] = useState(false);
  const handleClose = () => setShow(false);
  const [isLoading, setIsLoading] = useState(true);
  const handleDelClose = () => setDel(false);
  const handledelShow = () => setDel(true);
  const truckTypeList = useSelector(state => state?.truckTypeReducer?.truckTypeList)
  const history = useHistory()
  const dispatch = useDispatch()

  const numberOfTrucktype = truckTypeList?.length
  console.log("truckTypeList",truckTypeList);

  useEffect(() => {
    dispatch(getTruckList()).then((response) => {
      setIsLoading(false)
    })
  }, [])

  const handleShow = (vehicle) => {
    setDriverDetail(vehicle)
    setShow(true)
  }
  const handleShowDeleteModal = (vehicle) => {
    setDriverDetail(vehicle.id)
    setDel(true)
  };

  const deleteVehicleMethod = () => {
    setIsLoading(true)
    dispatch(deleteVehicle(driverDetail)).then((response) => {
      dispatch(getTruckList())
      setIsLoading(false)
    })
    setDel(false)
  }

  if (isLoading) {
    return (
      <div style={styles.spinnerStyles}>
        <Spinner animation="border" variant="primary">
        </Spinner>
      </div>
    )
  }


  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <ul className="breadcrumb ps-0 ms-0" style={styles.breadcrumb}>
            <li className="p-0">
              <Link to="/" style={{ ...styles.dashboardTxt }}>Dashboard</Link>
            </li>
              <li>
                <a href="/vehicle-management" style={styles.dashboardTxt}>
                  Management
                </a>
              </li>
              <li>
                <a href="/vehicle-management" style={styles.jobsTxt}>
                  Vehicle Management
                </a>
              </li>
            </ul>
            <Row style={{ backgroundColor: "rgb(247,247,247)" }}>
              <Col md="12">
                <div className="d-flex justify-content-end">
                  <Link
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    to={"/AddVehicle"}
                  >
                    <span className="add_vehicle_text">Add Vehicle</span>
                    <AiOutlinePlusCircle
                      color={"rgb(0,129,229)"}
                      size={23}
                      className="mt-1"
                      onClick={() => history.push("/AddVehicle")}
                    />
                    {/* <DropDownToggle /> */}
                  </Link>
                </div>
              </Col>
            </Row>
            <Row className="m-0 p-0">
              <span className="bankinvoice_text">{numberOfTrucktype} Result</span>
            </Row>
            <Row sm={12} xl={12} className="mt-1 pe-2">
              <Col md={12} className="ms-2 ps-3">
                <div className="table-responsive-sm pe-3 me-2">
                  <table className="table border-none p-0 m-0">
                    <thead style={{ borderRadius: "10px" }}>
                      <tr style={{ ...styles.trHeading, borderRadius: "20px" }}>
                        <th style={styles.textHeading}>Vehicle</th>
                        <th style={styles.textHeading}>Truck Type</th>
                        <th style={styles.textHeading}>Payload</th>
                        <th style={styles.textHeading}>Rego Number</th>
                        <th style={styles.textHeading}>GVM</th>
                        <th style={styles.textHeading}>Assigned Driver</th>
                        <th style={styles.textHeading}>Status</th>
                        <th
                          style={{
                            ...styles.textHeading,
                            color: "rgb(109,109,109)",
                          }}
                        >
                          {"heading"}
                        </th>
                        <th
                          style={{
                            ...styles.textHeading,
                            color: "rgb(109,109,109)",
                          }}
                        >
                          {"head"}
                        </th>
                        <th
                          style={{
                            ...styles.textHeading,
                            color: "rgb(109,109,109)",
                          }}
                        >
                          {"head"}
                        </th>
                      </tr>
                    </thead>
                    {truckTypeList !== null ?
                      truckTypeList.map((value, index) => {
                        console.log("value---------------",value);
                        console.log("value?.vehicle_driver?.driver?.name",value?.vehicle_driver?.driver?.name);

                        return (
                          <VehicleTable
                            handleShow={handleShow}
                            backgroundColor={"rgb(0,126,229)"}
                            title={"Assign Me"}
                            fontSize={"10px"}
                            vehicle={value}
                            key={index.toString()}
                            show={show}
                            onHide={handleClose}
                            handleShowDeleteModal={handleShowDeleteModal}
                            driverName={value?.vehicle_driver?.driver?.name}
                            // onClickEdit={onClickEdit}
                          // driverDetail={driverDetail}
                          />
                        )
                      })
                      : null
                    }
                    <Modal
                      className="modal-mini w-200 mt-5"
                      show={show}
                      onHide={handleClose}
                    // size="xl"
                    >
                      <div style={{ ...styles.modalContainer }}>
                        <Row style={{ width: "430px" }}>
                          <Col
                            xs={12}
                            md={8}
                            style={{
                              borderRight: "1px solid rgb(197,197,197)",
                              width: "400px",
                            }}
                          >
                            <VehicleModal
                              driverDetail={driverDetail}
                              typeofTruck={"Truck Type:"}
                              truckType={"7.5t Tipper"}
                              payloadType={"Payload:"}
                              materialQty={"03t"}
                              numberOfRego={"Rego Number:"}
                              regoNumber={"YF19 RZC"}
                              assignedDriver={"Assigned Driver:"}
                              assignedNumber={"Tim"}
                            />
                          </Col>

                          <Col
                            xs={6}
                            md={3}
                            style={{ width: "300px", marginTop: "10px" }}
                          >
                            <img
                              src={driverDetail?.image}
                              alt=""
                              className="truck-image"
                            />
                          </Col>
                        </Row>
                      </div>
                      {/* </Container> */}
                      {/* </Modal.Body> */}
                    </Modal>
                    <Modal
                      show={del}
                      onHide={handleDelClose}
                    // backdrop="static"
                    // keyboard={false}
                    // centered
                    // dialogClassName="my-modal"
                    >
                      <div style={{ ...styles.customHeader }}>
                        <IoIosClose size={"25px"} onClick={handleDelClose} />
                      </div>
                      <Modal.Body>
                        <Row
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Col xs={6} className="ms-0 mt-2 center">
                            <GoAlert
                              size={60}
                              color={"rgb(255,111,26)"}
                              style={{ alignSelf: "center" }}
                            />
                          </Col>
                        </Row>
                        <Row
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Col xs={6} className="ms-0 mt-2 center">
                            <p style={styles.sureTxt}>Are you sure?</p>
                          </Col>
                        </Row>
                        <Row
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Col className="ms-2 mt-1 center">
                            <p style={styles.deleteTxt}>
                              You want to delete this vehicle?
                            </p>
                          </Col>
                        </Row>
                      </Modal.Body>
                      <Row
                        style={{
                          textAlign: "center",
                          justifyContent: "flex-end",
                          paddingBottom: "20px",
                          paddingRight: "40px",
                        }}
                      >
                        <button
                          variant="none"
                          onClick={handleDelClose}
                          style={{ ...styles.backBtnStyle }}
                        >
                          Cancel
                        </button>
                        <button
                          variant="none"
                          onClick={deleteVehicleMethod}
                          style={{ ...styles.buttonStyle }}
                        >
                          Yes, Delete It
                        </button>
                      </Row>
                    </Modal>
                  </table>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default VehicleManagement;
