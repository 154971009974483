import React from "react";
import "../../assets/css/demo.css"
import "./switchtoggle.css"

const SwitchToggle = (props) => {
  const { drawerTxt, toggleSwitch, onValueChange } = props;
  console.log("toggleSwitch",toggleSwitch);
  console.log("drawerTxt",drawerTxt);
  console.log("onValueChange",onValueChange);
  return (
    <div className="Toggle-container" onClick={onValueChange}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <span className="drawerTxt">{drawerTxt}</span>
        <div className={"toggle"} style={{marginTop: '10px', display: 'flex', transition: '0.3s', justifyContent: toggleSwitch ? 'flex-end' :'flex-start', borderColor: toggleSwitch ? 'rgb(0 126 229)' : 'rgb(14, 14, 14)'}}>
          <div className={'toggle_left'} style={{background: toggleSwitch ? 'rgb(0 126 229)' : 'rgb(14, 14, 14)', transition: '0.3s'}}></div>
        </div>
      </div>
    </div>
  );
};

export default SwitchToggle;
