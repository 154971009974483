/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Spinner, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import 'font-awesome/css/font-awesome.min.css';
import "./assets/css/animate.min.css";
import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";
import "./assets/css/demo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import store from "./store/store"
import { Provider } from 'react-redux';
import AdminLayout from "layouts/Admin.js";
import Login from "views/Login";
import { useSelector, useDispatch } from 'react-redux';
import { setUserDetails } from './store/auth/slice'
import axios from "axios"
import ForgotPassword from "views/forgotPassword";
import { firebase, getNotifyToken } from 'services';
import PrivacyPolicy from "views/PrivacyPolicy";
import TermsCondition from "views/TermsCondition";

const root = ReactDOM.createRoot(document.getElementById("root"));

const App = () => {
  const [isLoading, setIsLoading] = useState(true)
  const { token } = useSelector(
    state => state.authReducer,
  )
  const dispatch = useDispatch()
  useEffect(() => {
    firebase
    getSaveUser()
  }, [token])

  const getSaveUser = async () => {
    const userData = await localStorage.getItem("token");
    dispatch(setUserDetails(userData));
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    setIsLoading(false)
  };

  if (isLoading) {
    return (
      <div style={{
        display: "flex",
        flex: 1,
        justifyContent: 'center',
        alignItems: "center",
        height: "70vh"
      }}>
        <Spinner animation="border" variant="primary">
        </Spinner>
      </div>
    )
  }
  if (token) {
    return (
      <AdminLayout />
    )
  }
  else {
    return (
      <Switch>
        <Route path="/" component={(props) => <Login {...props} />} exact/>
        <Route path="/privacyPolicy" component={() => <PrivacyPolicy />}  exact />
        <Route path="/termsCondition" component={() => <TermsCondition />} exact  />
        <Route component={(props) => <Redirect to={{ pathname: '/', state: { from: props.location } }} />} exact  />
      </Switch>
    )
  }
}

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);
