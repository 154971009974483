import React, { useEffect, useState } from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getPages } from "store/auth/actions";
import './TermsCondition.css'

const styles = {
  dashboardTxt: {
    fontSize: "14px",
    fontFamily: "Montserrat",
    color: "rgb(139,139,139)",
  },
  jobsTxt: {
    fontSize: "14px",
    fontFamily: "Montserrat",
    textDecoration: "underline",
    fontWeight: "500",
  },
  breadcrumb: {
    padding: 0,
  },
  center: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
}


const TermsCondition = () => {
  const [state, setState] = useState({
    loading: false,
    tAndC: '',
  })

  const dispatch = useDispatch();

  useEffect(() => {
    getTermsAndCondition();
  }, [])

  const getTermsAndCondition = () => {
    setState(prev => ({...prev, loading: true}));
    dispatch(getPages('tnc')).then(res => {
      if (res?.payload?.success === true) {
        setState(prev => ({...prev, tAndC: res?.payload?.data?.description, loading: false}))
      } else {
        setState(prev => ({...prev, loading: false}))
      }
    })
  }

  const createMarkup = () => {
    return { __html: state.tAndC };
  }

  return (
    <Container fluid>
      <Col style={{margin: '30px 0'}}>
        <span className="profile_Tab_Txt">Terms of Service</span>
      </Col>
      {state.loading ? <div style={styles.center}><Spinner animation="border" variant="primary" /></div> : <Col>
          <div dangerouslySetInnerHTML={createMarkup()} />
        </Col>}
    </Container>
  )
}

export default TermsCondition