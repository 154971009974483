import React, { useState, useEffect } from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Modal,
  Spinner
} from "react-bootstrap";
import DropDownToggle from "components/DropDownToggle/DropDownToggle";
import { IoIosSearch, IoIosClose } from "react-icons/io";
import { AiOutlinePlusCircle } from "react-icons/ai";
import "../vehicleManagement/vehicleManagement.css";
// import VehicleComponent from "./components/VehicleComponent";
import DriverTable from "./components/driverComponent";
import { Link, useHistory } from "react-router-dom";
import DriverModal from "./components/driverModal";
import truckImage from "../../assets/img/driverIcon.png";
import { GoAlert } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { getDriverList, deleteDriver } from "store/driver/actions";
const styles = {
  dashboardTxt: {
    fontSize: "14px",
    fontFamily: "Montserrat",
    color: "rgb(139,139,139)",
  },
  jobsTxt: {
    fontSize: "14px",
    fontFamily: "Montserrat",
    textDecoration: "underline",
    fontWeight: "500",
  },
  breadcrumb: {
    padding: 0,
  },
  searchJobScreen: {
    width: "300px",
    height: "28px",
    backgroundColor: "white",
    border: "1px solid rgb(223,223,223)",
  },
  searchIcon: {
    paddingLeft: "3px",
  },

  searchJobInput: {
    // paddingLeft: '10px',
    border: "0px solid",
    outline: "none",
    height: "17px",
    fontSize: "14px",
    fontFamily: "Montserrat",
    color: "rgb(223,223,223)",
  },
  trHeading: {
    backgroundColor: "rgb(109,109,109)",
    // fontSize: "15px",
    fontFamily: "Montserrat",
    fontWeight: "600",
    color: "white",
    borderRadius: "30px",
    marginTop: "10px",
    // width: '800px',
  },
  textHeading: {
    fontSize: "12px",
    fontFamily: "Montserrat",
    fontWeight: "700",
    color: "white",
    textTransform: "none",
  },
  customHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    // marginLeft: '30px'
  },
  CustomTitle: {
    color: "rgb(109, 109, 109)",
    fontSize: "12px",
    fontWeight: "500",
    opacity: "60",
    fontFamily: "Montserrat",
    marginLeft: "10px",
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "10px",
    paddingTop: "8px",
    width: "300px",
    marginRight: "10px",
  },
  sureTxt: {
    textAlign: "center",
    fontSize: "20px",
    fontFamily: "Montserrat",
    color: "black",
    fontWeight: "600",
  },
  deleteTxt: {
    textAlign: "center",
    fontSize: "15px",
    fontFamily: "Montserrat",
    color: "rgb(109,109,109)",
    fontWeight: "600",
  },
  backBtnStyle: {
    color: "rgb(0, 118, 255)",
    border: "1px solid rgb(223, 223, 223)",
    fontFamily: "Montserrat",
    backgroundColor: "#fff",
    borderRadius: "7px",
    fontSize: "15px",
    width: "80px",
    height: "30px",
    fontWeight: "700",
  },
  buttonStyle: {
    backgroundColor: "rgb(255, 72, 91)",
    borderRadius: "7px",
    border: "none",
    color: "white",
    marginLeft: "21px",
    marginRight: "10px",
    borderRadius: "7px ",
    width: "120px",
    height: "30px",
  },
  driverImage: {
    height: "120px",
    width: "100px",
    borderRadius: "6px",
    marginLeft: "13px",
    marginRight: "20px",
    marginTop: "10px",
  },
  spinnerStyles: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "70vh",
  }
};

function DriverManagement() {

  const handleDelClose = () => setDel(false);
  const handledelShow = () => setDel(true);
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [del, setDel] = useState(false);
  const [driverDetail, setDriverDetail] = useState(false);
  const [idForEdit, setIdForEdit] = useState(null)
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(getDriverList())
      .then((response) => {
        setIsLoading(false)
      })
  }, [])

  const { driverList } = useSelector(
    state => state?.driverReducer,
  );
  const numberOfDerivers = driverList?.length
  const handleClose = () => {
    setShow(false)
    setShowEdit(false)
  };
  const handleShow = (driver) => {
    console.log("driver",driver);
    setDriverDetail(driver)
    setShow(true)
  };
  const handleShowDeleteModal = (driver) => {
    setDriverDetail(driver.id)
    setDel(true)
  };
  const handleEditButton = (id) => {
    setIdForEdit(id);
    setShowEdit(true)
  }
  const deleteDriverMethod = () => {
    setIsLoading(true)
    dispatch(deleteDriver(driverDetail)).then((response) => {
      if (response?.payload?.success) {
        dispatch(getDriverList())
        setIsLoading(false)
      }
    })
    setDel(false)
  }

  if (isLoading) {
    return (
      <div style={styles.spinnerStyles}>
        <Spinner animation="border" variant="primary">
        </Spinner>
      </div>
    )
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <ul className="breadcrumb ps-0 ms-0" style={styles.breadcrumb}>
              <li className="p-0">
              <Link to="/" style={{ ...styles.dashboardTxt }}>Dashboard</Link>
            </li>
              <li>
                <a href="/driver-management" style={styles.dashboardTxt}>
                  Management
                </a>
              </li>
              <li>
                <a href="/driver-management" style={styles.jobsTxt}>
                  Driver Management
                </a>
              </li>
            </ul>
            <Row style={{ backgroundColor: "rgb(247,247,247)" }}>
              <Col md="12">
                <div className="d-flex justify-content-end">
                  {/* <p>hii</p> */}
                  {/* <div style={styles.searchJobScreen}>
                    <IoIosSearch
                      size={21}
                      color={"rgb(197,197,197"}
                      style={styles.searchIcon}
                    />
                    <input
                      type="text"
                      placeholder="Search job"
                      style={styles.searchJobInput}
                    />
                  </div> */}
                  <Link
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    to={"/AddDriver"}
                  >
                    <span className="add_vehicle_text">Add Driver</span>
                    <AiOutlinePlusCircle
                      color={"rgb(0,129,229)"}
                      size={23}
                      className="mt-1"
                      onClick={() => history.push("/addVehicle")}
                    />
                    {/* <DropDownToggle /> */}
                  </Link>
                </div>
              </Col>
            </Row>
            <Row className="m-0 p-0">
              <span className="bankinvoice_text">{numberOfDerivers} Results</span>
            </Row>
            <Row sm={6} xl={12} className="mt-1 pe-2">
              <Col md={12} className="ms-2 ps-3">
                <div className="table-responsive-sm pe-3 me-2">
                  <table className="table border-none p-0 m-0">
                    <thead style={{ borderRadius: "10px" }}>
                      <tr style={{ ...styles.trHeading, borderRadius: "20px" }}>
                        <th style={styles.textHeading}>Driver</th>
                        <th style={styles.textHeading}>Name</th>
                        <th style={styles.textHeading}>Assigned Truck</th>
                        <th style={styles.textHeading}>Driving License No.</th>
                        <th style={styles.textHeading}>Mobile No.</th>
                        <th style={styles.textHeading}>Rego Number</th>
                        <th style={styles.textHeading}>Status</th>
                        <th
                          style={{
                            ...styles.textHeading,
                            color: "rgb(109,109,109)",
                          }}
                        >
                          {"heading"}
                        </th>
                        <th
                          style={{
                            ...styles.textHeading,
                            color: "rgb(109,109,109)",
                          }}
                        >
                          {"heading"}
                        </th>
                        <th
                          style={{
                            ...styles.textHeading,
                            color: "rgb(109,109,109)",
                          }}
                        >
                          {"heading"}
                        </th>
                        {/* <th
                          style={{
                            ...styles.textHeading,
                            color: "rgb(109,109,109)",
                          }}
                        >
                          {"heading"}
                        </th> */}
                      </tr>
                    </thead>

                    {driverList !== null ?
                      driverList.map((value, index) => {
                        return (
                          <DriverTable
                            handleShowDeleteModal={handleShowDeleteModal}
                            handleShow={handleShow}
                            backgroundColor={"rgb(0,126,229)"}
                            title={"Assign Me"}
                            fontSize={"10px"}
                            driver={value}
                            key={index.toString()}
                            show={show}
                            onHide={handleClose}
                            driverDetail={driverDetail}
                            handleEditButton={handleEditButton}
                          />
                        )
                      })
                      : null
                    }

                    {/* <DriverTable
                      handleShow={handleShow}
                      backgroundColor={"rgb(0,126,229)"}
                      title={"Assign Me"}
                      fontSize={"10px"}
                      handledelShow={handledelShow}
                    />

                    <DriverTable
                      handleShow={handleShow}
                      backgroundColor={"rgb(0,126,229)"}
                      title={"Assign Me"}
                      fontSize={"10px"}
                      handledelShow={handledelShow}
                    /> */}
                    <Modal
                      className="modal-mini w-200 mt-5 modal-dialog-centered"
                      show={show}
                      onHide={handleClose}
                      style={{display:'flex',alignItems:'center',justifyContent:'center'}}
                    // size="xl"
                    >
                      {/* <Modal.Header
                        closeButton
                        className="modal-header no-border"
                      ></Modal.Header> */}
                      {/* <Modal.Body className="modalTextStyle"> */}
                      {/* <Container> */}
                      <div style={{ ...styles.modalContainer }}>
                        {/* <div style={{ ...styles.customHeader }}>
              <Modal.Title className="m-2 font-weight-bold">Are you sure?</Modal.Title>
              <IoIosClose size={"25px"} onClick={handleClose} />
            </div> */}
                        <Row style={{ width: "500px" }}>
                          <Col
                            xs={12}
                            md={8}
                            style={{
                              borderRight: "1px solid rgb(197,197,197)",
                              width: "400px",
                            }}
                          >
                            <DriverModal
                              driverDetail={driverDetail}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={3}
                            style={{ width: "300px", marginTop: "15px" }}
                          >
                            <img
                              src={driverDetail.image}
                              alt=""
                              className="truck-image"
                            />
                          </Col>
                          <div
                            style={{
                              ...styles.customHeader,
                              justifySelf: 'flex-end',
                              // marginLeft: "10px",
                              // marginBottom: "15px",
                              position: 'absolute',
                              bottom: 0,
                              top:0,
                              right:0

                            }}
                          >
                            <IoIosClose
                              size={"28px"}
                              onClick={handleClose}
                            />
                          </div>
                        </Row>
                      </div>
                      {/* </Container> */}
                      {/* </Modal.Body> */}
                    </Modal>
                    <Modal
                      className="modal-mini w-200 mt-5"
                      show={showEdit}
                      onHide={handleClose}
                    // size="xl"
                    >
                      <Row style={{ width: "500px" }}>
                        <Col
                          xs={12}
                          md={8}
                          style={{
                            borderRight: "1px solid rgb(197,197,197)",
                            width: "600px",
                          }}
                        >
                        </Col>

                        <Col
                          xs={12}
                          md={3}
                          style={{ width: "300px", marginTop: "10px" }}
                        >

                          {idForEdit}
                        </Col>
                        <div
                          style={{
                            ...styles.customHeader,
                            marginLeft: "10px",
                            marginBottom: "5px",
                          }}
                        >
                          <IoIosClose
                            size={"28px"}
                            onClick={handleClose}
                          />
                        </div>
                      </Row>
                    </Modal>

                    <Modal
                      show={del}
                      onHide={handleDelClose}
                    // backdrop="static"
                    // keyboard={false}
                    // centered
                    // dialogClassName="my-modal"
                    >
                      <div style={{ ...styles.customHeader }}>
                        <IoIosClose size={"25px"} onClick={handleDelClose} />
                      </div>
                      <Modal.Body>
                        <Row
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Col xs={6} className="ms-0 mt-2 center">
                            <GoAlert
                              size={60}
                              color={"rgb(255,111,26)"}
                              style={{ alignSelf: "center" }}
                            />
                          </Col>
                        </Row>
                        <Row
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Col xs={6} className="ms-0 mt-2 center">
                            <p style={styles.sureTxt}>Are you sure?</p>
                          </Col>
                        </Row>
                        <Row
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Col className="ms-2 mt-1 center">
                            <p style={styles.deleteTxt}>
                              You want to delete this driver?
                            </p>
                          </Col>
                        </Row>
                      </Modal.Body>
                      <Row
                        style={{
                          textAlign: "center",
                          justifyContent: "flex-end",
                          paddingBottom: "20px",
                          paddingRight: "40px",
                        }}
                      >
                        <button
                          variant="none"
                          onClick={handleDelClose}
                          style={{ ...styles.backBtnStyle }}
                        >
                          Cancel
                        </button>
                        <button
                          variant="none"
                          onClick={deleteDriverMethod}
                          style={{ ...styles.buttonStyle }}
                        >
                          Yes, Delete It
                        </button>
                      </Row>
                    </Modal>
                  </table>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container >
    </>
  );
}

export default DriverManagement;
