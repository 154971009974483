import React, { useEffect, useState } from "react";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Modal,
} from "react-bootstrap";
import { IoIosSearch, IoIosClose } from "react-icons/io";
import "../views/Track.css";
import route from "../assets/img/routeImg.png";
import arrow from "../assets/img/arrowImg.png";
import arrowDown from "../assets/img/arrowDown.png";
import Table from "react-bootstrap/Table";
import phone from "../assets/img/phone.png";
import { Link } from "react-router-dom";
import OrderDetail from "components/OrderDetail/OrderDetail";
import MapContainer from "../components/Map/Map";
import "../../src/views/Track.css";
import { CardBody, CardTitle } from "reactstrap";
import { useHistory, useLocation } from "react-router";
import moment from "moment";
import GoogleMapReact from "google-map-react";
import { IoMdPin } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import {
  getTruckList,
  getTruckType,
  deleteVehicle,
  addVehicleInTransport,
} from "store/vechileType/actions";
import { getJobList, assignOrderToMe } from "store/driver/actions";
import Toast, { MainToastContainer } from "utils/Toast";
// import { useHistory } from "react-router";
import { getLoadAssumption } from "store/driver/actions";

const AnyReactComponent = ({ text }) => (
  <div style={{ width: "100px" }}>
    <IoMdPin size={30} color="rgb(0,126,229)" />
    <p style={{ color: "rgb(0,126,229)" }}>{text}</p>
  </div>
);

const styles = {
  dashboardTxt: {
    fontSize: "14px",
    fontFamily: "Montserrat",
    color: "rgb(139,139,139)",
  },
  jobsTxt: {
    fontSize: "14px",
    fontFamily: "Montserrat",
    textDecoration: "underline",
    fontWeight: "500",
  },
  breadcrumb: {
    padding: 0,
  },
  headingTxt: {
    fontSize: "12px",
    color: "rgb(23,32,63)",
    fontFamily: "Montserrat",
    fontWeight: "600",
    textTransform: "none",
  },
  tableFirstValue: {
    padding: 0,
  },
  table: {
    borderCollapse: "collapse",
  },
  deliveryTimesTxt: {
    // color: "rgb(109,109,109)",
    fontSize: "12px",
    // padding-left: 10px;
    fontFamily: "Montserrat",
    fontWeight: "600",
    // padding-bottom: 20px !important;
  },
  deliveryTimesContainer: {
    width: "310px",
    height: "130px",
    boxShadow: "2px 2px 2px 2px rgba(0, 126, 229, 0.2)",
    borderRadius: "10px",
    marginTop: "20px",
    padding: 10,
  },
  routeHeadingContainer: {
    borderBottom: "1px solid rgb(183,183,183)",
    // height: "30px",
    alignItems: "center",
  },
  additionalInfoTxt: {
    fontSize: "13px",
    color: "rgb(87,87,87)",
    fontFamily: "Montserrat",
    fontWeight: "400",
  },
  routeContainer: {
    backgroundColor: "white",
    borderRadius: "10px",
    // width: "310px",
    boxShadow: "2px 2px 2px 2px rgba(0, 126, 229, 0.2)",
  },
  mapContainer: {
    borderRadius: "10px",
    // /* width: 70%; */
    height: "200px",
    marginTop: "10px",
    /* margin-left: 17px; */
    width: "620px",
    boxShadow: "2px 2px 2px 2px rgba(0, 126, 229, 0.2)",
  },
  buttonTrackContainerStyle: {
    width: "120px",
    height: "30px",
    borderRadius: "7px",
    border: "none",
    color: "white",
    fontSize: "15px",
    fontWeight: "600",
    backgroundColor: "rgb(0,126,229)",
    marginBottom: "20px",
    marginRight: "10px",
  },
  contactInfoContainer: {
    flexDirection: "column",
    marginTop: "10px",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: 2,
  },
  modalTextStyle: {
    color: "rgb(109, 109, 109)",
    fontSize: "13px",
    fontWeight: "500",
    opacity: "60",
    fontFamily: "Montserrat",
  },
  backBtnStyle: {
    color: "rgb(0, 118, 255)",
    border: "1px solid rgb(223, 223, 223)",
    fontFamily: "Montserrat",
    backgroundColor: "#fff",
    borderRadius: "7px",
    fontSize: "15px",
    width: "80px",
    height: "30px",
  },
  myModal: {
    width: "100px",
  },
  modalHeader: {
    borderBottom: "none",
  },
  modalFooter: {
    borderTop: "none",
  },
  buttonStyle: {
    backgroundColor: "rgb(0, 126, 229)",
    borderRadius: "7px",
    border: "none",
    color: "white",
    marginLeft: "21px",
    marginRight: "10px",
    borderRadius: "7px ",
    width: "120px",
    height: "30px",
  },
  customHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  CustomTitle: {
    color: "rgb(109, 109, 109)",
    fontSize: "12px",
    fontWeight: "500",
    opacity: "60",
    fontFamily: "Montserrat",
    marginLeft: "10px",
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "10px",
    paddingTop: "8px",
  },
  orderIdContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "rgb(109,109,109)",
    paddingTop: "5px",
    marginBottom: "15px",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  orderIdText: {
    fontSize: "16px",
    fontFamily: "Montserrat",
    fontWeight: "500",
    color: "white",
  },
  distanceContainer: {
    justifyContent: "flex-start",
    alignItems: "flex-start",
    alignContent: "flex-start",
    display: "flex",
    marginTop: 0,
  },
};

const Track = () => {
  const location = useLocation();
  const myloadDetail = location.state.state.item;
  const latitude = myloadDetail?.schedule?.lat;
  const longitude = myloadDetail?.schedule?.lng;
  const [transporterRate, setTransporterRate] = useState("");

  const [state, setState] = useState({
    showModalOrderId: "",
  });

  const history = useHistory();

  const openPicker = () => {};

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTruckList());
  }, []);

  const defaultProps = {
    center: {
      lat: latitude,
      lng: longitude,
    },
    zoom: 11,
  };

  const vehicleList = useSelector(
    (state) => state.truckTypeReducer?.truckTypeList
  );

  const onSave = async (item, parentItem) => {
    const payload = {
      vehicle_id: item?.id,
      order_shipment_id: parentItem?.id,
      date: Date,
      orderId: myloadDetail?.order_id,
      vehicle_type_id: item?.vehicle_type_id,
    };

    await dispatch(addVehicleInTransport(payload)).then((response) => {
      if (response?.payload?.success) {
        Toast(response?.payload?.message);
      } else {
        Toast(response?.payload?.message);
      }
    });
  };

  const hitAssignMe = () => {
    history.push("/jobs", { state: { item: myloadDetail } });
    if (state.showModalOrderId) {
      dispatch(assignOrderToMe({ order_id: state.showModalOrderId })).then(
        (res) => {
          if (res?.payload?.success === true) {
            Toast(res?.payload?.message);
          } else {
            setState((prev) => ({ ...prev, data: jobListData }));
            Toast(res?.payload?.message);
          }
        }
      );
    }
  };

  const toggleHandle = (orderId) => {
    if (orderId) {
      setState((prev) => ({ ...prev, showModalOrderId: orderId }));
    } else {
      setState((prev) => ({ ...prev, showModalOrderId: "" }));
    }
  };

  React.useEffect(() => {
    getLoadAssumptionData();
    // addNotifyToken();
  }, []);

  const getLoadAssumptionData = () => {
    dispatch(getLoadAssumption()).then((res) => {
      console.log("res", res?.payload?.data[0]);
      setTransporterRate(res?.payload?.data[0]);
    });
  };

  var da =
    myloadDetail?.total_price -
    myloadDetail?.supplier_material?.rate * myloadDetail?.quantity;
  var comm = transporterRate?.transporter_commission / 100;
  var pricing = da - da * (transporterRate?.transporter_commission / 100);
  const pricing1 = Math.round(pricing * Math.pow(10, 2)) / Math.pow(10, 2);

  console.log("myloadDetail",myloadDetail?.supplier_material?.supplier?.name);

  return (
    <Container fluid>
      <ul className="breadcrumb ps-0 ms-0" style={styles.breadcrumb}>
        <li className="p-0">
          <a style={{ ...styles.dashboardTxt }}>Dashboard</a>
        </li>
        <li className="p-0">
          <a style={{ ...styles.dashboardTxt }}>
            {location.pathname !== "/jobs/details" ? "My Loads" : "Jobs"}
          </a>
        </li>
        <li>
          <a style={styles.jobsTxt}>{myloadDetail?.order_id}</a>
        </li>
      </ul>
      <div style={styles.orderIdContainer}>
        <p style={styles.orderIdText}>Order ID: {myloadDetail?.order_id}</p>
        <p style={styles.orderIdText}>{pricing1 ? `$` + pricing1 : 0}</p>
      </div>
      <div class="container">
        <div class="row">
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              width: "100%",
              height: "100%",
            }}
          >
            <div
              class="col-sm-8 col-lg-8"
              style={{ width: "100%", height: "100%" }}
            >
              <div class="card">
                <div class="card-body">
                  <div style={{ width: "100%", height: "230px" }}>
                    <GoogleMapReact
                      bootstrapURLKeys={{
                        key: "AIzaSyDmVZGX3PBi1ARa1HUBDmnSEAcqc8NbiqM",
                      }}
                      defaultCenter={defaultProps.center}
                      defaultZoom={defaultProps.zoom}
                    >
                      <AnyReactComponent
                        lat={latitude}
                        lng={longitude}
                        text={myloadDetail?.site?.address}
                      />
                    </GoogleMapReact>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-lg-4" style={{ width: "25%" }}>
              <div class="card">
                <div class="card-body p-0 m-0">
                  <span className="routeTxt p-0 m-0">Route</span>
                  <div
                    style={{
                      flexDirection: "column",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <span className="pickUpTxt" style={{ display: "flex" }}>
                      {"Pick Up:"}
                      <span style={{ color: "black" }}>
                        {` ` +
                          myloadDetail?.supplier_material?.supplier?.name +
                          `, ` +
                          myloadDetail?.supplier_material?.supplier?.user
                            ?.address}
                      </span>
                    </span>

                    <div style={styles.distanceContainer}>
                      <img
                        src={route}
                        alt=""
                        className="routeImgStyle"
                        style={{ height: "100%" }}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifySelf: "center",
                          marginTop: "12%",
                        }}
                      >
                        <span>
                          {myloadDetail?.distance
                            ? myloadDetail?.distance + myloadDetail?.d_unit
                            : 0 + `km`}
                        </span>
                      </div>
                    </div>

                    <div className="routePathContainer m-0 p-0">
                      <span className="pickUpTxt">
                        {"Drop Off:"}
                        <span style={{ color: "black" }}>
                          {` ` +
                            myloadDetail?.site?.name +
                            `, ` +
                            myloadDetail?.site?.address}{" "}
                        </span>
                      </span>
                    </div>

                    <div style={{ flexDirection: "row", display: "flex" }}>
                      <div
                        style={{ flexDirection: "column", display: "flex" }}
                      ></div>
                      <div style={{ flexDirection: "row" }}></div>
                    </div>
                  </div>
                  <div className="m-0 justify-flex-start"></div>
                  <div style={{ flexDirection: "row", display: "flex" }}>
                    <div style={{ flexDirection: "row", marginLeft: "15px" }}>
                      {location.pathname === "/jobs/details" ? (
                        <div style={{}}>
                          <button
                            style={styles.buttonTrackContainerStyle}
                            onClick={() => toggleHandle(myloadDetail?.order_id)}
                          >
                            {"Assign Me"}
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              width: "100%",
              height: "100%",
            }}
          >
            <div class="col-sm-6 col-lg-8">
              <div class="card">
                <table style={styles.table}>
                  <thead>
                    <tr style={styles.tableFirstValue}>
                      <th style={styles.headingTxt}>Material</th>
                      <th style={styles.headingTxt}>Weight</th>
                      <th style={styles.headingTxt}>Truck Type</th>
                      <th style={styles.headingTxt}>
                        {location.pathname === "/jobs/details"
                          ? null
                          : "Select Vehicle"}
                      </th>
                      <th style={styles.headingTxt}>Date & Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {myloadDetail?.shipments.map((i) => {
                      const totalEarnings =
                        Math.round(i?.quantity * Math.pow(10, 2)) /
                        Math.pow(10, 2);
                      const rate =
                        Math.round(i?.price * Math.pow(10, 2)) /
                        Math.pow(10, 2);
                      const date = moment
                        .utc(i?.date)
                        .local()
                        .format("DD/MM/YY");
                      const times = moment
                        .utc(i?.date)
                        .local()
                        .format("hh:mm a");
                      const time1 =
                        location.pathname !== "/jobs/details"
                          ? moment(i?.date).format("hh:mm a")
                          : moment(i?.date)
                              .subtract(1, "hour")
                              .format("hh:mm a");
                      const payload = i?.vehicle_type?.payload;
                      const level = myloadDetail?.site?.allowed_vehicle_type_id;

                      return (
                        <OrderDetail
                          isJobsDetails={location.pathname === "/jobs/details"}
                          materialValue={
                            myloadDetail?.supplier_material?.material?.name
                          }
                          materialQty={totalEarnings + i?.unit}
                          rate={`$` + rate}
                          truckType={i?.vehicle_type?.name}
                          openPicker={openPicker}
                          time={date}
                          date={i?.status === "delivered" ? times : time1}
                          onSave={(item) => onSave(item, i)}
                          status={i?.shipment_transporter === null}
                          price={`$` + i?.payment?.final_amount}
                          truckName={i?.shipment_transporter?.vehicle?.rego}
                          payload={payload}
                          level={level}
                          vehicle={i?.vehicle_type?.name}
                          list={vehicleList}
                        />
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>

            <div class="col-sm-6 col-lg-4">
              <div class="card">
                <div class="card-body">
                  <div style={styles.routeHeadingContainer}>
                    <span className="routeTxt">Actual Delivered Times</span>
                  </div>

                  {myloadDetail?.shipments?.map((i) => {
                    var date = moment(i?.date).format("DD/MM/YYYY");
                    var time = moment(i?.date).format("hh:mm a");
                    var date1 = moment
                      .utc(i?.date)
                      .local()
                      .format("DD/MM/YYYY");
                    const time1 =
                      location.pathname !== "/jobs/details"
                        ? moment.utc(i?.date).local().format("hh:mm a")
                        : moment(i?.date).subtract(1, "hour").format("hh:mm a");

                    return (
                      <div>
                        <span
                          style={{
                            ...styles.deliveryTimesTxt,
                            color:
                              i?.status === "delivered"
                                ? "#FF6F1A"
                                : "rgb(109,109,109)",
                          }}
                        >
                          {i?.status === "delivered"
                            ? i?.quantity +
                              i?.unit +
                              `@` +
                              time1 +
                              ` on ` +
                              date1
                            : i?.quantity +
                              i?.unit +
                              `@` +
                              time +
                              ` on ` +
                              date}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              width: "100%",
              height: "100%",
            }}
          >
            <div class="col-sm-6 col-lg-8">
              <div class="card">
                <div class="card-body">
                  <span
                    style={{
                      ...styles.additionalInfoTxt,
                      marginTop: "10px",
                      padding: 5,
                    }}
                  >
                    One of our Building Contractors has ordered materials from {" "}
                    {myloadDetail?.supplier_material?.supplier?.name}. This order 
                  </span>
                  <span style={{ ...styles.additionalInfoTxt, padding: 5, paddingLeft: 0 }}>
                    is now ready for a transporter to collect haul and deliver!
                  </span>
                </div>
              </div>
            </div>
            <div
              className="col-sm-6 col-lg-4"
              style={{ justifyItems: "flex-start", display: "flex" }}
            >
              <div
                class="card card2 d-flex  justify-content-flex-start ps-2"
                style={{ boxShadow: "1px, 1px, 2px, 2px, rgb(0,126,229,0.9)" }}
              >
                <span className="routeTxt p-0 m-0 mt-4">Contact Info.</span>
                <div style={styles.contactInfoContainer}>
                  <div
                    style={{
                      flexDirection: "row",
                      padding: 2,
                      display: "flex",
                      marginTop: 0,
                    }}
                  >
                    <img
                      src={arrow}
                      alt=""
                      className="arrowStyle mt-2"
                      style={{ paddingRight: "30px" }}
                    />
                    <span className="pickUpTxt mt-0 pt-0">Pick Up</span>
                  </div>
                  <span className="serviceTxt">
                    {myloadDetail?.supplier_material?.supplier?.user?.name}
                  </span>
                  <div style={{ flexDirection: "row", paddingLeft: "60px" }}>
                    <img src={phone} alt="" className="phoneServiceStyle" />
                    <span className="phoneServiceTxt">
                      {myloadDetail?.supplier_material?.supplier?.user?.mobile}
                    </span>
                  </div>
                </div>
                <div style={{ flexDirection: "row", padding: 0 }}>
                  <img
                    src={arrowDown}
                    alt=""
                    className="arrowStyle"
                    style={{ paddingRight: "30px" }}
                  />
                  <span className="pickUpTxt">Drop Off</span>
                </div>
                <span className="serviceTxt" style={{ paddingLeft: "60px" }}>
                  {myloadDetail?.site?.site_members[0]?.user?.name +
                    `(` +
                    myloadDetail?.site?.site_members[0]?.user?.role?.name +
                    `)`}
                </span>
                <div style={{ flexDirection: "row", paddingLeft: "60px" }}>
                  <img src={phone} alt="" className="phoneServiceStyle" />
                  <span className="phoneServiceTxt">
                    {myloadDetail?.site?.site_members[0]?.user?.mobile}
                  </span>
                </div>
              </div>
            </div>
            <div className="row"></div>
          </div>
        </div>
      </div>

      <Modal
        className="modal-mini w-100 mt-5"
        show={state.showModalOrderId}
        onHide={() => toggleHandle()}
      >
        <div style={styles.modalContainer}>
          <div style={styles.customHeader}>
            <Modal.Title className="m-2 font-weight-bold">
              Are you sure?
            </Modal.Title>
            <IoIosClose size={"25px"} onClick={() => toggleHandle()} />
          </div>
          <p style={styles.CustomTitle}>
            You want to Assign yourself to this job?
          </p>
        </div>
        <div
          className="d-flex flex-row justify-content-end"
          style={{ paddingBottom: "10px" }}
        >
          <button
            variant="none"
            onClick={() => toggleHandle()}
            style={styles.backBtnStyle}
          >
            Back
          </button>
          <button
            variant="none"
            onClick={() => hitAssignMe()}
            style={styles.buttonStyle}
          >
            Yes Assign Me
          </button>
        </div>
      </Modal>
      <MainToastContainer />
    </Container>
  );
};

export default Track;
