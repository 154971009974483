import React from 'react'
import weight from '../../../assets/img/weight.png';
const DriverModal = (props) => {

    const { name, mobile, driver_vehicle, document } = props?.driverDetail || {};
    const { number } = document || {}
    const { rego, vehicle_type } = driver_vehicle?.vehicle || {}

    return (
        <>
            <div style={{ flexDirection: 'column', display: 'flex' }}>
                <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                    <span className='typeofTruck'>{"Driver Name:"}</span>
                    <span className="modal_text">{name}</span>
                </div>
                <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                    <span className='typeofTruck'>{"Mobile Number:"}</span>
                    <div style={{ flexDirection: 'row', marginTop: '5px' }}>
                        {/* <img src={weight} alt='' className="weightStyle" /> */}
                        <span className="modal_text">{mobile}</span>
                    </div>
                </div>
                <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', }}>
                    <span className='typeofTruck'>{"Driving License No:"}</span>
                    <span className='modal_text'>{number}</span>
                </div>
                <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                    <span className='typeofTruck'>{"Assigned Truck:"}</span>
                    <div style={{ display: 'flex' }}>
                        <span className='modal_text'>{vehicle_type?.name}</span>
                    </div>
                </div>
                <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', marginRight: '6px' }}>
                    <span className='typeofTruck'>{"Rego Number:"}</span>
                    <span className='modal_text'>{rego}</span>
                </div>
            </div>
        </>
    )
}

export default DriverModal







