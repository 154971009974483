import React from "react";
import {
  Container,
  Row,
  Col,
} from "react-bootstrap";
import TopTabBar from "components/TopTabBar/TopTabBar";
import { Link } from "react-router-dom";

const TableList = () => {
  return (
      <Container fluid>
        <Row>
          <Col md="12">
            <ul className="breadcrumb ps-0 ms-0" style={styles.breadcrumb}>
            <li className="p-0">
              <Link to="/" style={{ ...styles.dashboardTxt }}>Dashboard</Link>
            </li>
              <li>
                <a href="/my-loads" style={styles.jobsTxt}>
                  MyLoads
                </a>
              </li>
            </ul>
            <Row>
              <Col md={12}>
                <TopTabBar />
              </Col>
            </Row>
          </Col>
          <Col md="12"></Col>
        </Row>
      </Container>
  );
}

const styles = {
  dashboardTxt: {
    fontSize: "14px",
    fontFamily: "Montserrat",
    color: "rgb(139,139,139)",
  },
  jobsTxt: {
    fontSize: "14px",
    fontFamily: "Montserrat",
    textDecoration: "underline",
    fontWeight: "500",
  },
  breadcrumb: {
    padding: 0,
  },
  searchJobScreen: {
    width: "300px",
    height: "28px",
    backgroundColor: "white",
    border: "1px solid rgb(223,223,223)",
  },
  searchIcon: {
    paddingLeft: "3px",
  },

  searchJobInput: {
    // paddingLeft: '10px',
    border: "0px solid",
    outline: "none",
    height: "17px",
    fontSize: "14px",
    fontFamily: "Montserrat",
    color: "rgb(223,223,223)",
  },
};


export default TableList;
