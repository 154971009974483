import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ProfileForm from "components/ProfileForm/ProfileForm";
import Toast, { MainToastContainer } from "utils/Toast";
import { getUserProfile, setUserProfile } from "store/auth/actions";
import { uploadImage } from "store/uploadImage/actions";
import album from "../assets/img/album.png";
import edit from "../assets/img/edit.png";
import "./Profile.css"
import "./EditProfile.css"
import { isValidDigit } from "utils/isValidate";
import { isValidName } from "utils/isValidate";
import { isValidEmail } from "utils/isValidate";

const Profile = () => {
  const dispatch = useDispatch();

  const { userDetails } = useSelector(state => state.authReducer);

  const [state, setState] = useState({
    loading: false,
    image: '',
    userDetails: {
      name: '',
      businessName: '',
      businessEmail: '',
      businessMobileNumber: '',
      address: '',
      postcode: '',
      image: '',
      abn: '',
    }
  })

  console.log('imageimage', state)

  useEffect(() => {
    getUserProfileData();
  }, []);

  useEffect(() => {
    if (userDetails) {
      setState(prev => ({ ...prev, userDetails: userDetails }));
    }
  }, [userDetails])

  const getUserProfileData = (isLoading) => {
    !isLoading && setState(prev => ({ ...prev, loading: true }));
    dispatch(getUserProfile()).then(res => setState(prev => ({ ...prev, loading: false })))
  }

  const checkEnable = () => {
    let { image, ...data } = state.userDetails
    if (JSON.stringify(state.userDetails) === JSON.stringify(userDetails)) {
      return false;
    } else if (Object.values(data).filter(item => item !== '').length === 0) {
      return false;
    }
    return true;
  }

  const handleSave = (e) => {
    setState(prev => ({ ...prev, loading: true }))
    e.preventDefault();
     if (userDetails.image !== state.userDetails.image) {
      const formData = new FormData();
      formData?.append('image', state.image, state.image?.name);

      dispatch(uploadImage(formData)).then(res => {
        if (res?.payload?.success === true && res?.payload?.data?.url) {
          updateUserProfileData(res?.payload?.data?.url)
        } else {
          Toast(res?.payload?.message)
          setState(prev => ({ ...prev, loading: false }))
        }
      })
    } else {
      updateUserProfileData()
    }

  };

  const updateUserProfileData = image => {
    let data = {};

    Object.keys(state.userDetails).forEach(item => {
      let value = state.userDetails[item];
      if (value !== userDetails[item]) {
        if (item === 'businessName') {
          data = { ...data, company: { ...data.company, name: value } }
        } else if (item === 'abn') {
          data = { ...data, company: { ...data.company, abn: value } }
        } else if (item === 'image' && image) {
          data = { ...data, company: { ...data.company, image: image } }
        } else if (item === 'businessEmail') {
          data['email'] = value;
        } else if (item === 'businessMobileNumber') {
          data['mobile'] = value
        } else {
          data[item] = value;
        }
      }
    });

    dispatch(setUserProfile(data)).then(res => {
      setState(prev => ({ ...prev, loading: false }))
      Toast(res?.payload?.message)
      if (res?.payload?.success !== true) {
        setState(prev => ({ ...prev, userDetails: userDetails }))
      } else {
        getUserProfileData(false);
      }
    })
  }

  const onChangeFile = () => {
    // console.log("hello");
    const { target } = event;
    const { files } = target;
    console.log("files[0]",files);
    if(files[0].type === "image/png" || files[0].type === "image/jpeg" || files[0].type === "image/gif"){
      if (files && files[0]) {
      var reader = new FileReader();
      reader.onloadstart = () =>
        setState((prev) => ({ ...prev, loading: true, image: files[0] }));
      reader.onload = (event) => {
        setState((prev) => ({
          ...prev,
          loading: false,
          userDetails: {
            ...prev.userDetails,
            image: event.target.result
          }
        }));
      };
      reader.readAsDataURL(files[0]);
    }
  }
  else{
    alert("Please upload  valid image")
  }
  }

  return (
    <Container fluid>
      <ul className="breadcrumb ps-0 ms-0" style={styles.breadcrumb}>
        <li>
          <a href="#" style={styles.jobsTxt}>
            Profile
          </a>
        </li>
      </ul>
      <Row className="m-0 p-0" style={{ display: "flex" }}>
        <span className="profile_Tab_Txt">Profile</span>
      </Row>
      <Row className="form-containers m-0 p-0 mt-3">
        {
          state.loading ?
            <div style={{ height: '500px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Spinner animation="border" variant="primary" />
            </div> : <>
              <input type={'file'} id={'file'} style={{ display: 'none' }} accept="image/png, image/gif, image/jpeg" onChange={onChangeFile} />
              <Col md={12}>
                <label htmlFor={'file'} className="squareBox">
                  <img src={edit} className="Editing" alt="" />
                  {state.userDetails?.image
                    ? <img src={state.userDetails?.image} className="album-img" alt=""></img>
                    : <img src={album} style={{ width: '30px', heigh: '30px' }} alt=""></img>
                  }
                </label>
              </Col>
              <Row
                style={{ display: "flex", flexDirection: "row" }}
              >
                <Col sm={6}>
                  <ProfileForm
                    label_name={"Business Name"}
                    placeholder_name={"Enter Business Name"}
                    maxLength={25}
                    value={state.userDetails.businessName}
                    dataSave={e => setState(prev => ({ ...prev, userDetails: { ...prev.userDetails, businessName: e.target.value } }))}
                  />
                  <ProfileForm
                    label_name={"ABN"}
                    placeholder_name={"21-123/4567"}
                    maxLength={11}
                    value={state.userDetails.abn}
                    dataSave={e => {
                      if(isValidDigit(e.target.value)){
                         setState(prev => ({ ...prev, userDetails: { ...prev.userDetails, abn: e.target.value } }))}
                      else{
                        alert("Please enter valid abn")
                      }
                    }
                  }
                  />
                  <ProfileForm
                    label_name={"Address"}
                    placeholder_name={"Enter Address"}
                    value={state.userDetails.address}
                    maxLength={50}
                    dataSave={e => setState(prev => ({ ...prev, userDetails: { ...prev.userDetails, address: e.target.value } }))}
                  />
                  <ProfileForm
                    label_name={"Business Mobile Number"}
                    placeholder_name={"Enter Business Mobile Number"}
                    maxLength={10}
                    value={state.userDetails.businessMobileNumber}
                    dataSave={e => {
                      if(isValidDigit(e.target.value)){
                      setState(prev => ({ ...prev, userDetails: { ...prev.userDetails, businessMobileNumber: e.target.value } }))
                    }
                    else{
                      alert("Please enter valid mobile number")
                    }
                  }
                }
                  />
                </Col>
                <Col sm={6}>
                  <ProfileForm
                    label_name={"Name"}
                    placeholder_name={"Enter Name"}
                    maxLength={25}
                    value={state.userDetails.name}
                    dataSave={e => {
                      if(e.target.value === '' || isValidName(e.target.value)){
                      setState(prev => ({ ...prev, userDetails: { ...prev.userDetails, name: e.target.value } }))
                    }
                    else{
                       alert("Please enter valid name")
                    }
                  }
                }
                  />
                  <ProfileForm
                    label_name={"Business Email"}
                    placeholder_name={"Enter Business Email"}
                    value={state.userDetails.businessEmail}
                    maxLength={45}
                    dataSave={e => setState(prev => ({ ...prev, userDetails: { ...prev.userDetails, businessEmail: e.target.value } }))}
                  />
                  <ProfileForm
                    label_name={"Postcode"}
                    placeholder_name={"4215"}
                    maxLength={4}
                    value={state.userDetails.postcode}
                    dataSave={e => {
                      if(isValidDigit(e.target.value)){
                      setState(prev => ({ ...prev, userDetails: { ...prev.userDetails, postcode: e.target.value } }))
                    }
                    else{
                      alert("Please enter valid postcode")
                    }
                    }
                  }
                  />
                  <button disabled={!checkEnable()} style={{ opacity: checkEnable() ? 1 : 0.5 }} className="button" onClick={handleSave}>
                    Save
                  </button>
                </Col>
              </Row>
            </>
        }
      </Row>
      <MainToastContainer />
    </Container>
  );
};

const styles = {
  dashboardTxt: {
    fontSize: "14px",
    fontFamily: "Montserrat",
    color: "rgb(139,139,139)",
  },
  jobsTxt: {
    fontSize: "14px",
    fontFamily: "Montserrat",
    textDecoration: "underline",
    fontWeight: "500",
  },
  breadcrumb: {
    padding: 0,
  },
}

export default Profile;
