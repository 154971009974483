import React, { useState, useEffect } from "react";
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { Link, useHistory, useNavigate, useLocation } from "react-router-dom";
import uploadIcon from "../assets/img/uploadIcon.png";
import check from "../assets/img/check.png";
import "../views/AddVehicle.css";
import AddVehicleForm from "components/AddVehicleForm/AddVehicleForm";
import DropdownIcon from "components/Icon/Icon";
import { useDispatch, useSelector } from "react-redux";
import { getVehicleType } from "store/vechileType/actions";
import { addTruckType, getTruckType, editVehicle } from "store/vechileType/actions";
import { uploadImage } from "store/uploadImage/actions";
import { getDriverList } from "store/driver/actions";
import Toast, { MainToastContainer } from "../utils/Toast";
import { isValidDigit } from "utils/isValidate";

const styles = {
  dashboardTxt: {
    fontSize: "14px",
    fontFamily: "Montserrat",
    color: "rgb(139,139,139)",
  },
  jobsTxt: {
    fontSize: "14px",
    fontFamily: "Montserrat",
    textDecoration: "underline",
    fontWeight: "500",
  },
  breadcrumb: {
    padding: 0,
  },
};

const AddVehicle = () => {

  const location = useLocation();
  const [state, setState] = useState({
    fileName: location?.state?.image || "",
    truckType: location?.state?.vehicle_type_id || '',
    payloaded: location?.state?.payload || '',
    veh_id: location?.state?.id || '',
    regoNumber: location?.state?.rego || "",
    doc_number: "",
    gvm: location?.state?.gvm || "",
    assignDriver: location?.state?.vehicle_driver?.driver_id || "",
    image: ""
  });
  const dispatch = useDispatch()
  const history = useHistory()
  const truckType = useSelector(state => state?.truckTypeReducer?.truckType)
  console.log("truckType",truckType);
  const[editedDriver,setEditedDriver] = useState('')
  const driverList = useSelector(
    state => state?.driverReducer?.driverList,
  );
  const truckTypeList = useSelector(state => state?.truckTypeReducer?.truckTypeList)


  useEffect(() => {
    getTruckDriverList()
  }, [])

  const getTruckDriverList = () => {
    dispatch(getTruckType())
    if (!driverList) {
      dispatch(getDriverList())
    }
  }

  const handleAddVehicle = async (e) => {
    e.preventDefault()
    
    if(state.gvm <= 4500){

    }

    let driverImageUrl;
    if(state?.fileName && state.fileName?.name){
      var formData = new FormData();
      formData.append("image", state?.fileName, state?.fileName?.name)
      await dispatch(uploadImage(formData)).then((response) => {
        driverImageUrl = response?.payload?.data?.url
      }) 
    }

    if(state.gvm <= 4500 && state.payloaded <= 100){
      Toast("Please enter GVW and Payload more than 4500 KG and 1000KG respectively");
      return null;
    } else if(state.payloaded <= 1000){
      Toast(" Please enter Payload 1000KG or more ");
      return null;
    } 
    else if(state.gvm < state.payloaded){
      Toast("The gvm must be greater than or equal to payload")
    }
    else if(state.gvm <= 4500 ) {
      Toast("Please enter GVM 4500KG more");
      return null;
    }
    
        if(state?.truckType && state?.regoNumber && state?.gvm && state?.payloaded){

      const payload = {
        vehicle_type_id: state?.truckType,
        rego: state?.regoNumber,
        gvm: state?.gvm,
        payload: state?.payloaded,
        driver_id: state?.assignDriver
      }
      if(driverImageUrl){
        payload.image = driverImageUrl
      }

      await dispatch(addTruckType(payload)).then((response) => {
        
      if (response?.payload?.success) {
          Toast("Successfully added!")
          history.goBack()
        } 
      
        else {
          Toast(response?.payload?.message)
        }
      })
    }
      
}

// console.log("location?.state?.vehicle_driver?.vehicle_id",location?.state);



  const handleEditDriver = async (e) => {
    e.preventDefault()
    var formData = new FormData();
    let vehicleImageUrl;
    if (state?.fileName?.name) {
      formData.append("image", state?.fileName, state?.fileName?.name)
      await dispatch(uploadImage(formData)).then((response) => {
        if (response?.payload?.success) {
          vehicleImageUrl = response?.payload?.data?.url
        }
        else {
          vehicleImageUrl = ""
        }
      })
    }

    console.log("vehicleId",location);
   console.log("state?.assignDriver",state?.assignDriver);

    const payload = {
      vehicleId: location?.state?.id,
      image: vehicleImageUrl,
      rego: state.regoNumber,
      payload: state.payloaded,
      driver_id: state?.assignDriver,
      gvm: Number(state?.gvm),
      vehicle_type_id: state?.truckType
    }
    dispatch(editVehicle(payload)).then((res) => {
      console.log("res",res);
      var result = driverList.find(function(e) {
        return e.id == state?.assignDriver;
      });
      console.log("result",result);
      setEditedDriver(result)
      history.goBack()
    })
  }

  const handleCancel = () => {
    history.goBack()
  }

console.log("state?.assignDriver",state.assignDriver);
console.log("driverlist",driverList);
  return (
    <Container fluid>
      <Row>
        <Col md="12">
          <ul className="breadcrumb ps-0 ms-0" style={styles.breadcrumb}>
            <li className="p-0">
              <Link to="/" style={{ ...styles.dashboardTxt }}>Dashboard</Link>
            </li>
            <li>
              <a href="/vehicle-management" style={styles.dashboardTxt}>
                Management
              </a>
            </li>
            <li>
              <Link to="/vehicle-management" style={styles.jobsTxt}>
                Vehicle Management
              </Link>
            </li>
            <li>
              <Link to="/AddVehicle" style={styles.jobsTxt}>
                AddVehicle
              </Link>
            </li>
          </ul>
        </Col>
      </Row>
      <Row className="m-0 p-0" style={{ display: "flex" }}>
        <span className="profile_Tab_Txt">Add Vehicle</span>
      </Row>
      <Row className="m-0 p-0">
        <Col
          md={12}
          style={{ backgroundColor: "rgb(247,247,247)" }}
          className="pb-5"
        >
          <Row className="form-containers" style={{ justifyContent: "center" }}>
            <Col md={6}>
              <AddVehicleForm
                label_name={"Truck Type*"}
                placeholder_name={"choose truck type"}
                icon={true}
                onChange={e => { setState(prev => ({ ...prev, truckType: e })) }}
                options={truckType}
                // disabled={location?.state ? true : false}
                // value={state.truckType}  truck type as a type  cvechicle link
                assignTruck={location?.state?.vehicle_type?.name}
              />
              <AddVehicleForm
                label_name={"Rego Number*"}
                placeholder_name={"Enter Rego Number"}
                maxLength={15}
                onChange={e => { setState(prev => ({ ...prev, regoNumber: e.target.value })) }}
                value={state.regoNumber}
              />
              <AddVehicleForm
                label_name={"GVM (kg)*"}
                maxLength={5}
                placeholder_name={"Allowable GVM (kg)"}
                onChange={e => { 
                if(isValidDigit(e.target.value)){
                  setState(prev => ({ ...prev, gvm: e.target.value })) 
                }
                else{
                  alert("Please enter valid gvm")
                }
              }
            }
                value={state.gvm}
              />
            </Col>
            <Col md={6}>
              <AddVehicleForm
                label_name={"PayLoad (kg)*"}
                placeholder_name={"Enter Payload in kg"}
                maxLength={5}
                onChange={e => { 
                if(isValidDigit(e.target.value)){
                  setState(prev => ({ ...prev, payloaded: e.target.value })) 
                }
                else{
                  alert("Please enter valid payload")
                }
              }
            }
                value={state.payloaded}
              />
              <AddVehicleForm
                label_name={"Assign Driver"}
                placeholder_name={"Select Driver"}
                icon={true}
                Itemicon={<DropdownIcon />}
                onChange={e => { setState(prev => ({ ...prev, assignDriver: e })) }}
                options={driverList}
                value={editedDriver?.name}
                assignTruck={location?.state?.vehicle_driver?.driver?.name}
              />
              <div className="container-input">
                <div className="input-box">
                  <label className="label_name">Truck Image</label>
                  <div className="icon-box" style={{ marginTop: "10px" }}>
                    <div className="image-upload">
                      <label htmlFor="file-input1">
                        <img
                          src={state.fileName ? check : uploadIcon}
                          className="inputIcon"
                          width="30px"
                          height="25px"
                        />
                      </label>
                      <input
                        id="file-input1"
                        type="file"
                        accept="image/png, image/gif, image/jpeg"
                        onChange={(e) => {
                          console.log("eef", e.target.files);
                          if(e.target.files[0].type === "image/png" || e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/gif"){
                          setState((prev) => ({
                            ...prev,
                            fileName: e.target.files[0],
                          }));
                        }
                        else{
                          alert("please upload valid image")
                        }
                      }
                    }
                      />
                      <div>
                        <p className="inputText">{state.image.name}</p>
                      </div>
                    </div>
                  </div>
                </div>

                {location?.state ? (
                  <div style={{ marginBottom: "20px", marginTop: "20px" }}>
                    <Button className="cancelButton" onClick={handleCancel}>Cancel</Button>
                    <Button className="saveChanges" onClick={handleEditDriver}>Save Changes</Button>
                  </div>
                ) : (
                  <div style={{ marginBottom: "20px", marginTop: "20px" }} onClick={handleAddVehicle}>
                    <Button className="saveButton">Add Vehicle</Button>
                  </div>
                )}
              </div>
            </Col>
            <Row style={{ justifyContent: "flex-end", margin: '10px 2px', color: 'red' }}>
                *required
              </Row>
          </Row>
          
        </Col>
      </Row>
      <MainToastContainer />
    </Container>
  );
};

export default AddVehicle;
