import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "../views/AddDriver.css";
import "../assets/css/demo.css";
import CancelJobTable from "components/CancelJobTable/CancelJobTable";
import { cancelledJobs } from "store/driver/actions";
import { useDispatch, useSelector } from "react-redux";
import { deleteCancelledJobs } from "store/driver/actions";
import Toast, { MainToastContainer } from "utils/Toast";


const styles = {
  dashboardTxt: {
    fontSize: "14px",
    fontFamily: "Montserrat",
    color: "rgb(139,139,139)",
  },
  jobsTxt: {
    fontSize: "14px",
    fontFamily: "Montserrat",
    textDecoration: "underline",
    fontWeight: "500",
  },
  breadcrumb: {
    padding: 0,
  },
  searchJobConatiner: {
    flexDirection: "row",
    justifyContent: "space-between",
    display: "flex",
    paddingTop: "10px",
    backgroundColor: "rgb(255,255,255)",
  },
  searchJobScreen: {
    width: "300px",
    height: "28px",
    backgroundColor: "white",
    border: "1px solid rgb(223,223,223)",
  },
  searchIcon: {
    paddingLeft: "3px",
  },

  searchJobInput: {
    // paddingLeft: '10px',
    border: "0px solid",
    outline: "none",
    height: "17px",
    fontSize: "14px",
    fontFamily: "Montserrat",
    color: "rgb(223,223,223)",
  },
  trHeading: {
    backgroundColor: "rgb(109,109,109)",
    // fontSize: "15px",
    fontFamily: "Montserrat",
    fontWeight: "600",
    color: "white",
    borderRadius: "30px",
    marginTop: "10px",
    // width: '800px',
  },
  textHeading: {
    fontSize: "12px",
    fontFamily: "Montserrat",
    fontWeight: "700",
    color: "white",
    textTransform: "none",
  },
};


const CancelledJobs = () => {
  const [state, setState] = useState({
    fileName: "",
  });

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(cancelledJobs()).then((response) => {
      // console.log("response",response);
      // toggleLoader();
    })
  }, [])

  const List = useSelector(state => state?.driverReducer?.cancelledJobsList)
  

  const handleDelete = (item) => {
    let data = {
      id: item?.id
    }
    dispatch(deleteCancelledJobs(data)).then((response) => {
      console.log("response",response);
      if (response?.payload?.success) {
        dispatch(cancelledJobs())
        Toast(response?.payload?.message)
        // setIsLoading(false)
      }
    })

  }

  return (
    <Container fluid>
      <Row>
        <Col md="12">
          <ul className="breadcrumb ps-0 ms-0" style={styles.breadcrumb}>
          <li className="p-0">
              <Link to="/" style={{ ...styles.dashboardTxt }}>Dashboard</Link>
            </li>
            <li>
              <a href="/my-loads" style={styles.dashboardTxt}>
                My Loads
              </a>
            </li>
            <li>
              <a href="/CancelledJobs" style={styles.jobsTxt}>
                Cancelled Jobs
              </a>
            </li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col xs={6} md={4}>
          <div style={{ width: '200px' }}>
            <span className="profile_Tab_Txt">Cancelled Jobs</span>
          </div>
        </Col>
        <Col xs={12} md={8} >
          {/* <p>hii</p> */}
          <div className="d-flex justify-content-between">
          </div>
        </Col>
      </Row>

      <Row className="m-0 mb-0 p-0" style={{ display: "flex" }}>
        <span className="resultTxt">{List.length} Result</span>
      </Row>

      <Row sm={6} xl={12} className="mt-3 pe-2">
        <Col md={12} className="ms-2 ps-3">
          <div className="table-responsive-sm pe-3 me-2">
            <table className="table border-none p-0 m-0">
              <thead style={{ borderRadius: "10px" }}>
                <tr style={{ ...styles.trHeading, borderRadius: "20px" }}>
                  <th style={styles.textHeading}>OrderId</th>
                  <th style={styles.textHeading}>Material</th>
                  <th style={styles.textHeading}>Loading</th>
                  <th style={styles.textHeading}>Distance</th>
                  <th style={styles.textHeading}>Unloading</th>
                  <th style={styles.textHeading}>Delivery Date</th>
                  <th style={styles.textHeading}>Status</th>
                  <th
                    style={{
                      ...styles.textHeading,
                      color: "rgb(109,109,109)",
                    }}
                  >
                    {"heading"}
                  </th>
                </tr>
              </thead>
              <CancelJobTable 
              backgroundColor={"rgb(0,126,229)"} 
              title={"Assign Me"} 
              fontSize={"10px"} 
              handleDelete={(item) => handleDelete(item)}/>
            </table>
          </div>
        </Col>
      </Row>
      <MainToastContainer/>
    </Container>
  )
}
export default CancelledJobs;