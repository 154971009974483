import React from 'react'
import driverIcon from '../../assets/img/driverIcon.png';

const DropdownIcon = ({src}) => {
  return (
    <>
    <img src={driverIcon} alt="" style={{width:29, height:29}} />
    </>
  )
}

export default DropdownIcon;