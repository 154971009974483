import { createSlice } from '@reduxjs/toolkit';
import { dashboardCount, orderList } from './actions';

const slice = createSlice({
    name: 'dashboard',
    initialState: {
        dashboardCounts: null,
        ordersLists: []
    },
    extraReducers: builder => {
        builder.addCase(dashboardCount.fulfilled, (state, action) => {
            if (action?.payload?.success) {
                console.log(" action?.payload?.data", action?.payload?.data);
                state.dashboardCounts = action?.payload?.data
            }
        });
        builder.addCase(orderList.fulfilled, (state, action) => {
            if (action?.payload?.success) {
                console.log(action.payload.data)
                state.ordersLists = action?.payload?.data
            }
        });
    }
});
export default slice.reducer;
