import React from "react";
import Table from "react-bootstrap/Table";
import weight from "../../assets/img/weight.png";
import arrow from "../../assets/img/arrowImg.png";
import phone from "../../assets/img/phone.png";
import date from "../../assets/img/date.png";
import timing from "../../assets/img/timing.png";
// import '../views/jobs/jobs.css'
import { useHistory, Link } from 'react-router-dom';
import { MdDirtyLens } from "react-icons/md";


const styles = {
  trHeading: {
    backgroundColor: "rgb(109,109,109)",
    // fontSize: "15px",
    fontFamily: "Montserrat",
    fontWeight: "600",
    color: "white",
    borderRadius: "30px",
    marginTop: "10px",
    // width: '800px',
  },
  textHeading: {
    fontSize: "12px",
    fontFamily: "Montserrat",
    fontWeight: "700",
    color: "white",
    textTransform: "none",
  },
  tableRowShaddow: {
    boxShadow: "1px 1px 1px 1px rgba(0, 126, 229, 0.2)",
    // backgroundColor: "rgb(255,255,255)",
    borderRadius: "10px",
    marginBottom: "1em",
  },
  tdView: {
    fontSize: "12px",
    fontFamily: "Montserrat",
    // fontWeight: "600",
    color: "rgb(23,32,63)",
    // display: 'flex',
    flexDirection: "row",
    // marginTop: 7,
    // marginBottom: 7
  },
  weightStyle: {
    width: "13px",
    height: "13px",
  },
  statusViewContainer: {
    width: "50px",
    height: "7px",
    backgroundColor: "rgb(191, 190, 190)",
    // marginTop: "7px",
  },
  buttonStyle: {
    width: "100px",
    height: "40px",
    // backgroundColor: "rgb(0, 126, 229)",
    marginTop: "7px",
    borderRadius: "7px",
    border: "none",
    color: "white",
    fontSize: "11px",
    fontWeight: "700",
    // marginLeft: "25px",
  },
  table: {
    border: "1px solid #CCC",
    borderCollapse: "collapse",
  },
};

const CustomTable = ({
  heading,
  handleShow,
  backgroundColor,
  title,
  fontSize,
  onClick,
  track,
  handleClick,
  anotherBackgroundColor,
anotherFontSize,
anotherHandleShow,
anotherTitle,
antherFontColor,
onPressAssignMe,
onPressSeeDetails,
  ...props
}) => {

  const ButtonSection = () => {
    if(!props.seeDetails && !props.assignMe){
      return null;
    }
    return(
      <td>
        {props.assignMe && <button
           style={{
             ...styles.buttonStyle,
             backgroundColor: "rgb(0,126,229)",
             fontSize: '10px',
           }}
           onClick={onPressAssignMe}
         >
           {'Assign Me'}
         </button>}
        {props?.seeDetails && <button
           style={{
             ...styles.buttonStyle,
             backgroundColor: "rgba(109,109,109, 0.5)",
             fontSize: '10px',
             color: 'rgb(255,255,255)',
           }}
           onClick={onPressSeeDetails}
         >
           {'View Details'}
         </button>}
      </td>
    );
  }


  return (
      // <tbody className="p-0 mt-3">
        <tr style={styles.tableRowShaddow} className="pt-3">
          <td style={{ ...styles.tdView }}>{`#${props.orderId}`}</td>
          <td>
            <div style={{ width: "90px", display: "flex", padding: 0 }}>
              <span style={styles.tdView}>{props.materialName}</span>
            </div>
            <div style={{...styles.tdView, width: '90px'}}>
              <img
                src={weight}
                alt=""
                style={{ ...styles.weightStyle, ...styles.tdView }}
              />
              <span style={{ ...styles.tdView, paddingTop: '7px' }}>{` ${props.quantity}`}</span>
            </div>
          </td>
          <td>
            <div style={{ flexDirection: "column", display: "flex" }}>
              <div style={{ border: "none", width: "160px" }}>
                <img src={arrow} alt="" style={styles.weightStyle} />
                <span style={styles.tdView}>{` ${props.loaderName}`}</span>
              </div>
            </div>
            <div style={{ ...styles.tdView, border: "none" }}>
              <img src={phone} alt="" style={styles.weightStyle} />
              {` ${props.loaderNumber}`}</div>
          </td>
          <td>
            <span style={{ ...styles.tdView }}>{props.distance}</span>
          </td>
          <td>
            <div style={{ flexDirection: "column" }}>
              <div style={{ ...styles.tdView, border: "none" }}>
                <img src={arrow} alt="" style={styles.weightStyle} />
                <span style={{ ...styles.tdView }}>{` ${props.unloaderName}`}</span>
              </div>
              <div style={{ ...styles.tdView, border: "none", width: "120px" }}>
                <img src={phone} alt="" style={styles.weightStyle} />
                <span style={{ ...styles.tdView }}>{` ${props.unloaderNumber}`}</span>
              </div>
            </div>
          </td>
          <td>
            <div style={{ flexDirection: "column" }}>
              <div
                style={{ ...styles.tdView, border: "none", width: "119.55px" }}
              >
                <img src={date} alt="" style={styles.weightStyle} />
                <span style={{ ...styles.tdView }}>{` ${props.deliveryDate}`}</span>
              </div>
            </div>
            <div style={{ ...styles.tdView, border: "none" }}>
              <img src={timing} alt="" style={styles.weightStyle} />
              <span style={styles.tdView}>{
                props.deliveryTime === props.deliveryLastTime ? ` ${props.deliveryTime}` : ` b/w ${props.deliveryTime} - ${props.deliveryLastTime}`
              }</span>
            </div>
          </td>
          {props?.amount && <td>
            <div style={{ ...styles.tdView, border: "none" }}>
              <span style={styles.tdView}>{`$ ${props?.amount}`}</span>
            </div>
          </td>}
          {/* {props?.status && <td>
            <div style={{ flexDirection: "row", display: "flex", paddingTop: '6px' }}>
              <div
                style={{
                  ...styles.statusViewContainer,
                  backgroundColor: "rgb(255,111,26)",
                }}
              />
              <div
                style={{ ...styles.statusViewContainer, marginLeft: "5px" }}
              />
              <div
                style={{ ...styles.statusViewContainer, marginLeft: "5px" }}
              />
            </div>
            <div style={{...styles.tdView, paddingTop: '8.5px'}}>
              <span style={styles.tdView}>{props?.startedTime}</span>
            </div>
           {props?.status ? <div style={{...styles.tdView, paddingTop: '8.5px'}}>
              <span style={{...styles.tdView, color: "rgb(255,111,26)"}}>{` ${props.status}`}</span>
            </div> : null}
          </td>} */}
         <ButtonSection />
        </tr>
  );
};

export default CustomTable;
