import React from 'react';

const BankDetailForm = (props) => {
  const { label, input_text , handleChange , value , paddingTop, maxLength } = props;
  const styles ={paddingTop:paddingTop}
  return (
    <>
      <div style={{display:'flex'}} className='mb-3 mt-3'>
      <input type="text" className="input_name" placeholder={input_text} onChange={handleChange} value={value} maxLength={maxLength}/>
        <span className="labelTxt" id="basic-addon3" style={{...styles}}>{label}</span>

      </div>
    </>
  )
}

export default BankDetailForm