export const AUTHENTICATE_USER = 'AUTHENTICATE_USER';
export const DRIVER_LIST = 'DRIVER_LIST';
export const TRUCK_LIST = 'TRUCK_LIST';
export const ADD_DRIVER = 'ADD_DRIVER';
export const ADD_TRUCK_TYPE = 'ADD_TRUCK_TYPE';
export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const EDIT_DRIVER = 'EDIT_DRIVER';
export const TRUCK_TYPE = 'TRUCK_TYPE';
export const GET_JOB_LIST = 'GET_JOB_LIST';
export const DELETE_DRIVER = 'DELETE_DRIVER';
export const ASSIGN_ORDER_TO_ME = 'ASSIGN_ORDER_TO_ME';
export const EDIT_VEHICLE = 'EDIT_VEHICLE';
export const DELETE_VEHICLE = 'DELETE_VEHICLE';
export const GET_LIST_OF_LOADINGS = 'GET_LIST_OF_LOADINGS';
export const GET_COMPLETED_LIST_OF_LOADINGS = 'GET_COMPLETED_LIST_OF_LOADINGS';
export const GET_INVOICE_LIST = 'GET_INVOICE_LIST';
export const GET_BANK_DETAILS = 'GET_BANK_DETAILS';
export const ADD_BANK_DETAILS = 'ADD_BANK_DETAILS';
export const DASHBOARD_COUNT = 'DASHBOARD_COUNT';
export const ORDERS_LIST = 'ORDERS_LIST';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const GET_NOTIFICATION_LIST = 'GET_NOTIFICATION_LIST';
export const FIRE_MARK_ALL_AS_READ = 'FIRE_MARK_ALL_AS_READ';
export const GET_PAGES_API = 'GET_PAGES_API';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const GET_ASSSUMPTION = 'GET_ASSSUMPTION';
export const CANCELLED_JOBS = 'CANCELLED_JOBS';
export const DELETE_CANCELLED_JOBS = 'DELETE_CANCELLED_JOBS'
export const SEND_DEVICE_TOKEN_API = 'SEND_DEVICE_TOKEN_API'
export const ADD_VEHICLE_IN_TRANSPORT = 'ADD_VEHICLE_IN_TRANSPORT'
export const GET_NOTIFICATION_STATUS = 'GET_NOTIFICATION_STATUS'
export const UPDATE_NOTIFICATION_STATUS = 'UPDATE_NOTIFICATION_STATUS'
