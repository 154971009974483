import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Table } from "react-bootstrap";
import TableOrder from "./components/orderTable";
import "../orderHistory/orderHistory.css";
import { useDispatch, useSelector } from "react-redux";
import { orderList } from "store/dashboard/actions";
import moment from 'moment'
import { Link, useHistory } from "react-router-dom";


const OrderHistory = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [viewShipment,setViewShipment] = useState({})


  useEffect(() => {
    dispatch(orderList())
  }, [])

  const orders = useSelector(state => state?.dashboardReducer?.ordersLists)
  console.log("orders99999", orders);

  return (
    <Container fluid>
      <ul className="breadcrumb ps-0 ms-0" style={styles.breadcrumb}>
      <li className="p-0">
              <Link to="/" style={{ ...styles.dashboardTxt }}>Dashboard</Link>
            </li>
        <li>
          <a href="/order-history" style={styles.dashboardTxt}>
            Management
          </a>
        </li>
        <li>
          <a href="/order-history" style={styles.jobsTxt}>
            Order History & Earnings
          </a>
        </li>
      </ul>
      <Row>
        <Col md={12}>
          <Table style={{borderBottom: '12px', borderBottomColor: 'red'}}>
            <tbody>
              {orders.map((obj) => {
                const Date = moment(obj?.schedule?.date).format("DD-MM-YYYY")
                const price = Math.round(obj?.total_price*Math.pow(10,2))/Math.pow(10,2);
                return (
                  <TableOrder
                    mainObj={obj}
                    orderId={`#${obj?.order_id}`}
                    material={obj?.supplier_material?.material?.name}
                    status={"Payment Status"}
                    type={obj?.invoice?.status ? obj?.invoice?.status : 'unpaid'}
                    // totalamount={obj?.}
                    currency={`$` + price}
                    date={Date}
                    orderdetail={"view order details"}
                    color={obj?.invoice?.status === 'paid' ? "rgb(0 197 105)" : "rgb(255,0,26)"}
                    quantity={obj?.quantity+obj?.unit}
                    shipment={obj?.shipments}
                    // onViewDetails={() => history.push("/orderHistoryDetails", { state: { item: obj, shipment: viewShipment } })}
                    handleDetail={(item) => history.push("/orderHistoryDetails", { state: { item: obj, shipment: item } })}
                  ></TableOrder>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

const styles = {
  trHeading: {
    backgroundColor: "rgb(109,109,109)",
    fontFamily: "Montserrat",
    fontWeight: "600",
    color: "white",
    borderRadius: "30px",
    marginTop: "10px",
  },
  textHeading: {
    fontSize: "12px",
    fontFamily: "Montserrat",
    fontWeight: "700",
    color: "white",
    textTransform: "none",
  },
  dashboardTxt: {
    fontSize: "14px",
    fontFamily: "Montserrat",
    color: "rgb(139,139,139)",
  },
  jobsTxt: {
    fontSize: "14px",
    fontFamily: "Montserrat",
    textDecoration: "underline",
    fontWeight: "500",
  },
  breadcrumb: {
    padding: 0,
  },
  searchJobConatiner: {
    flexDirection: "row",
    justifyContent: "space-between",
    display: "flex",
    paddingTop: "10px",
    backgroundColor: "rgb(255,255,255)",
  },
  searchJobScreen: {
    width: "300px",
    height: "28px",
    backgroundColor: "white",
    border: "1px solid rgb(223,223,223)",
  },
  searchIcon: {
    paddingLeft: "3px",
  },

  searchJobInput: {
    border: "0px solid",
    outline: "none",
    height: "17px",
    fontSize: "14px",
    fontFamily: "Montserrat",
    color: "rgb(223,223,223)",
  },
  dropdownStyle: {
    width: "30px",
    height: "30px",
    justifyContent: "flex-end",
  },
  modalTextStyle: {
    color: "rgb(109, 109, 109)",
    fontSize: "13px",
    fontWeight: "500",
    opacity: "60",
    fontFamily: "Montserrat",
  },
  backBtnStyle: {
    color: "rgb(0, 118, 255)",
    border: "1px solid rgb(223, 223, 223)",
    fontSize: "16px",
    fontWeight: "600",
    fontFamily: "Montserrat",
  },
  myModal: {
    width: "100px",
  },
  modalHeader: {
    borderBottom: "none",
  },
  modalFooter: {
    borderTop: "none",
  },
};

export default OrderHistory;
