import {
	LineChart,
	ResponsiveContainer,
	Legend, Tooltip,
	Line,
	XAxis,
	YAxis,
	CartesianGrid
} from 'recharts';
// import '../views/dashboard/dashboard.css';

// Sample chart data

const styles = {
	heading: {
		fontSize: '16px',
		fontFamily: "Montserrat",
		fontWeight: '500',
		color: 'black',
		marginTop: '10px',
		paddingLeft: '10px'
	}
}

const pdata = [
	{
		name: '10 Jun',
		Last: 11,
		Month: 120
	},
	{
		name: '11 Jun',
		Last: 15,
		Month: 12
	},
	{
		name: '12 Jun',
		Last: 5,
		Month: 10
	},
	{
		name: '13 Jun',
		Last: 10,
		Month: 5
	},
	{
		name: '14 Jun',
		Last: 9,
		Month: 4
	},
	{
		name: '15 Jun',
		Last: 10,
		Month: 8
	},
];



function Chart() {
	return (
		<>
			<span style={styles.heading}>Income Generated</span>
			<ResponsiveContainer aspect={3} width="95%" height="50%">
				<LineChart data={pdata} marginBottom={90} margin={{}}>
					<CartesianGrid vertical={false} />
					<XAxis dataKey="name" axisLine={false} label={false}
						name="This Month" style={{ color: 'rgb(139,139,139)', fontSize: 12, fontFamily: 'Montserrat' }} />
					<YAxis width={30} axisLine={false} style={{ color: 'rgb(139,139,139)', fontSize: 12, fontFamily: 'Montserrat' }} />
					{/* <Tooltip /> */}
					<Legend align="right" fontStyle={{ color: 'gray' }} verticalAlign="top" iconType="square" stroke='rgb(139,139,139)' wrapperStyle={{ position: 'absolute', top: 0, left: 37, fontFamily: 'Montserrat, sans-serif', fontSize: 15 }} />
					<Line dataKey="Last" name="Last Month" type="monotone"
						stroke="rgb(255,111,26)" activeDot={{ r: 8 }} fill="rgb(255,111,26)" />
					<Line dataKey="Month" name="This Month" type="monotone"
						stroke="rgb(22,82,240)" activeDot={{ r: 8 }} fill="rgb(22,82,240)" />
				</LineChart>
			</ResponsiveContainer>
		</>

	);
}

export default Chart;
