import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// import { firebase } from ".";

const firebaseConfig = {
    apiKey: "AIzaSyBW6z7oMD5zWc_Ag9qn6yZP9cwyViSylr4",
    authDomain: "rubbl-mobile.firebaseapp.com",
    projectId: "rubbl-mobile",
    storageBucket: "rubbl-mobile.appspot.com",
    messagingSenderId: "654447465028",
    appId: "1:654447465028:web:96c72ab65e1e02fe2ce211",
    measurementId: "G-47KX8SLXPZ"
};

const app = firebase.app.length && initializeApp(firebaseConfig)
// const app = initializeApp(firebaseConfig)
getAnalytics(app);

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });

export default app;

