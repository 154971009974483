import React, { useEffect, useState } from "react";
import { Col, Nav, Tab, Row, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import moment  from "moment";
import { IoIosSearch } from "react-icons/io";
import { Link } from "react-router-dom";
import { getListOfLoadings, getCompletedListOfLoadings  } from "store/driver/actions";
import AssignedList from "../TopTabBar/components/AssignedList";
import CompletedList from "../TopTabBar/components/CompletedList";
import canceljob from "../../assets/img/cancelJob.png";
import DropDownToggle from "../DropDownToggle/DropDownToggle";
import "../TopTabBar/TopTabBar.css";
import { useHistory } from "react-router-dom";


const getFixedDate = (start, end) => {
  let startDate = moment(start).format("D-M-Y");
  let endDate = moment(end).format("D-M-Y");

  if (startDate === endDate) {
    return `${startDate}`;
  } else {
    return `${startDate} to ${endDate}`;
  }
};

const getFixedTime = (start, end) => {
  let startTime = moment(start).format("Ha");
  let endTime = moment(end).format("Ha");

  if (startTime === endTime) {
    return `${startTime}`;
  } else {
    return `b/w ${startTime} - ${endTime}`;
  }
};

const TableAssignedList = () => {
  const dispatch = useDispatch();
  const assignLoadingData = useSelector((state) => state.driverReducer.assignLoadingList);
  const [state, setState] = useState({
    loading: false,
  });

  useEffect(() => {
    toggleLoading();
    getAssignedMyList();
  }, [])

  const getAssignedMyList = () => {
    dispatch(getListOfLoadings()).then(res => {
      toggleLoading();
    })
  }

  const toggleLoading = () => {
    setState(prev => ({...prev, loading: !prev.loading}))
  };

  
  const DataSheet = () => {

    if (state.loading) {
      return (
        <div
          style={styles.spinnerContainer}
        >
          <Spinner animation="border" variant="primary" />
        </div>
      );
    }
    const history = useHistory();


    const handle = (item) => {
      history.push("/track",{ state: { item: item } });
    };

    return(
      <div className="table-responsive-sm pe-3 me-2">
        <table className="table border-none p-0 m-0">
          <thead style={{ borderRadius: "10px" }}>
            <tr style={{ ...styles.trHeading, borderRadius: "20px" }}>
              <th style={styles.textHeading}>OrderId</th>
              <th style={styles.textHeading}>Material</th>
              <th style={styles.textHeading}>Loading</th>
              <th style={styles.textHeading}>Distance</th>
              <th style={styles.textHeading}>Unloading</th>
              <th style={styles.textHeading}>Delivery Date</th>
              <th
                style={{
                  ...styles.textHeading,
                  color: "rgb(109,109,109)",
                }}
              >
                {"head"}
              </th>
            </tr>
          </thead>
          {
            assignLoadingData.map(item => {
              let time = moment(item?.shipments[0]?.date).format("h:mm a")
              let time1 = moment(item?.shipments[item?.shipments?.length - 1]?.date).format("h:mm a")
               
              return <AssignedList
              orderId={item?.order_id}
              materialName={item?.supplier_material?.material?.name}
              quantity={`${item?.quantity}${item?.unit}`}
              distance={`${item?.distance || 0}${item?.d_unit}`}
              loaderName={item?.supplier_material?.supplier?.name}
              loaderNumber={
                item?.supplier_material?.supplier?.user?.address
              }
              unloaderName={item?.site?.name}
              unloaderNumber={item?.site?.address}
              deliveryDate={getFixedDate(
                item?.shipments?.[0]?.date,
                item?.shipments?.[item?.shipments?.length - 1]?.date
              )}
              deliveryTime={time}
              deliveryLastTime={time1}              
              startedTime={moment(item?.created_at).fromNow()}
              status={item?.status}
              seeDetails={true}
              

            AssignedTxtStatus={"Assigned"}
            backgroundColor={"rgb(223,223,223)"}
            handleClick={() => handle(item)}
          />
            })
          }
        </table>
      </div>
    );
  }

  return (
    <>
      <Row className="m-0 p-0" style={{ display: "flex" }}>
        <span className="resultTxt">{assignLoadingData.length ? assignLoadingData.length : 0} Results</span>
      </Row>
      <DataSheet />
    </>
  );
};

const TableCompletedList = () => {
  const dispatch = useDispatch();
  const completedLoadingData = useSelector((state) => state.driverReducer.completedLoadingList);
  const [state, setState] = useState({
    loading: false
  })

  const history = useHistory();


  const handle = (item) => {
    history.push("/track",{ state: { item: item } });
  };

  useEffect(() => {
    toggleLoading()
    getCompletedMyList();
  }, [])

  const getCompletedMyList = () => {
    dispatch(getCompletedListOfLoadings()).then(res => toggleLoading())
  }
  
  const toggleLoading = () => {
    setState(prev => ({...prev, loading: !prev.loading}))
  };

  const DataSheet = () => {

    if (state.loading) {
      return (
        <div
          style={styles.spinnerContainer}
        >
          <Spinner animation="border" variant="primary" />
        </div>
      );
    }

    return(
      <div className="table-responsive-sm pe-3 me-2">
        <table className="table border-none p-0 m-0" style={{
              'tableLayout': 'fixed',
              'width': '100%'
            }}>
          <thead style={{ borderRadius: "10px" }}>
            <tr style={{ ...styles.trHeading, borderRadius: "20px" }}>
              <th style={styles.textHeading}>OrderId</th>
              <th style={styles.textHeading}>Material</th>
              <th style={styles.textHeading}>Loading</th>
              <th style={styles.textHeading}>Distance</th>
              <th style={styles.textHeading}>Unloading</th>
              <th style={styles.textHeading}>Delivery Date</th>
              <th
                style={{
                  ...styles.textHeading,
                  color: "rgb(109,109,109)",
                }}
              >
                {"head"}
              </th>
            </tr>
          </thead>
          {completedLoadingData.map(item => {
              let time = moment(item?.shipments[0]?.date).format("h:mm a")
              let time1 = moment(item?.shipments[item?.shipments?.length - 1]?.date).format("h:mm a")

            return <CompletedList
             orderId={item?.order_id}
             materialName={item?.supplier_material?.material?.name}
             quantity={`${item?.quantity}${item?.unit}`}
             distance={`${item?.distance || 0}${item?.d_unit}`}
             loaderName={item?.supplier_material?.supplier?.name}
             completed={true}
             loaderNumber={
               item?.supplier_material?.supplier?.user?.mobile
             }
             unloaderName={item?.site?.name}
              unloaderNumber={item?.site?.address}
             deliveryDate={getFixedDate(
               item?.shipments?.[0]?.date,
               item?.shipments?.[item?.shipments?.length - 1]?.date
             )}
             deliveryTime={time}
             deliveryLastTime={time1}
             startedTime={moment(item?.created_at).fromNow()}
             seeDetails={true}
             handleClick={() => handle(item)}
          />
          })}
        </table>
      </div>
    );
  }

  return (
    <>
      <Row className="m-0 p-0" style={{ display: "flex" }}>
        <span className="resultTxt">{completedLoadingData.length ? completedLoadingData.length : 0} Result</span>
      </Row>
      <DataSheet />
    </>
  );
};

const TopTabBar = () => {
  const [activeLink, setActiveLink] = useState("assigned");

  return (
    <div style={{ backgroundColor: "rgb(247,247,247)" }}>
      <div>
        <Tab.Container defaultActiveKey="assigned" activeKey={activeLink}>
          <Row>
            <Col md={12} className="d-flex justify-content-between pt-1">
              <Nav
                onSelect={(selectedKey) => {
                  setActiveLink(selectedKey);
                }}
              >
                <Nav.Item>
                  <Nav.Link eventKey="assigned" className="assignedTableTxt">
                    Assigned
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="completed" className="assignedTableTxt">
                    Completed
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <div
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <Link to={"/CancelledJobs"}>
                    <span className="cancelJobTxt">Cancelled Jobs</span>
                    <img src={canceljob} alt="" className="canceljobStyle" />
                  </Link>
                </div>
            </Col>
          </Row>
          <Tab.Content>
            <Tab.Pane eventKey="assigned">
              <TableAssignedList />
            </Tab.Pane>
            <Tab.Pane eventKey="completed">
              <TableCompletedList />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  );
};

const styles = {
  trHeading: {
    backgroundColor: "rgb(109,109,109)",
    fontFamily: "Montserrat",
    fontWeight: "600",
    color: "white",
    borderRadius: "30px",
    marginTop: "10px",
  },
  textHeading: {
    width :'10%',
    fontSize: "12px",
    fontFamily: "Montserrat",
    fontWeight: "700",
    color: "white",
    textTransform: "none",
  },
  searchJobScreen: {
    width: "300px",
    height: "28px",
    backgroundColor: "white",
    border: "1px solid rgb(223,223,223)",
  },
  searchIcon: {
    paddingLeft: "3px",
  },
  searchJobInput: {
    border: "0px solid",
    outline: "none",
    height: "17px",
    fontSize: "14px",
    fontFamily: "Montserrat",
    color: "rgb(223,223,223)",
  },
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  }
};

export default TopTabBar;
