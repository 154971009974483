import axios from 'axios'
const { REACT_APP_API_URL } = process.env;

export const dashboardCountApi = async () => {
    return axios.get(`${REACT_APP_API_URL}dashboard/data-count`)
};

export const ordersListApi = async () => {
    return axios.get(`${REACT_APP_API_URL}orders/transporter/loadings?status=delivered`)
};
