import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { IoMdArrowDropdown } from 'react-icons/io'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import "../../views/AddVehicle.css"
import { useDispatch, useSelector } from "react-redux";


const AddVehicleForm = (props) => {
  const { assignTruck, label_name, placeholder_name, value, icon, Itemicon, enable, onChange, options, maxLength, disabled } = props;
  const [state, setState] = useState({
    list: assignTruck || ''
  })
  const truckTypeList = useSelector(state => state?.truckTypeReducer?.truckTypeList)

  //   return(
  //     { value: i?.id, label: i?.vehicle_type?.name }
  //   )
  // })

  // const options1 = driverList.map((i) => {
  //   return(
  //     { value: i?.id, label: i?.name }
  //   )
  // })
  return (
    <>
      <div className='box_container w-100%'>
        <div>
          <label className="label_name">{label_name} </label>
          {icon ?
            <Dropdown style={{width:'100%'}}>
              <Dropdown.Toggle disabled={disabled} variant='No style' id="dropdown-basic" style={{ width: "100%", border: '0.5px solid gray', textAlign: 'left', height: '42px' }}>
                {state?.list || placeholder_name}
                <IoMdArrowDropdown size={'30px'} />
              </Dropdown.Toggle>
              {/* <Dropdown.Menu  
              // onClick={(e) => {
              //   setState((prev) => ({ ...prev, list: e.target.text }))
              // }}
              >
                {truckTypeList.map((todo, index) => {
            <Dropdown.Item value={todo.id}>{todo.name}</Dropdown.Item>
          })} */}
              {/* {enable ? truckTypeList.map(({ label, value }, index) => {
                return(
               <Dropdown.Item className="dropdown-item"> {Itemicon} &nbsp;&nbsp; {label}
                  <Dropdown.Divider />
                </Dropdown.Item>)}) : 
                driverList.map(({ label, value }, index) => <Dropdown.Item className="dropdown-item"> {Itemicon} &nbsp;&nbsp; {label}
                  <Dropdown.Divider />
                </Dropdown.Item>)
                } */}
              {/* </Dropdown.Menu> */}
              <Dropdown.Menu style={{ width: '100%',  maxHeight: '255px',
    overflowY: 'auto'}} onClick={
                (e) => {
                  setState((prev) => ({ ...prev, list: e.target.text }))
                  onChange(Number(e.target.id))
                }}>
                {options !== null ? options.map((value, index) => <Dropdown.Item id={value?.id} className="dropdown-item" key={index.toString()}>
                  <img src={value?.icon} alt="" style={{ width: 29, height: 20, marginRight: 10 }} />
                  {value?.name}

                  {
                    value?.axles === 2 ? ` ( ${value?.axles} axles and up to ${value?.name == "15t Tipper" || value?.name == "7.5t Tipper" ? ((value?.gvm * 1000) + ((value?.gvm * 1000) * 20) / 100+` kg`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "12,950 kg"
                      } ) `
                      : value?.axles ? ` ( ${value?.axles} axles )` : null
                  }
                  <Dropdown.Divider />
                </Dropdown.Item>)
                  : null
                }
              </Dropdown.Menu>
            </Dropdown>
            :
            <div>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control placeholder={placeholder_name} onChange={onChange} value={value || ''} maxLength={maxLength}/>
                </Form.Group>
              </Form>
            </div>
          }
        </div>
      </div>
    </>
  )
}

export default AddVehicleForm