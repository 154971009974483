import React from 'react'
import weight from '../../../assets/img/weight.png';
import "../../../assets/css/demo.css"


const VehicleModal = (props) => {

    const { driverDetail, truckType, materialQty, regoNumber, assignedNumber, typeofTruck, payloadType, numberOfRego, assignedDriver } = props;
    const { name } = driverDetail?.vehicle_type
    const { rego, payload } = driverDetail
    const assignDriver = driverDetail?.vehicle_driver?.driver?.name


    return (
        <>
            <div style={{ flexDirection: 'column', display: 'flex',  }}>

                <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                    <span className='typeofTruck'>{typeofTruck}</span>
                    <span className="modal_text">{name}</span>
                </div>


                <div style={{ flexDirection: 'row', display: 'flex',   justifyContent: 'space-between', }}>
                    <span className='typeofTruck'>{payloadType}</span>
                    <div style={{ flexDirection: 'row', marginTop: '5px' }}>

                        <img src={weight} alt='' className="weightStyle" />
                        <span className="modal_text">{payload}</span>

                    </div>
                </div>
                <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between'}}>
                    <span className='typeofTruck'>{numberOfRego}</span>
                    <span className='modal_text'>{rego}</span>
                </div>
                <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                    <span className='typeofTruck' style={{ width: '100px' }}>{assignedDriver}</span>
                    <div style={{ display: 'flex' }}>
                        <span className='modal_text'>{assignDriver}</span>
                    </div>
                </div>

            </div>
        </>
    )
}

export default VehicleModal







