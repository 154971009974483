import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./HelpSupport.css";
import { Container } from "react-bootstrap";
import Logo from "../assets/img/Logo.png";
import Whatsapp from "../assets/img/whatsapp.png";
import phone from "../assets/img/phone.png";
import man from "../assets/img/man.png";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

const styles = {
  dashboardTxt: {
    fontSize: "14px",
    fontFamily: "Montserrat",
    color: "rgb(139,139,139)",
  },
  jobsTxt: {
    fontSize: "14px",
    fontFamily: "Montserrat",
    textDecoration: "underline",
    fontWeight: "500",
  },
  breadcrumb: {
    padding: 0,
  },
};

const HelpSupport = () => {
  const handleOpenWhatsapp = () => {
    const win = window.open('https://wa.me/610478094782', "_blank");
    win.focus();
  }

  return (
    <Container fluid>
      <ul className="breadcrumb ps-0 ms-0" style={styles.breadcrumb}>
      <li className="p-0">
              <Link to="/profile" style={{ ...styles.dashboardTxt }}>Profile</Link>
            </li>
        <li>
          <a href="/HelpSupport" style={styles.jobsTxt}>
            Help & Support{" "}
          </a>
        </li>
      </ul>
      <Row className="m-0 p-0" style={{ display: "flex" }}>
        <span className="profile_Tab_Txt">Help & Support</span>
      </Row>
      <Row className="help-container">
        <Col md={12}>
          {/* <img src={Logo} className='logo_img' alt=""></img> */}
          <div className="chat-container mt-4 pt-5">
            <img src={man} alt="" height={'67px'} width={'67px'}></img>
            <div className="triangle-left"></div>
            <p className="chat-txt">Hi! How may I help you? Please describe your problem.</p>
          </div>
          <div className="iconChat">
            <button className="chat_btn" onClick={handleOpenWhatsapp}>
              <img src={Whatsapp} alt="" className="whatsapp-img"></img>
              continue to chat
            </button>
          </div>
          <p className="or_text">Or</p>
          <a className="phone_txt_container" href={'tel:+610478094782'} target={'_blank'}>
            <img src={phone} alt="" height={'30px'} width={'30px'}></img>
            <p className="number_Txt">+61 0478 094 782</p>
          </a>
        </Col>
      </Row>
    </Container>
  );
};
export default HelpSupport;
