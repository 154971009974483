import { createSlice } from '@reduxjs/toolkit';
import { getDriverList, getJobList, getListOfLoadings, getCompletedListOfLoadings, getInvoiceList, getBankDetailsData, getLoadAssumption, cancelledJobs } from './actions';

const slice = createSlice({
    name: 'driver',
    initialState: {
        driverList: null,
        jobList: [],
        assignLoadingList: [],
        completedLoadingList: [],
        invoicesList: [],
        userBankDetails: {},
        loadAssumption: [],
        cancelledJobsList: []
    },
    extraReducers: builder => {
        builder.addCase(getDriverList.fulfilled, (state, action) => {
            if (action?.payload?.success) {
                state.driverList = action?.payload?.data
            }
        });
        builder.addCase(getJobList.fulfilled, (state, action) => {
            if (action?.payload?.success) {
                state.jobList = action?.payload?.data
            }
        });
        builder.addCase(getListOfLoadings.fulfilled, (state, action) => {
            if (action?.payload?.success) {
                state.assignLoadingList = action?.payload?.data
            }
        });
        builder.addCase(getCompletedListOfLoadings.fulfilled, (state, action) => {
            if (action?.payload?.success) {
                state.completedLoadingList = action?.payload?.data
            }
        });
        builder.addCase(getInvoiceList.fulfilled, (state, action) => {
            if (action?.payload?.success) {
                console.log(" action?.payload?.data", action?.payload?.data);
                state.invoicesList = action?.payload?.data
            }
        });
        builder.addCase(cancelledJobs.fulfilled, (state, action) => {
            if (action?.payload?.success) {
                state.cancelledJobsList = action?.payload?.data
            }
        });
        builder.addCase(getBankDetailsData.fulfilled, (state, action) => {
            if (action?.payload?.success) {
                state.userBankDetails = action?.payload?.data
            }
        });
        builder.addCase(getLoadAssumption.fulfilled, (state, action) => {
            if (action?.payload?.success) {
                state.loadAssumption = action?.payload?.data
            }
        });
    },
});

export default slice.reducer;
