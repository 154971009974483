import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container, Spinner } from "react-bootstrap";
import PasswordForm from "components/PasswordForm/PasswordForm";
// import PasswordForm from "./components/PasswordForm";
import "./ChangePassword.css"
import { changePassword } from "store/auth/actions";
import Toast, { MainToastContainer } from "utils/Toast";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";


const styles = {
  dashboardTxt: {
    fontSize: "14px",
    fontFamily: "Montserrat",
    color: "rgb(139,139,139)",
  },
  jobsTxt: {
    fontSize: "14px",
    fontFamily: "Montserrat",
    textDecoration: "underline",
    fontWeight: "500",
  },
  breadcrumb: {
    padding: 0,
  },
};

const ChangePassword = () => {

  const [state, setState] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
    isLoading: false
})

  const dispatch = useDispatch()


  const onChangePassword = async (e) => {
    e.preventDefault()
    if(state.oldPassword === '' || state.newPassword === '' || state.confirmPassword == ''){
      alert("Please fill all fields!")
    }
    else if(state.oldPassword === state.newPassword){
      alert("Old password should not match with new password!")
    }
    else if(state.newPassword !== state.confirmPassword){
      alert("Confirm password does not match with new password!")
    }
    else{
    let payload = {
      old_password: state.oldPassword,
      password: state.newPassword
    }
    setState(prev => ({ ...prev, isLoading: true }))
    await dispatch(changePassword(payload)).then((response) => {
      if (response?.payload?.success) {
        setState(prev => ({ ...prev, isLoading: false }))
        alert(response?.payload?.message)
        // history.goBack()
      } else {
        alert(response?.payload?.message)
      }
    })
    setState(prev => ({ ...prev, isLoading: false, oldPassword: '', newPassword: '', confirmPassword: '' }))
  }
}

  


  return (
    <Container fluid>
      <ul className="breadcrumb ps-0 ms-0" style={styles.breadcrumb}>
      <li className="p-0">
              <Link to="/profile" style={{ ...styles.dashboardTxt }}>Profile</Link>
            </li>
        <li>
          <a href="/ChangePassword" style={styles.jobsTxt}>
            Change Password
          </a>
        </li>
      </ul>
      <Row className="m-0 p-0" style={{ display: "flex" }}>
        <span className="profile_Tab_Txt">Change Password</span>
      </Row>
      <Row className="passwordContainer">
        <PasswordForm 
        label_name={"Old Password"} 
        placeholder_name={"Enter Old password"} 
        icon={true} 
        value={state.oldPassword} 
        dataSave={e => { setState(prev => ({ ...prev, oldPassword: e.target.value })) }}
        />
        <PasswordForm 
        label_name={"New Password"} 
        placeholder_name={"Enter New password"} 
        icon={true} 
        value={state.newPassword} 
        dataSave={e => { setState(prev => ({ ...prev, newPassword: e.target.value })) }}
        />
        <PasswordForm 
        label_name={"Confirm Password"} 
        placeholder_name={"Re-Enter New password"} 
        value={state.confirmPassword} 
        dataSave={e => { setState(prev => ({ ...prev, confirmPassword: e.target.value })) }}
        />
        <button className="btn-save" onClick={onChangePassword}>
        {
                        state.isLoading ?
                          <Spinner animation="border" className="text-white" size="sm" />
                          :
                          <span className="text-white"> Save</span>
                      }
        
        </button>
      </Row>
    </Container>
  )
}

export default ChangePassword