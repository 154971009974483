import React from "react";
import { Link } from "react-router-dom";
import weight from "../../../assets/img/weight.png";
import date from "../../../assets/img/date.png";
import timing from "../../../assets/img/timing.png";

const TableContent = ({ backgroundColor, fontSize, ...props }) => {
  return (
    <>
      <tbody className="p-0 mt-3">
        <tr style={styles.tableRowShaddow} className="pt-6">
          <td style={{ ...styles.tdView }}>{props?.invoiceNumber}</td>
          <td>
            <div
              style={{
                ...styles.tdView,
                width: "90px",
                display: "flex",
                padding: 0,
              }}
            >
              <span>{` ${props?.materialName}`}</span>
            </div>
            <div style={{ ...styles.tdView, width: "90px" }}>
              <img src={weight} alt="" style={{ ...styles.weightStyle }} />
              <span
                style={{
                  ...styles.tdView,
                  paddingLeft: "5px",
                  paddingTop: "7px",
                }}
              >
                {props?.quantity}
              </span>
            </div>
          </td>
          <td>
            <div style={{ flexDirection: "column" }}>
              <div style={{ ...styles.tdView, border: "none" }}>
                <img src={date} alt="" style={styles.weightStyle} />
                <span style={{ ...styles.tdView }}>{` ${props?.deliveryDate}`}</span>
              </div>
            </div>
            {/* <div style={{ ...styles.tdView, border: "none" }}>
              <img src={timing} alt="" style={styles.weightStyle} />
              <span style={styles.tdView}> Delivered at {props?.deliveryTime}</span>
            </div> */}
          </td>
          <td>
            <span style={{ ...styles.tdView }} className="amountTxt">
              {props?.amount}
            </span>
          </td>
          <td>
            <div
              style={{
                width: "60px",
                height: "30px",
                backgroundColor: props?.status === 'paid' ?  "rgb(154,255,170,0.5)" : "rgb(255, 197, 203)",
                borderRadius: "20px",
                textAlign: "center",
              }}
            >
              <span
                style={{ 
                  ...styles.tdView,
                  width: "120px",
                  color: props?.status === 'paid' ? "rgb(2,196,34)" : "rgb(255 0 26)",
                }}
              >
                {props?.status}
              </span>
            </div>
          </td>
          <td onClick={props?.status === 'paid' ? props?.onShowReceipt : null}>
            <span style={{ ...styles.tdView, color: props?.status === 'paid' ? 'rgb(0,126,229)' : 'black', textDecoration: 'underline', fontWeight: '600' }} className="amountTxt">
              {props?.status === 'paid' ? "View Receipt" : "-----"}
            </span>
          </td>
        </tr>
      </tbody>
    </>
  );
};

const styles = {
  trHeading: {
    backgroundColor: "rgb(109,109,109)",
    fontFamily: "Montserrat",
    fontWeight: "600",
    color: "white",
    borderRadius: "30px",
    marginTop: "10px",
  },
  textHeading: {
    fontSize: "12px",
    fontFamily: "Montserrat",
    fontWeight: "700",
    color: "white",
    textTransform: "none",
  },
  tableRowShaddow: {
    boxShadow: "1px 1px 1px 1px rgba(0, 126, 229, 0.2)",
    borderRadius: "10px",
    marginBottom: "1em",
  },
  tdView: {
    fontSize: "12px",
    fontFamily: "Montserrat",
    color: "rgb(23,32,63)",
    flexDirection: "row",
    marginTop: 5,
    marginBottom: 5,
  },
  weightStyle: {
    width: "13px",
    height: "13px",
  },
  statusViewContainer: {
    width: "50px",
    height: "7px",
    backgroundColor: "rgb(191, 190, 190)",
  },
  buttonStyle: {
    width: "115px",
    height: "35px",
    marginTop: "7px",
    borderRadius: "7px",
    border: "none",
    color: "white",
    fontSize: "11px",
    fontWeight: "700",
  },
  table: {
    border: "1px solid #CCC",
    borderCollapse: "collapse",
  },
};

export default TableContent;
