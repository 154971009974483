import React from "react";
import rightarrow from "../../assets/img/rightarrow.png";
import "../../assets/css/demo.css"

const DrawerContent = (props) => {

  const { drawerTxt, to } = props;

  return (
    <div className="profileTxt-container" >
      <span className="drawerTxt">{drawerTxt}</span>
      <a href={to}> <img src={rightarrow} height={"30px"} width={"30px"} alt=""></img>
      </a>
    </div>
  );
};

export default DrawerContent;
