import React, {useEffect, useState} from "react";
import {Button, Modal} from 'react-bootstrap';
import moment from "moment";
import order_invoice from "../../../../assets/img/invoiceimg.png";
import checked from "../../../../assets/img/checked.png";
import download from '../../../../assets/img/download.png';
import "../../orderHistory.css";
import Whatsapp from "../../../../assets/img/whatsapp.png";
import { Link, useHistory } from "react-router-dom";



const styles = {
  th: {
    padding: '15px',
  },
  td: {
    paddingTop: '20px',
    paddingBottom: '15px',
    fontSize: '12px',
    fontFamily: 'Montserrat',
    fontWeight: '500',
    color: 'rgb(109,109,109)',
    border: 'none'
  },
  headingTxt: {
    fontSize: '12px',
    fontFamily: 'Montserrat',
    fontWeight: '700',
    color: 'black',
  },
  logoContainer: {
    width: '30px',
    height: '30px',
    backgroundColor: 'rgb(241,241,241)',
    borderRadius: '15px',
    //   marginLeft: '5px',
  },
  statusContainer: {
    width: '50px',
    height: '20px',
    backgroundColor: 'rgb(2,196,34,0.1)',
    borderRadius: '10px',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
    //   marginLeft: '10px',
  },
  statusTxt: {
    fontSize: '10px',
    color: 'rgb(2,196,34)',
    alignSelf: 'center',
    paddingTop: '2px',
  },
  inactiveTxt: {
    fontSize: '10px',
    color: 'rgb(255, 0, 26)',
    alignSelf: 'center',
    paddingTop: '2px',
  },
  inactiveContainer: {
    width: '50px',
    height: '20px',
    backgroundColor: 'rgb(255, 0, 26, 0.1)',
    borderRadius: '10px',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
  },
}

const ModalView = ({title, data}) => {
  const [show, setShow] = useState(false);
  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if(data?.length === 0){
      handleClose()
    }
  }, [data])

  const history = useHistory()



  return (
    <>
      {/* <span className="orderDetailTxt" onClick={data?.length > 0 && }>{data?.length > 0 ? title : 'No orders details'}</span> */}
      {/* <Modal show={show} onHide={handleClose}  size="lg">
        <Modal.Body>
          <a className="logo-img" style={{display: 'flex', justifyContent: 'center'}}>
            <img src={require("assets/img/rubbl.png")} alt="..." style={{backgroundColor:'rgb(0,126,229)', width: '180px', height:'50px'}}/>
          </a>
          <div style={{display: 'flex', justifyContent: 'center', marginTop: '15px'}}>
            <p>{"Your Earnings $8900"}</p>
          </div>
        <table>
  <tr>
    <th>Company</th>
    <th>Contact</th>
    <th>Country</th>
  </tr>
  <tr>
    <td>Alfreds Futterkiste</td>
    <td>Maria Anders</td>
    <td>Germany</td>
  </tr>
  <tr>
    <td>Centro comercial Moctezuma</td>
    <td>Francisco Chang</td>
    <td>Mexico</td>
  </tr>
</table> */}
        {/* <div style={{display: 'flex'}}>
                  <div style={{width: '100%', border: '1px solid rgba(0, 0, 0, 0.1)', margin: '1px', background: 'rgba(0, 0, 0, 0.1)', display: 'flex', justifyContent:'center', alignItems: 'center'}}>
                  Rego
                  </div>
                  <div style={{width: '100%', border: '1px solid rgba(0, 0, 0, 0.1)', margin: '1px', background: 'rgba(0, 0, 0, 0.1)', display: 'flex', justifyContent:'center', alignItems: 'center'}}>
                    Delivery Date
                  </div>
                  <div style={{width: '100%', border: '1px solid rgba(0, 0, 0, 0.1)', margin: '1px', background: 'rgba(0, 0, 0, 0.1)', display: 'flex', justifyContent:'center', alignItems: 'center'}}>
                  Quantity
                  </div>
                  <div style={{width: '100%', border: '1px solid rgba(0, 0, 0, 0.1)', margin: '1px', background: 'rgba(0, 0, 0, 0.1)', display: 'flex', justifyContent:'center', alignItems: 'center'}}>
                  Price
                  </div>
                </div>
          {
            data?.map(item => {
              var Quantity = Math.round(item?.quantity*Math.pow(10,2))/Math.pow(10,2)
              return(
                <div style={{display: 'flex'}}>
                  <div style={{width: '100%', border: '1px solid rgba(0, 0, 0, 0.1)', margin: '1px', display: 'flex', justifyContent:'center', alignItems: 'center'}}>
                  {item?.shipment_transporter?.vehicle?.rego || '-'}
                  </div>
                  <div style={{width: '100%', border: '1px solid rgba(0, 0, 0, 0.1)', margin: '1px', display: 'flex', justifyContent:'center', alignItems: 'center'}}>
                    {moment(item?.date).format('D/M/Y') || '-'}
                  </div>
                  <div style={{width: '100%', border: '1px solid rgba(0, 0, 0, 0.1)', margin: '1px', display: 'flex', justifyContent:'center', alignItems: 'center'}}>
                  {`${Quantity || '-'}${item?.unit || ''}`}
                  </div>
                  <div style={{width: '100%', border: '1px solid rgba(0, 0, 0, 0.1)', margin: '1px', display: 'flex', justifyContent:'center', alignItems: 'center'}}>
                  {`$${item?.price || '-'}`}
                  </div>
                </div>
              );
            })
          } */}
        {/* </Modal.Body>
        <Modal.Footer style={{display: 'flex', justifyContent:'flex-end'}}>
        <Button variant="light" onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
}


const TableOrder = (props) => {

  const [modal,setModal] = useState(false)
  const {
    orderId,
    material,
    status,
    type,
    totalamount,
    currency,
    date,
    orderdetail,
    color,
    marginLeft,
    quantity,
    shipment,
    onViewDetails,
    handleDetail
  } = props;

  const style2 = { color: color, marginLeft: marginLeft };


  const handleClick = (item) => {
    console.log("item888",item);
    handleDetail(item)
    // onViewDetails()
  }

  return (
        <tr scope="row" style={{   flexDirection: "row", display: "flex", justifyContent: 'space-between', flex: 1, border: '12px', boxShadow: "rgb(0,126,229,0.5)" }}>
        {/* <th scope="row" style={styles.td} >
  {"1"}
</th> */}
<td style={{...styles.td, color: 'rgb(0,126,229)', fontSize: '12px',
    fontFamily: 'Montserrat',
    fontWeight: '600', border: 20, borderTopColor: 'red',}}>
{orderId+`  `+material}
  { shipment ? shipment.map((i) => {
  return(
<div
            style={{ flexDirection: "row", display: "flex", paddingTop: "5px", color: 'rgb(109,109,109)' }}
          >
            {i?.shipment_transporter?.vehicle?.rego ? i?.shipment_transporter?.vehicle?.rego : '------'}
            {/* <img src={order_invoice} alt="" className="invoicebtn" /> */}
          </div> 
  )
  }) : '-----'}
  
</td>
<td style={{...styles.td, color: 'rgb(0,126,229)',
    fontFamily: 'Montserrat',
    fontWeight: '600',}}>
{orderId+`  `+material}
  { shipment ? shipment.map((i) => {
        let Date = moment(i?.loading_time).format("DD/MM/YYYY")
  return(
<div
            style={{ flexDirection: "row", display: "flex", paddingTop: "5px", color: 'rgb(0,126,229)', fontSize: '12px',color: 'rgb(109,109,109)' }}
          >
            { i?.loading_time ? Date : '----'}
            {/* <img src={order_invoice} alt="" className="invoicebtn" /> */}
          </div> 
  )
  }) : '-----'}
  
</td>
<td style={{...styles.td, color: 'rgb(0,126,229)',
    fontFamily: 'Montserrat',
    fontWeight: '600',}}>
{orderId+`  `+material}
  { shipment ? shipment.map((i) => {
        let Quan = Math.round(i?.quantity*Math.pow(10,2))/Math.pow(10,2);

  return(
<div
            style={{ flexDirection: "row", display: "flex", paddingTop: "5px",color: 'rgb(109,109,109)', fontSize: '12px', }}
          >
            {i?.quantity ? Quan+i?.unit : '---' }
            {/* <img src={order_invoice} alt="" className="invoicebtn" /> */}
          </div> 
  )
  }) : '-----'}
  
</td>
{/* <td style={{...styles.td, color: 'rgb(0,126,229)', fontSize: '12px',
    fontFamily: 'Montserrat',
    fontWeight: '600',}}>
  {material}
  {shipment.map((i) => {
    let Date = moment(i?.loading_time).format("DD-MM-YYYY")
  return(
<div
            style={{ flexDirection: "row", display: "flex", paddingTop: "5px" }}
          >
            {i?.loading_time ? Date : '---' }
          </div> 
  )
  })}
</td> */}
<td style={{...styles.td, color: 'rgb(0,126,229)', fontSize: '12px',
    fontFamily: 'Montserrat',
    fontWeight: '600',}}>
  {quantity}
  {shipment.map((i) => {
    let Price =  Math.round(i?.payment?.final_amount*Math.pow(10,2))/Math.pow(10,2);

  return(
<div
            style={{ flexDirection: "row", display: "flex", paddingTop: "5px", color:'rgb(109,109,109)', fontSize: '12px', textDecoration: 'underline',
            fontFamily: 'Montserrat',
            fontWeight: '600' }}
            onClick={() => handleClick(i)}
          >
            {i?.payment?.final_amount ? `$`+Price : '---' }
            {/* <img src={order_invoice} alt="" className="invoicebtn" /> */}
          </div> 
  )
  })}
   <div
            style={{ flexDirection: "row", display: "flex", paddingTop: "5px" }}
          >
      {/* <span className="orderDetailTxt" onClick={onViewDetails}>{props?.mainObj?.shipments?.length > 0 ? "View Order details" : 'No orders details'}</span> */}
      </div> 
</td>
      {/* <td>
        <div
          style={{ flexDirection: "row", display: "flex", justifyContent: 'space-between', flex: 1 }}
        >
          <span className="OrderIdTxt">{orderId}</span>
          <span className="OrderIdTxt">{orderId}</span>

         <div
            style={{ flexDirection: "row", display: "flex", paddingTop: "5px" }}
          >
            <img src={order_invoice} alt="" className="invoicebtn" />
          </div> 
        </div>
      </td> */}
      {/* <td>
        <div style={{ flexDirection: "row", display: "flex" }}>
          <img src={checked} alt="" className="checkedbtn" />

          <span className="deliverTxt">{material}</span>
        </div>
      </td> */}
      {/* <td style={{ paddingLeft: "10px" }}>
        <div
          style={{ flexDirection: "row", display: "flex", marginLeft: "20px" }}
        >
          <span className="orderStatusTxt">{status}</span>
          <span className="ordertypeTxt" style={{ ...style2 }}>
            {type}
          </span>
        </div>
      </td> */}
      {/* <td style={{ textAlign: "center" }}>
        <div
          style={{ flexDirection: "row", display: "flex", marginLeft: "20px" }}
        >
          <span className="orderStatusTxt">{totalamount}</span>
          <span className="currencyTxt">{currency}</span>
        </div>
      </td> */}
      {/* <td style={{ paddingLeft: "70px" }}>
        <div style={{ flexDirection: "column", display: "flex" }}>
          <span className="datesTxt">{date}</span>

          <div
            style={{ flexDirection: "row", display: "flex", paddingTop: "5px" }}
          >
            <ModalView title={orderdetail} data={props?.mainObj?.shipments || []} />
          </div>
        </div>
      </td> */}
    </tr>
  );
};

export default TableOrder;
