import React from "react";
import { useHistory } from "react-router-dom";
import CustomTable from "components/CustomTable/CustomTable";
import "../../TopTabBar.css";

const AssignedList = (props) => {
  const history = useHistory();

  // const handleClick = (props) => {
  //   // history.push("/track");
  // };

  return (
    <CustomTable
      orderId={props?.orderId}
      materialName={props?.materialName}
      quantity={props?.quantity}
      distance={props?.distance}
      loaderName={props?.loaderName}
      loaderNumber={props?.loaderNumber}
      unloaderName={props?.unloaderName}
      unloaderNumber={props?.unloaderNumber}
      deliveryDate={props?.deliveryDate}
      deliveryTime={props?.deliveryTime}
      deliveryLastTime={props?.deliveryLastTime}
      startedTime={props?.startedTime}
      seeDetails={props?.seeDetails}
      onPressSeeDetails={props?.handleClick}
      track={true}
      status={props?.status}
    />
  );
};
export default AssignedList;
