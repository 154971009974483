import { createSlice } from '@reduxjs/toolkit';
import { getTruckList, getVehicleType, getTruckType } from './actions';

const slice = createSlice({
    name: 'truckType',
    initialState: {
        truckTypeList: null,
        vehicleTypesList: null,
        truckType: null

    },
    reducers: {
        // authenticate: (state, action) => {
        // },
    },
    extraReducers: builder => {
        builder.addCase(getTruckList.fulfilled, (state, action) => {
            if (action?.payload?.success) {
                state.truckTypeList = action?.payload?.data
            }
        });
        builder.addCase(getTruckType.fulfilled, (state, action) => {
            if (action?.payload?.success) {
                console.log(action.payload.data)
                state.truckType = action?.payload?.data
            }
        });
        // builder.addCase(getVehicleType.fulfilled, (state, action) => {
        //     if (action?.payload?.success) {
        //         state.vehicleTypesList = action?.payload?.data
        //     }
        // });
    },
});

// export const { setUserDetails } = slice.actions;
export default slice.reducer;
