import { createAsyncThunk } from '@reduxjs/toolkit';
import { forgotPasswordApi } from 'services';
import { authenticateApi, updateNotificationStatusApi, changePasswordApi,getNotificationStatusApi, getUserProfileApi, setUserProfileApi, getNotificationListApi, fireMarkAllAsReadApi, getPagesApi, sendDeviceTokenApi } from 'services';
import { AUTHENTICATE_USER, CHANGE_PASSWORD, FORGOT_PASSWORD, GET_USER_PROFILE, SET_USER_PROFILE, GET_NOTIFICATION_LIST, FIRE_MARK_ALL_AS_READ, GET_PAGES_API, SEND_DEVICE_TOKEN_API, GET_NOTIFICATION_STATUS, UPDATE_NOTIFICATION_STATUS } from "../actionTypes"

export const authenticate = createAsyncThunk(AUTHENTICATE_USER, async data => {
    const response = await authenticateApi(data);
    return response.data;
});

export const changePassword = createAsyncThunk(CHANGE_PASSWORD, async (payload) => {
    try {
        const response = await changePasswordApi(payload);
        return response.data;
    } catch (error) {
        return error.response.data
    }
});

export const getUserProfile = createAsyncThunk(GET_USER_PROFILE, async (payload) => {
    try {
        const response = await getUserProfileApi(payload);
        return response.data;
    } catch (error) {
        return error.response.data
    }
});

export const setUserProfile = createAsyncThunk(SET_USER_PROFILE, async (payload) => {
    try {
        const response = await setUserProfileApi(payload);
        return response.data;
    } catch (error) {
        return error.response.data
    }
});

export const getNotificationList = createAsyncThunk(GET_NOTIFICATION_LIST, async (queryParams) => {
    try {
        const response = await getNotificationListApi(queryParams);
        return response.data;
    } catch (error) {
        return error.response.data
    }
});

export const getNotificationStatus = createAsyncThunk(GET_NOTIFICATION_STATUS, async () => {
    try {
        const response = await getNotificationStatusApi();
        return response.data;
    } catch (error) {
        return error.response.data
    }
});

export const updateNotificationStatus = createAsyncThunk(UPDATE_NOTIFICATION_STATUS, async (queryParams) => {
    try {
        const response = await updateNotificationStatusApi(queryParams);
        return response.data;
    } catch (error) {
        return error.response.data
    }
});

export const fireMarkAllAsRead = createAsyncThunk(FIRE_MARK_ALL_AS_READ, async (data) => {
    try {
        const response = await fireMarkAllAsReadApi(data);
        return response.data;
    } catch (error) {
        return error.response.data
    }
});

export const getPages = createAsyncThunk(GET_PAGES_API, async (data) => {
    try {
        const response = await getPagesApi(data);
        return response.data;
    } catch (error) {
        return error.response.data
    }
});

export const forgotPassword = createAsyncThunk(FORGOT_PASSWORD, async (payload) => {
    try {
        const response = await forgotPasswordApi(payload);
        return response.data;
    } catch (error) {
        return error.response.data
    }
});

export const sendDeviceToken = createAsyncThunk(SEND_DEVICE_TOKEN_API, async (payload) => {
    try {
        const response = await sendDeviceTokenApi(payload);
        return response.data;
    } catch (error) {
        return error.response.data
    }
});




