import React, { useEffect, useState } from "react";
import { Container, Row, Col, Modal, Spinner } from "react-bootstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import { IoIosSearch } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import DropDownToggle from "components/DropDownToggle/DropDownToggle";
import {
  getInvoiceList,
  getBankDetailsData,
  addBankDetailsData,
} from "store/driver/actions";
import Toast, { MainToastContainer } from "utils/Toast";
import TableContent from "./components/TableContent";
import BankDetailForm from "./components/BankDetailForm";
import editOutLine from "../../assets/img/editOutline.png";
import "../bankDetailAndInvoices/bankDetail.css";
import { authenticate } from "store/auth/actions";
import { getUserProfile } from "store/auth/actions";
import { isValidName } from "utils/isValidate";
import { isValidDigit } from "utils/isValidate";


const BankDetail = () => {


 
  const dispatch = useDispatch();
  const { invoicesList: invoicesListData, userBankDetails } = useSelector(
    (state) => state.driverReducer
  );
  const [edit, setEdit] = useState(false);
  const [change, setChange] = useState(false);
  const [id,setId] = useState('')
  const [showModal,setShowModal] = useState(false)
  const [receiptItem,setReceiptItem] = useState('')
  const [loader,setLoader] = useState(true)
  
  let amountPaidAt = moment.utc(receiptItem?.updated_at).local().format('D/M/YY @ h.mma');


  const [state, setState] = useState({
    account_name: "",
    bsb: "",
    account_number: "",
    bank: "",
  });

  // useEffect(() => {
  //    dispatch(getUserProfile()).then(res => {
  //     console.log("res48242479",res);
  //     setId(res?.payload?.data?.id)
  //     // if (res?.payload?.success === true && res?.payload?.data?.url) {
  //     //   updateUserProfileData(res?.payload?.data?.url)
  //     // } else {
  //     //   Toast(res?.payload?.message)
  //     //   setState(prev => ({ ...prev, loading: false }))
  //     // }
  //   })
  // }, []);

  useEffect(() => {
    getInvoiceListData();
    getBankDetails();
  }, []);

  useEffect(() => {
    if (
      Object.keys(userBankDetails).length > 0 &&
      state.account_name === "" &&
      state.bsb === "" &&
      state.account_number === "" &&
      state.bank === ""
    ) {
      setState((prev) => ({
        ...prev,
        account_name: userBankDetails?.account_name,
        bsb: userBankDetails?.bsb,
        account_number: userBankDetails?.account_number,
        bank: userBankDetails?.bank,
      }));
    }
  }, [userBankDetails]);

  const { userDetails } = useSelector(state => state.authReducer);
  console.log("orders99999", userDetails);


  const getInvoiceListData = () => {
    let data = {
       user_id: userDetails?.id
    }
    dispatch(getInvoiceList(data)).then((res) => {
      // if(res.success){
        setLoader(false)
      // }
    })
  };

  const getBankDetails = () => {
    dispatch(getBankDetailsData()).then((res) => {
      // if(res?.success){
      //   setLoader(false)
      // }
    });
  };
  
  const setBankDetails = () => {
    dispatch(addBankDetailsData(state)).then(res => {
      if(res?.payload?.success === true){
        Toast('Branch details successfully updated.')
        getBankDetails();
      } else {
        Toast(res?.payload?.message)
        setState(userBankDetails)
      }
    });
  };

  const setMessage = (text, stateKey) => {
    setState((prev) => ({ ...prev, [`${stateKey}`]: text }));
  };

  const checkButtonDisable = () => {
    if(state.account_name === '' || state.account_number === '' || state.bank === '' || state.bsb === ''){
      return false
    } else if (state.account_name === userBankDetails?.account_name && state.account_number === userBankDetails?.account_number && state.bank === userBankDetails?.bank && state.bsb === userBankDetails?.bsb) {
      return false
    } else {
       return true
    }
  };

  const handleEdit = () => {
    setBankDetails()
  };

  const handleClose = () => {
    setShowModal(false)
  }

  const handleChange = () => {
    setChange(true);
    setEdit(false);
  };

  const onShowReceipt = (item) => {
    console.log("item receipt",item)
    setShowModal(!showModal)
    setReceiptItem(item)
  }

    if (loader) {
      return (
        <div style={styles.spinnerContainer}>
          <Spinner animation="border" variant="primary" />
        </div>
      );
    }
  

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <ul className="breadcrumb ps-0 ms-0" style={styles.breadcrumb}>
            <li className="p-0">
              <Link to="/" style={{ ...styles.dashboardTxt }}>Dashboard</Link>
            </li>
              <li>
                <a href="/my-loads" style={styles.dashboardTxt}>
                  MyLoads
                </a>
              </li>
              <li>
                <a href="#" style={styles.jobsTxt}>
                  Bank Details & Payments
                </a>
              </li>
            </ul>
            <Row style={{ backgroundColor: "rgb(247,247,247)", boxShadow:'2px 2px 2px 2px rgba(0, 126, 229, 0.2)', margin: 5 }}>
              {/* <Row style={{ backgroundColor: "rgb(247,247,247)", boxShadow:'2px 2px 2px 2px rgba(0, 126, 229, 0.2)',  marginTop: '20px',
                  marginLeft: '10px', marginRight:'10px',
                  padding: '10px' }}> */}
                <Col
                  md={12}
                  // className="pb-5"
                >
                  {/* <div className="Bank_detail_container"> */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      className="pt-2"
                    >
                      <span className="BankDetail_text">Bank Details</span>
                      {edit ? (
                        <div className="edit">
                          <p className="editTxt">Edit Details</p>

                          <img
                            src={editOutLine}
                            alt=""
                            className="editDetails"
                            onClick={handleChange}
                          ></img>
                        </div>
                      ) : change ? (
                        <div className="button_styling">
                          <button className="buttonCancel">Cancel</button>
                          <button className="buttonSave">Save Changes</button>
                        </div>
                      ) : (
                        <button
                          className="saveButton"
                          style={{
                            backgroundColor: checkButtonDisable()
                              ? "rgb(22,82,240)"
                              : "rgb(139,139,139)",
                          }}
                          disabled={checkButtonDisable() ? false : true}
                          onClick={handleEdit}
                        >
                          Save
                        </button>
                      )}
                    </div>
                    <span className="add_detail_text">
                      Please add your bank details
                    </span>
                    <Row style={{ display: "flex", flexDirecation: "row" }}>
                      <Col md={3}>
                        <BankDetailForm
                          value={state.account_name}
                          label={"Account Name"}
                          input_text={"Enter account name"}
                          maxLength={30}
                          handleChange={(e) => {
                            if( e.target.value == '' || isValidName(e.target.value)){
                            setMessage(e.target.value, "account_name")
                            }
                            else{
                              alert("Please enter valid account name")
                            }
                           }
                          }
                        />
                      </Col>
                      <Col md={3}>
                        <BankDetailForm
                          value={state.bsb}
                          label={"BSB"}
                          input_text={"Enter BSB"}
                          paddingTop={"15px"}
                          maxLength={6}
                          handleChange={(e) => {
                            if(isValidDigit(e.target.value)){
                            setMessage(e.target.value, "bsb")
                            }
                            else{
                              alert("Please enter valid bsb")
                            }
                            }
                          }
                        />
                      </Col>
                      <Col md={3}>
                        <BankDetailForm
                          value={state.account_number}
                          label={"Account Number"}
                          input_text={"Enter account no."}
                          maxLength={20}
                          // paddingTop={"4px"}
                          handleChange={(e) => {
                            if(isValidDigit(e.target.value)){
                            setMessage(e.target.value, "account_number")
                            }
                            else{
                              alert("Please enter valid account number")
                            }
                          }
                        }
                        />
                      </Col>
                      <Col md={3}>
                        <BankDetailForm
                          value={state.bank}
                          label={"Bank"}
                          input_text={"Enter Bank"}
                          paddingTop={"15px"}
                          maxLength={25}
                          handleChange={(e) => {
                            if( e.target.value == '' || isValidName(e.target.value)){
                            setMessage(e.target.value, "bank")
                            }
                            else{
                              alert("Please enter valid bank name")
                            }
                          }
                        }
                        />
                      </Col>
                    </Row>
                    {/* </Row> */}
                  {/* </div> */}
                 
                  {/* <Row sm={6} xl={12} className="m-0 mb-0 p-0">
                    <Col md={12} className="me-0 pe-0">
                    <div className="table-responsive-sm pe-3 me-2">
                  <table className="table border-none p-0 m-0">
                          <thead style={{ borderRadius: "10px" }}>
                            <tr
                              style={{
                                ...styles.trHeading,
                                borderRadius: "20px",
                                textAlign: "center",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <th style={styles.textHeading}>Invoice Number</th>
                              <th style={styles.textHeading}>Material</th>
                              <th style={styles.textHeading}>Date</th>
                              <th style={styles.textHeading}>Amount</th>
                              <th style={styles.textHeading}>Payment Status</th>
                              <th style={styles.textHeading}>Receipt</th>
                              <th
                                style={{
                                  ...styles.textHeading,
                                  color: "rgb(109,109,109)",
                                }}
                              >
                                {"heading"}
                              </th>
                            </tr>
                          </thead>
                          {invoicesListData.map((item, index) => {
                            console.log("item899",item);
                           
                           let Price = Math.round(item?.final_amount*Math.pow(10,2))/Math.pow(10,2);
                           let Quantity = Math.round(item?.shipment?.quantity*Math.pow(10,2))/Math.pow(10,2);
                          return(
                            <TableContent
                              key={index.toString()}
                              status={item?.status}
                              onShowReceipt={() => onShowReceipt(item)}
                              invoiceNumber={`#${item?.shipment?.shipment_no}`}
                              amount={`$${Price}`}
                              materialName={
                                item?.shipment?.order?.supplier_material
                                  ?.material?.name
                              }
                              quantity={`${Quantity}${item?.shipment?.unit}`}
                              deliveryDate={moment(
                                item?.shipment?.date
                              ).format("D/M/Y")}
                              deliveryTime={moment(
                                item?.shipment?.loading_time
                              ).format("Ha")}
                            />
                          )
                              } 
                          )}
                        </table>
                      </div>
                    </Col>
                  </Row> */}
                </Col>
              {/* </Row> */}
             
                     {/* <Row sm={6} xl={12} className="m-0 mb-0 p-0">
                    <Col md={12} className="me-0 pe-0">
                    <div className="table-responsive-sm pe-3 me-2">
                  <table className="table border-none p-0 m-0">
                          <thead style={{ borderRadius: "10px" }}>
                            <tr
                              style={{
                                ...styles.trHeading,
                                borderRadius: "20px",
                                textAlign: "center",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <th style={styles.textHeading}>Invoice Number</th>
                              <th style={styles.textHeading}>Material</th>
                              <th style={styles.textHeading}>Date</th>
                              <th style={styles.textHeading}>Amount</th>
                              <th style={styles.textHeading}>Payment Status</th>
                              <th style={styles.textHeading}>Receipt</th>
                              <th
                                style={{
                                  ...styles.textHeading,
                                  color: "rgb(109,109,109)",
                                }}
                              >
                                {"heading"}
                              </th>
                            </tr>
                          </thead>
                          {invoicesListData.map((item, index) => {
                            console.log("item899",item);
                           
                           let Price = Math.round(item?.final_amount*Math.pow(10,2))/Math.pow(10,2);
                           let Quantity = Math.round(item?.shipment?.quantity*Math.pow(10,2))/Math.pow(10,2);
                          return(
                            <TableContent
                              key={index.toString()}
                              status={item?.status}
                              onShowReceipt={() => onShowReceipt(item)}
                              invoiceNumber={`#${item?.shipment?.shipment_no}`}
                              amount={`$${Price}`}
                              materialName={
                                item?.shipment?.order?.supplier_material
                                  ?.material?.name
                              }
                              quantity={`${Quantity}${item?.shipment?.unit}`}
                              deliveryDate={moment(
                                item?.shipment?.date
                              ).format("D/M/Y")}
                              deliveryTime={moment(
                                item?.shipment?.loading_time
                              ).format("Ha")}
                            />
                          )
                              } 
                          )}
                        </table>
                      </div>
                    </Col>
                  </Row> */}
            </Row>
            <Row className="m-0 p-0 ms-3">
              <span className="bankinvoice_text">Payments</span>
            </Row>
            <Row sm={6} xl={12} className="mt-1 pe-2">
              <Col md={12} className="ms-2 ps-3">
                <div className="table-responsive-sm pe-3 me-2">
                  <table className="table border-none p-0 m-0">
                          <thead style={{ borderRadius: "10px" }}>
                            <tr
                              style={{
                                ...styles.trHeading,
                                borderRadius: "20px",
                                textAlign: "center",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <th style={styles.textHeading}>Invoice Number</th>
                              <th style={styles.textHeading}>Material</th>
                              <th style={styles.textHeading}>Date</th>
                              <th style={styles.textHeading}>Amount</th>
                              <th style={styles.textHeading}>Payment Status</th>
                              <th style={styles.textHeading}>Receipt</th>
                              <th
                                style={{
                                  ...styles.textHeading,
                                  color: "rgb(109,109,109)",
                                }}
                              >
                                {"heading"}
                              </th>
                            </tr>
                          </thead>
                          {invoicesListData.map((item, index) => {
                            console.log("item899",item);
                           let Price = Math.round(item?.final_amount*Math.pow(10,2))/Math.pow(10,2);
                           let Quantity = Math.round(item?.shipment?.quantity*Math.pow(10,2))/Math.pow(10,2);
                          return(
                            <TableContent
                              key={index.toString()}
                              status={item?.status}
                              onShowReceipt={() => onShowReceipt(item)}
                              invoiceNumber={`#${item?.shipment?.shipment_no}`}
                              amount={`$${Price}`}
                              materialName={
                                item?.shipment?.order?.supplier_material
                                  ?.material?.name
                              }
                              quantity={`${Quantity}${item?.shipment?.unit}`}
                              deliveryDate={moment(
                                item?.shipment?.date
                              ).format("D/M/Y")}
                              deliveryTime={moment(
                                item?.shipment?.loading_time
                              ).format("Ha")}
                            />
                          )
                              } 
                          )}
                        </table>
                      </div>
                    </Col>
                  </Row>
          </Col>
        </Row>
        <Modal
                      className="modal-mini w-200 mt-5"
                      show={showModal}
                      onHide={handleClose}
                    // size="xl"
                    >
                      <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', padding: 3}}>
                        <span style={{ fontSize: "14px",fontFamily: "Montserrat",color: "rgb(23,23,23)",fontWeight: '700'}}>Transaction Details</span>
                      </div>
                      {/* <Modal.Header
                        closeButton
                        className="modal-header no-border"
                      ></Modal.Header> */}
                      {/* <Modal.Body className="modalTextStyle"> */}
                      {/* <Container> */}
                      <div style={{ ...styles.modalContainer }}>
                        {/* <div style={{ ...styles.customHeader }}>
              <Modal.Title className="m-2 font-weight-bold">Are you sure?</Modal.Title>
              <IoIosClose size={"25px"} onClick={handleClose} />
            </div> */}
                        <Row style={{ width: "430px", flexDirection: 'column', display: 'flex' }}>
                          <div style={{ paddingLeft: '15px'}}>
                            <p style={{ fontSize: "14px",fontFamily: "Montserrat",color: "rgb(23,23,23)",fontWeight: '700'}}>Direct Deposit</p>
                            <p style={{ fontSize: "12px",fontFamily: "Montserrat",color: "rgb(23,23,23)",fontWeight: '400'}}>{`$`+receiptItem?.final_amount}</p>
                            {/* <p>hello</p> */}
                            </div>
                            <div style={{ paddingLeft: '15px'}}>
                            <p style={{ fontSize: "14px",fontFamily: "Montserrat",color: "rgb(23,23,23)",fontWeight: '700'}}>Status</p>
                            <p style={{ fontSize: "12px",fontFamily: "Montserrat",color: "rgb(23,23,23)",fontWeight: '400'}}>{receiptItem?.status === 'paid' ? "Completed" : '----'}</p>
                            {/* <p>hello</p> */}
                            </div>
                            <div style={{ paddingLeft: '15px'}}>
                            <p style={{ fontSize: "14px",fontFamily: "Montserrat",color: "rgb(23,23,23)",fontWeight: '700'}}>Date & Time</p>
                            <p style={{ fontSize: "12px",fontFamily: "Montserrat",color: "rgb(23,23,23)",fontWeight: '400'}}>{amountPaidAt}</p>
                            {/* <p>hello</p> */}
                            </div>
                        </Row>
                      </div>
                      {/* </Container> */}
                      {/* </Modal.Body> */}
                    </Modal>
      </Container>
        <MainToastContainer />
    </>
  );
};

const styles = {
  dashboardTxt: {
    fontSize: "14px",
    fontFamily: "Montserrat",
    color: "rgb(139,139,139)",
  },
  jobsTxt: {
    fontSize: "14px",
    fontFamily: "Montserrat",
    textDecoration: "underline",
    fontWeight: "500",
  },
  breadcrumb: {
    padding: 0,
  },
  searchJobScreen: {
    width: "300px",
    height: "28px",
    backgroundColor: "white",
    border: "1px solid rgb(223,223,223)",
  },
  searchIcon: {
    paddingLeft: "3px",
  },
  searchJobInput: {
    border: "0px solid",
    outline: "none",
    height: "17px",
    fontSize: "14px",
    fontFamily: "Montserrat",
    color: "rgb(223,223,223)",
  },
  trHeading: {
    backgroundColor: "rgb(109,109,109)",
    fontFamily: "Montserrat",
    fontWeight: "600",
    color: "white",
    borderRadius: "30px",
    marginTop: "10px",
  },
  textHeading: {
    fontSize: "12px",
    fontFamily: "Montserrat",
    fontWeight: "700",
    color: "white",
    textTransform: "none",
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "10px",
    paddingTop: "8px",
    width: "300px",
  },
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
};

export default BankDetail;
