import React, { useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Table } from "react-bootstrap";
// import TableOrder from "./components/orderTable";
import "../orderHistory/orderHistory.css";
import { useDispatch, useSelector } from "react-redux";
import { orderList } from "store/dashboard/actions";
import moment from 'moment'
import { Link, useHistory } from "react-router-dom";
import route from "../../assets/img/routeImg.png";
import truck from "../../assets/img/truck.png"
import { useLocation } from "react-router";


const OrderHistoryDetails = () => {
  const dispatch = useDispatch()
  const history = useHistory()


  useEffect(() => {
    dispatch(orderList())
  }, [])

  const orders = useSelector(state => state?.dashboardReducer?.ordersLists)
  const Loads = useSelector(state => state?.driverReducer?.loadAssumption)
  console.log("Loads",Loads);

  console.log("orders99999", orders);

  const location = useLocation();
  // const { item } = state;
  console.log("item",location.state);
  var orderHistoryDet = location.state.state.item
  console.log("orderHistoryDetails",orderHistoryDet);
  
  var shipments = location.state.state.shipment
  console.log("shipments",shipments);
  const totalEarnings = shipments?.payment?.final_amount ? Math.round(shipments?.payment?.final_amount*Math.pow(10,2))/Math.pow(10,2) : '-----';
  const deliveryDate = moment(shipments?.updated_at).format('DD/MM/YYYY')
  const deliveryTime =  moment(shipments?.updated_at).format('hh:mm A');
  const transportRate = +shipments?.payment?.price_exc_gst.toFixed(2);
  const loadingRate = shipments?.quantity/Loads[0]?.ton_loaded_per_hour*Loads[0]?.hourly_loading_rate

  const load = loadingRate ? +loadingRate.toFixed(2) : 0
  const transportCost = transportRate ? (transportRate + load).toFixed(2): 0

//   const orderDetail = location.state.item
// const d = moment(shipments?.updated_at).format("DD/MM/YYYY")
const delDate = moment(shipments?.updated_at).add(1, 'days').format("DD/MM/YYYY");

  return (
    <Container fluid>
      <ul className="breadcrumb ps-0 ms-0" style={styles.breadcrumb}>
      <li className="p-0">
              <Link to="/" style={{ ...styles.dashboardTxt }}>Dashboard</Link>
            </li>
        <li>
          <a href="/order-history" style={styles.dashboardTxt}>
            Management
          </a>
        </li>
        <li>
          <a href="/order-history" style={styles.dashboardTxt}>
            Order History & Earnings
          </a>
        </li>
        <li>
          <a style={styles.jobsTxt}>
            Order History Details
          </a>
        </li>
      </ul>
      <Row>
        <Col md={12}>
        <a className="logo-img" style={{display: 'flex', justifyContent: 'center',marginTop: '15px' }}>
            <img src={require("assets/img/Rubbl 2.png")} alt="..." style={{ width: '180px', height:'30px'}}/>
          </a>
          <div style={{display: 'flex', justifyContent: 'center', marginTop: '15px', ...styles.breadcrumb,border:'2px', alignItems: 'center', alignContent:'center'}} className="breadcrumb ps-0 ms-0 mt-2">
            <p style={{...styles.textHeading, textAlign:'center',paddingTop:'15px'}}>{`Your Earnings: $`+totalEarnings}</p>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <p style={{ fontSize: "16px",
    fontFamily: "Montserrat",
    fontWeight: "700",color:'rgb(0,126,229)'}}>{orderHistoryDet?.supplier_material?.material?.name}</p>
                <p style={{ fontSize: "16px",
    fontFamily: "Montserrat",
    fontWeight: "700",color:'rgb(0,126,229)'}}>{shipments?.quantity+shipments?.unit}</p>
          </div>
          <div style={{flexDirection: 'row', display: 'flex', borderBottom: '52px', borderBottomColor: 'yellow', border: '3px'}}>
          <img src={route} alt="" className="routeImgStyle" />
          <div style={{display: 'flex', flexDirection: 'column'}}>
          <p  style={{ fontSize: "16px", padding: '5px',
    fontFamily: "Montserrat",
    fontWeight: "600",color:'black'}}>{orderHistoryDet?.supplier_material?.supplier?.name ? orderHistoryDet?.supplier_material?.supplier?.name+`, `+ orderHistoryDet?.supplier_material?.supplier?.user?.address : '-----'}</p>
    <div style={{flexDirection: 'column', display: 'flex', marginTop:'25px', borderBottom: '12px', borderBottomColor: 'gray'}}>
<p style={{ fontSize: "16px", padding: '5px',
    fontFamily: "Montserrat",
    fontWeight: "600",color:'black'}}>{ orderHistoryDet?.site?.name ? orderHistoryDet?.site?.name+`, `+orderHistoryDet?.site?.address : '------'}</p>
    </div>
    </div>
    
          </div>
          <ul className="breadcrumb ps-0 ms-0 mt-2" style={{...styles.breadcrumb,border:'2px'}}>
        <li className="p-0">
          <a style={{ ...styles.dashboardTxt, ontSize: "16px", padding: '5px',
    fontFamily: "Montserrat",
    fontWeight: "400",color:'black' }}>Distance:  {orderHistoryDet?.distance+orderHistoryDet?.d_unit}</a>
        </li>
        </ul>
        <ul className="breadcrumb ps-0 ms-0 mt-2" style={{...styles.breadcrumb,border:'2px'}}>
        <li className="p-0">
          <a style={{ ...styles.dashboardTxt, ontSize: "16px", padding: '5px',
    fontFamily: "Montserrat",
    fontWeight: "400",color:'black' }}>Delivered:  {deliveryDate+`@`+deliveryTime}</a>
        </li>
        </ul>
        {/* <ul className="breadcrumb ps-0 ms-0 mt-2" style={{...styles.breadcrumb,border:'2px',display:'flex',flexDirection:'row',justifyContent:'space-between'}}> */}
        {/* <li className="p-0"> */}
          {/* <a style={{ ...styles.dashboardTxt, ontSize: "16px", padding: '5px',
    fontFamily: "Montserrat",
    fontWeight: "400",color:'black' }}>Vehicle</a>
    <img src={truck} alt="" style={{ width: 29, height: 20, marginRight: 10 }} /> */}
      

        {/* </li> */}
                <div style={{...styles.breadcrumb,display: 'flex', flexDirection: 'row', justifyContent: 'space-between',backgroundColor:'rgb(139,139,139,0.1)', padding:'5px'}}>
                <a style={{ ...styles.dashboardTxt, fontSize: "16px", 
            fontFamily: "Montserrat",
            fontWeight: "400",color:'black' }}>Vehicle</a>
                <img src={truck} alt="" style={{ width: 29, height: 20,  marginTop: 5 }} /> 
                <a style={{ ...styles.dashboardTxt, ontSize: "16px", 
            fontFamily: "Montserrat",
            fontWeight: "400",color:'black' }}>{shipments?.vehicle_type?.name}</a>
        
        <a style={{ ...styles.dashboardTxt, ontSize: "16px", 
            fontFamily: "Montserrat",
            fontWeight: "400",color:'black' }}>{shipments?.shipment_transporter?.vehicle?.rego ? shipments?.shipment_transporter?.vehicle?.rego : '----------'}</a>
                  </div>
            
        
       
          <div style={{...styles.breadcrumb,backgroundColor:'rgb(139,139,139,0.1)', padding:'5px', marginTop:'10px'}}>
        <a style={{ ...styles.dashboardTxt, fontSize: "16px", 
    fontFamily: "Montserrat",
    fontWeight: "400",color:'black' }}>Paid to you</a>
   
  
     <div style={{flexDirection:'row',justifyContent:'space-between',display:'flex'}}>
    <a style={{ ...styles.dashboardTxt, fontSize: "16px", marginTop:'10px',
    fontFamily: "Montserrat",
    fontWeight: "400",color:'black' }}>Transport Cost</a>

<a style={{ ...styles.dashboardTxt, fontSize: "16px", marginTop:'10px',
    fontFamily: "Montserrat",
    fontWeight: "400",color:'black' }}>{`$${transportCost}`}</a>

    </div>


    <div style={{flexDirection:'row',justifyContent:'space-between',display:'flex'}}>
    <a style={{ ...styles.dashboardTxt, fontSize: "16px", marginTop:'10px',
    fontFamily: "Montserrat",
    fontWeight: "400",color:'black' }}>Loading Rate</a>

<a style={{ ...styles.dashboardTxt, fontSize: "16px", marginTop:'10px',
    fontFamily: "Montserrat",
    fontWeight: "400",color:'black' }}>{`$`+load}</a>

    </div>

    <div style={{flexDirection:'row',justifyContent:'space-between',display:'flex'}}>
    <a style={{ ...styles.dashboardTxt, fontSize: "16px", marginTop:'10px',
    fontFamily: "Montserrat",
    fontWeight: "400",color:'black' }}>Transporter Rate</a>

<a style={{ ...styles.dashboardTxt, fontSize: "16px", marginTop:'10px',
    fontFamily: "Montserrat",
    fontWeight: "400",color:'black' }}>{shipments?.payment?.price_exc_gst ? `$`+transportRate : '----'}</a>

    </div>
    
   
    
        </div>
        <div style={{...styles.breadcrumb,display: 'flex', flexDirection: 'row', justifyContent: 'space-between',backgroundColor:'rgb(139,139,139,0.1)', padding:'5px',  marginTop:'10px'}}>
        <a style={{ ...styles.dashboardTxt, fontSize: "16px", 
    fontFamily: "Montserrat",
    fontWeight: "400",color:'black' }}>Service Fee</a>

<a style={{ ...styles.dashboardTxt, fontSize: "16px",
    fontFamily: "Montserrat",
    fontWeight: "400",color:'black' }}>{shipments?.payment?.service_fee ? `- $`+ shipments?.payment?.service_fee.toFixed(2) : '-----'}</a>
          </div>
          <div style={{...styles.breadcrumb,display: 'flex', flexDirection: 'row', justifyContent: 'space-between',backgroundColor:'rgb(139,139,139,0.1)', padding:'5px',  marginTop:'10px'}}>
        <a style={{ ...styles.dashboardTxt, fontSize: "16px", 
    fontFamily: "Montserrat",
    fontWeight: "400",color:'black' }}>Total Ex GST</a>

<a style={{ ...styles.dashboardTxt, fontSize: "16px",
    fontFamily: "Montserrat",
    fontWeight: "400",color:'black' }}>{ shipments?.payment?.total_exc_gst ? `$`+shipments?.payment?.total_exc_gst.toFixed(2) : '-----'}</a>
          </div>
          <div style={{...styles.breadcrumb,display: 'flex', flexDirection: 'row', justifyContent: 'space-between',backgroundColor:'rgb(139,139,139,0.1)', padding:'5px',  marginTop:'10px'}}>
        <a style={{ ...styles.dashboardTxt, fontSize: "16px", 
    fontFamily: "Montserrat",
    fontWeight: "400",color:'black' }}>+GST</a>

<a style={{ ...styles.dashboardTxt, fontSize: "16px",
    fontFamily: "Montserrat",
    fontWeight: "400",color:'black' }}>{ shipments?.payment?.gst ? `$`+shipments?.payment?.gst.toFixed(2) : '-----'}</a>
          </div>
          <div style={{...styles.breadcrumb,display: 'flex', flexDirection: 'row', justifyContent: 'space-between',backgroundColor:'rgb(139,139,139,0.1)', padding:'5px',  marginTop:'10px'}}>
        <a style={{ ...styles.dashboardTxt, fontSize: "16px", 
    fontFamily: "Montserrat",
    fontWeight: "400",color:'black' }}>Total Earnings</a>

<a style={{ ...styles.dashboardTxt, fontSize: "16px",
    fontFamily: "Montserrat",
    fontWeight: "400",color:'black' }}>{ shipments?.payment?.final_amount ? `$`+shipments?.payment?.final_amount.toFixed(2) : '------'}</a>
          </div>
          <div style={{...styles.breadcrumb,display: 'flex', flexDirection: 'row', justifyContent: 'space-between',backgroundColor:'rgb(139,139,139,0.1)', padding:'5px',  marginTop:'10px'}}>
        <a style={{ ...styles.dashboardTxt, fontSize: "16px", 
    fontFamily: "Montserrat",
    fontWeight: "400",color:'black' }}>{`Rubbl to pay`  + ` $ `+shipments?.payment?.final_amount + ` to you on ` +delDate}</a>
    </div>
        {/* </ul> */}
          {/* <Table style={{borderBottom: '12px', borderBottomColor: 'red'}}>
            <tbody>
              {orders.map((obj) => {
                const Date = moment(obj?.schedule?.date).format("DD-MM-YYYY")
                const price = Math.round(obj?.total_price*Math.pow(10,2))/Math.pow(10,2);
                return (
                  <TableOrder
                    mainObj={obj}
                    orderId={`#${obj?.order_id}`}
                    material={obj?.supplier_material?.material?.name}
                    status={"Payment Status"}
                    type={obj?.invoice?.status ? obj?.invoice?.status : 'unpaid'}
                    // totalamount={obj?.}
                    currency={`$` + price}
                    date={Date}
                    orderdetail={"view order details"}
                    color={obj?.invoice?.status === 'paid' ? "rgb(0 197 105)" : "rgb(255,0,26)"}
                    quantity={obj?.quantity+obj?.unit}
                    shipment={obj?.shipments}
                    onViewDetails={() => history.push("/orderHistoryDetails")}
                  ></TableOrder>
                );
              })}
            </tbody>
          </Table> */}
        </Col>
      </Row>
    </Container>
  );
};

const styles = {
  trHeading: {
    backgroundColor: "rgb(109,109,109)",
    fontFamily: "Montserrat",
    fontWeight: "600",
    color: "white",
    borderRadius: "30px",
    marginTop: "10px",
  },
  textHeading: {
    fontSize: "16px",
    fontFamily: "Montserrat",
    fontWeight: "700",
    color: "black",
    textTransform: "none",
  },
  dashboardTxt: {
    fontSize: "14px",
    fontFamily: "Montserrat",
    color: "rgb(139,139,139)",
  },
  jobsTxt: {
    fontSize: "14px",
    fontFamily: "Montserrat",
    textDecoration: "underline",
    fontWeight: "500",
  },
  breadcrumb: {
    padding: 0,
  },
  searchJobConatiner: {
    flexDirection: "row",
    justifyContent: "space-between",
    display: "flex",
    paddingTop: "10px",
    backgroundColor: "rgb(255,255,255)",
  },
  searchJobScreen: {
    width: "300px",
    height: "28px",
    backgroundColor: "white",
    border: "1px solid rgb(223,223,223)",
  },
  searchIcon: {
    paddingLeft: "3px",
  },

  searchJobInput: {
    border: "0px solid",
    outline: "none",
    height: "17px",
    fontSize: "14px",
    fontFamily: "Montserrat",
    color: "rgb(223,223,223)",
  },
  dropdownStyle: {
    width: "30px",
    height: "30px",
    justifyContent: "flex-end",
  },
  modalTextStyle: {
    color: "rgb(109, 109, 109)",
    fontSize: "13px",
    fontWeight: "500",
    opacity: "60",
    fontFamily: "Montserrat",
  },
  backBtnStyle: {
    color: "rgb(0, 118, 255)",
    border: "1px solid rgb(223, 223, 223)",
    fontSize: "16px",
    fontWeight: "600",
    fontFamily: "Montserrat",
  },
  myModal: {
    width: "100px",
  },
  modalHeader: {
    borderBottom: "none",
  },
  modalFooter: {
    borderTop: "none",
  },
};

export default OrderHistoryDetails;
