import _ from 'lodash';

export function isValidUserName(userName) {
  var un = /^(?=[a-zA-Z0-9._ ]{4,30}$)(?!.*[_.]{2})[^_.].*[^_.]$/;
  return un.test(userName);
}

export function isValidName(name) {
  var un = /^[a-zA-Z ]+$/;
  return un.test(name);
}

export function isValidPassword(password) {
  // var ps = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&])[A-Za-z\d#@$!%*?&]{8,}$/;
  // return ps.test(password);
  if (password.length < 6 || password.length > 15)
    return false
  else
    return true
}

export function isValidEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function isValidDigit(digit) {
  var de = /^[0-9]*$/;
  return de.test(digit);
}



export const createUrlParams = (data) => {
    const ret = [];
    for (let d in data) {
      if (_.isObject(data[d]) || _.isArray(data[d])) {
        for (let arrD in data[d]) {
          ret.push(`${encodeURIComponent(d)}[]=${encodeURIComponent(data[d][arrD])}`)
        }
      } else if (_.isNull(data[d]) || _.isUndefined(data[d])) {
        ret.push(encodeURIComponent(d))
      } else {
        ret.push(`${encodeURIComponent(d)}=${encodeURIComponent(data[d])}`)
      }
    }
    return ret.join('&');
  }