import { createAsyncThunk } from '@reduxjs/toolkit';
import { TRUCK_LIST, ADD_TRUCK_TYPE, TRUCK_TYPE, EDIT_VEHICLE, DELETE_VEHICLE, ADD_VEHICLE_IN_TRANSPORT } from "../actionTypes"
import { truckListApi, addTruckTypeApi, truckTypeApi, editVehicleApi, deleteVehicleApi, addVehicleInTransportApi } from 'services';

export const getTruckList = createAsyncThunk(TRUCK_LIST, async () => {
    const response = await truckListApi();
    return response.data;
});
export const getTruckType = createAsyncThunk(TRUCK_TYPE, async () => {
    const response = await truckTypeApi();
    return response.data;
});

export const addTruckType = createAsyncThunk(ADD_TRUCK_TYPE, async (payload) => {
    try {
        const response = await addTruckTypeApi(payload);
        return response.data;
    } catch (error) {
        return error.response.data
    }

});

export const addVehicleInTransport = createAsyncThunk(ADD_VEHICLE_IN_TRANSPORT, async payload => {
    try {
        const response = await addVehicleInTransportApi(payload);
        return response.data;
    } catch (error) {
        return error.response.data
    }
})

export const editVehicle = createAsyncThunk(EDIT_VEHICLE, async (payload) => {
    try {
        const response = await editVehicleApi(payload);
        return response.data;
    } catch (error) {
        return error.response.data
    }

});

export const deleteVehicle = createAsyncThunk(DELETE_VEHICLE, async (vehicleId) => {
    try {
        const response = await deleteVehicleApi(vehicleId);
    } catch (error) {

    }
});



// export const getVehicleType = createAsyncThunk(VEHICLE_TYPE, async () => {
//     const response = await vehicleTypelist();
//     return response.data;
// });