
// export default VehicleComponent
import React from "react";
import Table from "react-bootstrap/Table";
import weight from "../../../assets/img/weight.png";
import arrow from "../../../assets/img/arrowImg.png";
import phone from "../../../assets/img/phone.png";
import date from "../../../assets/img/date.png";
import timing from "../../../assets/img/timing.png";
// import '../views/jobs/jobs.css'
import { useHistory, Link } from "react-router-dom";
import truck from "../../../assets/img/truck.png";
import "../../vehicleManagement/vehicleManagement.css";
import { MdModeEdit } from 'react-icons/md';
import { MdDeleteForever } from 'react-icons/md';
import "../../driverManagement/driverManagement.css"
import Button from 'react-bootstrap/Button';

const styles = {
  trHeading: {
    backgroundColor: "rgb(109,109,109)",
    // fontSize: "15px",
    fontFamily: "Montserrat",
    fontWeight: "600",
    color: "white",
    borderRadius: "30px",
    marginTop: "10px",
    // width: '800px',
  },
  textHeading: {
    fontSize: "12px",
    fontFamily: "Montserrat",
    fontWeight: "700",
    color: "white",
    textTransform: "none",
  },
  tableRowShaddow: {
    boxShadow: "1px 1px 1px 1px rgba(0, 126, 229, 0.2)",
    // backgroundColor: "rgb(255,255,255)",
    borderRadius: "10px",
    marginBottom: "1em",
  },
  tdView: {
    fontSize: "12px",
    fontFamily: "Montserrat",
    // fontWeight: "600",
    color: "rgb(23,32,63)",
    display: 'flex',
    flexDirection: "row",

    // marginTop: 7,
    // marginBottom: 7
  },
  weightStyle: {
    width: "13px",
    height: "13px",
  },
  statusViewContainer: {
    width: "100px",
    height: "7px",
    backgroundColor: "rgb(191, 190, 190)",
    // marginTop: "7px",
  },
  buttonStyle: {
    width: "70px",
    height: "40px",
    // backgroundColor: "rgb(0, 126, 229)",
    // marginTop: "7px",
    borderRadius: "7px",
    border: "none",
    color: "white",
    fontSize: "11px",
    fontWeight: "700",
    marginLeft: "18px",
  },
  table: {
    border: "1px solid #CCC",
    borderCollapse: "collapse",
  },
  driverImg: {
    width: "50px",
    height: "50px",
    borderRadius: '25px',
    backgroundColor: "rgb(183,183,183)"
  }
};

const DriverTable = ({
  heading,
  handleShow,
  backgroundColor,
  title,
  fontSize,
  truckType,
  handledelShow,
  driver,
  handleEditButton,
  handleShowDeleteModal
}) => {

  let history = useHistory();
   const { name, status, mobile, image, document, id } = driver || {}
  const { number } = document || {}

  return (
    <>
      <tbody className="p-0 mt-3">
        <tr style={styles.tableRowShaddow} className="pt-3">
          <td>
            <div style={{ ...styles.driverImg }}>
              <img src={image} alt="" style={{ ...styles.driverImg }} />
            </div>
          </td>
          <td>
            <div
              style={{ ...styles.tdView, paddingTop: "5px" }}
            >
              <span className="truckTxt">{name}</span>
            </div>
          </td>
          <td>
            <div
              style={{ ...styles.tdView, paddingTop: "5px", width: "100px" }}
            >
              <span className="truckTxt">{driver?.driver_vehicle?.vehicle?.vehicle_type?.name}</span>
            </div>
          </td>
          <td>
            <div style={{ ...styles.tdView, width: '130px' }}>
              <span>{number}</span>
            </div>
          </td>
          <td>
            <div style={{ ...styles.tdView, width: '120px' }}>
              <span>{mobile}</span>
            </div>
          </td>
          <td>
            <div style={{ ...styles.tdView, width: '90px' }}>
              <span>{driver?.driver_vehicle?.vehicle?.rego}</span>
            </div>
          </td>
          <td>
            <div style={{ flexDirection: "row", display: "flex", width: '80px', paddingTop: '5px' }}>
              {
                status ?
                  <div
                    style={{
                      ...styles.statusViewContainer,
                      //   marginLeft: "5px",
                      backgroundColor: "rgb(255,111,26)",
                    }}
                  />
                  :
                  <div
                    style={{ ...styles.statusViewContainer, marginLeft: "5px" }}
                  />
              }
              <div
                style={{ ...styles.statusViewContainer, marginLeft: "5px" }}
              />
            </div>
            <div>
              <span style={styles.tdView}>{status}</span>
            </div>
          </td>
          <td>
            <div style={{ ...styles.tdView, width: '80px' }}>
              {/* <Link to="/admin/track/track"> */}
              <span
                style={{
                  // ...styles.buttonStyle,
                  // backgroundColor: "rgb(255,111,26)",
                  fontSize: "13px",
                  color: "rgb(255,111,26,0.7)",
                  textDecoration:'underline',
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                }}
                onClick={(e) => {
                  e.preventDefault()
                  handleShow(driver)
                }}
              >
                {"Quick View"}
              </span>
              {/* </Link> */}
            </div>
          </td>
          <td>
            <div style={{ marginLeft: '20px' }}>
              {/* <button
                style={{ borderWidth: 0, backgroundColor: 'rgb(246,246,247)' }}
                onClick={() => handleEditButton(id)}
              > */}
              {/* <MdModeEdit color="rgb(0,126,229)" size={32} className="dustbin" /> */}
              <Link
                style={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                to={
                  {
                    pathname: "/AddDriver",
                    search: `?driver=${driver.id}`,
                    state: driver
                  }}
              >
                {/* <span className="add_vehicle_text">Add Vehicle</span> */}
                <MdModeEdit
                  color={"rgb(0,129,229)"}
                  size={23}
                  className="mt-1"
                // onClick={() => history.push(
                //   {
                //     pathname: "/AddDriver",  
                //     search: `?driver=${driver.id}`,
                //     state: driver

                //   })}
                />
              </Link>
            </div>
          </td>
          <td>
            <div style={{ ...styles.tdView, marginLeft: '10px' }}>
              <MdDeleteForever
                color="rgb(255,72,91)"
                size={32}
                className="dustbin"
                onClick={(e) => {
                  e.preventDefault()
                  handleShowDeleteModal(driver)
                }}
              />
            </div>
          </td>
        </tr>
      </tbody>

      {/* </table> */}
      {/* </div> */}
    </>
  );
};

export default DriverTable;
