import { createSlice } from '@reduxjs/toolkit';
import { authenticate, getNotificationList, getUserProfile, fireMarkAllAsRead, getNotificationStatus, updateNotificationStatus } from './actions';

const slice = createSlice({
    name: 'auth',
    initialState: {
        token: null,
        user: '',
        // data: {},
        isLoggedIn: false,
        notificationList: [],
        userDetails: {
            name: '',
            businessName: '',
            businessEmail: '',
            businessMobileNumber: '',
            address: '',
            postcode: '',
            image: '',
            abn: '',
            id:''
        },
        userSettings: {
            notification: '',
        },
        up: ''

    },
    reducers: {
        setUserDetails: (state, action) => {
            state.token = action?.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(authenticate.fulfilled, (state, action) => {
            if (action?.payload?.data?.user?.company?.company_type?.slug === "transporter") {
                // console.log("action?.payload?.data?.user?.company",action?.payload?.data);
                state.token = action?.payload?.data?.accessToken;
                state.user = action?.payload?.data?.user
                state.isLoggedIn = action?.payload?.success;
                // state.userId = action?.payload?.data?.user?.id
            }
            else{
                alert("unauthorized access")
            }
        });
        builder.addCase(getUserProfile.fulfilled, (state, action) => {
            if (action?.payload?.success) {
                let data = action?.payload?.data;
                // console.log("data123456789",data);
                // state.data =  action?.payload?.data;
                state.userDetails.name = data?.name;
                state.userDetails.businessName = data?.company?.name;
                state.userDetails.abn = data?.company?.abn;
                state.userDetails.image = data?.company?.image;
                state.userDetails.businessMobileNumber = data?.mobile;
                state.userDetails.businessEmail = data?.email;
                state.userDetails.postcode = data?.postcode;
                state.userDetails.address = data?.address;
                state.userDetails.id = data?.id
                // const List = JSON.parse(JSON.stringify(state.user))
                // console.log("user",state.data);

            }
        });
        builder.addCase(getNotificationList.fulfilled, (state, action) => {
            if (action?.payload?.success) {
                let data = action?.payload?.data;
                state.totalUnread = action?.payload?.totalUnread;
                state.notificationList = [...state.notificationList, ...data];
            }
        });
        builder.addCase(fireMarkAllAsRead.fulfilled, (state, action) => {
            if (action?.payload?.success) {
                state.totalUnread = 0;
                state.notificationList = [];
            }
        });
        builder.addCase(getNotificationStatus.fulfilled, (state, action) => {
            if (action?.payload?.success) {
                console.log("action?.payload?.data",action?.payload?.data?.notification);

                let data = action?.payload?.data;
                state.userSettings.notification =  data?.notification;
                // var status = action?.payload?.data?.notification === 0 ? false : true
                // console.log("status reducer mein",status);
                // state.userSettings.notification = status
                // console.log("state.userSettings.notification",state.userSettings.notification);

            }
        });
        builder.addCase(updateNotificationStatus.fulfilled, (state, action) => {
            if (action?.payload?.success) {
                state.userSettings = action?.payload?.data
            }
        });
    },
});

export const { setUserDetails } = slice.actions;
export default slice.reducer;
