/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.1  
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component, useState } from "react";
import { useLocation, NavLink } from "react-router-dom";

import { Button, Nav } from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import logo from "assets/img/reactlogo.png";
import dropdownIcon from "assets/img/dropdown.png";
import './sidebar.css'
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    console.log('totally custom!'),
  );
  const [toggleDropdown, setToggleDropdown] = useState(false)

  const openManagement = () => {
    setToggleDropdown(!toggleDropdown)
  }
  return (
    <div onClick={decoratedOnClick} style={{}}>
      <button type="button" onClick={openManagement}
        style={{
          flexDirection: "row",
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          height: "50px",
          alignItems: "center",
          backgroundColor: "transparent",
          border: "0px",
          paddingLeft: '0px',
        }}>
        <span className="managementStyle">Management</span>
        <img width={"15px"} height={"10px"} src={require("assets/img/dropdown.png")} style={{ transform: toggleDropdown ? 'rotate(180deg)' : 'rotate(360deg)', marginRight: '10px'}} alt="..." />
      </button>
    </div>
  );
}

function Sidebar({ color, image, routes }) {
  const location = useLocation();
  const [isActive, setActive] = useState(true);

  const toggleClass = () => {
    setActive(isActive);
  };
  // const activeRoute = (routeName) => {
  //   return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  // };

  return (
    // <div className="sidebar" data-image={image} data-color={"rgb(0,126,229)"}>
    //   <div
    //     className="sidebar-background"
    //     style={{
    //       backgroundColor: 'rgb(0,126,229)'
    //       // backgroundImage: "url(" + image + ")"
    //     }}
    //   />
    //   <div className="sidebar-wrapper" style={{ backgroundColor: 'rgb(0,126,229)' }}>
    //     <div className="logo d-flex align-items-center justify-content-start">
    //       {/* <a
    //         href="https://www.creative-tim.com?ref=lbd-sidebar"
    //         className="simple-text logo-mini mx-1"
    //       > */}
    //       {/* </a> */}
    //       {/* <a className="simple-text" href="http://www.creative-tim.com">
    //         Creative Tim
    //       </a> */}
    //       <a className="logo-img" >
    //         <img src={require("assets/img/rubbl.png")} alt="..." />
    //       </a>
    //     </div>
    //     <Nav>
    //       {routes.map((prop, key) => {
    //         if (!prop.redirect)
    //           return (
    //             <li
    //               className={
    //                 prop.upgrade
    //                   ? "active active-pro"
    //                   : activeRoute(prop.layout + prop.path)
    //               }
    //               key={key}
    //             >
    //               <NavLink
    //                 to={prop.layout + prop.path}
    //                 className="nav-link"
    //                 activeClassName="active"
    //               >
    //                 <i className={prop.icon} />
    //                 <p style={{fontSize:"14px"}}>{prop.name}</p>
    //               </NavLink>
    //             </li>
    //           );
    //         return null;
    //       })}
    //     </Nav>
    //   </div>
    // </div>
    <div className="sidebar" data-image={image} data-color={"rgb(0,126,229)"}>
      <div
        className="sidebar-background"
        style={{
          backgroundColor: 'rgb(0,126,229)'
          // backgroundImage: "url(" + image + ")"
        }}
      />
      <div className="sidebar-wrapper" style={{ backgroundColor: 'rgb(0,126,229)' }}>
        {/* <div className="logo d-flex align-items-center justify-content-start"> */}
        {/* <a
          href="https://www.creative-tim.com?ref=lbd-sidebar"
          className="simple-text logo-mini mx-1"
        > */}
        {/* </a> */}
        {/* <a className="simple-text" href="http://www.creative-tim.com">
          Creative Tim
        </a> */}
        <div className="m-3 justify-content-center align-items-center" >
          <a className="logo-img" >
            <img style={{width:'200px'}} src={require("assets/img/rubblLogo.png")} alt="..." />
          </a>
        </div>

        {/* </div> */}
        {/* <Nav>
        {routes.map((prop, key) => {
          if (!prop.redirect)
            return (
              <li
                className={
                  prop.upgrade
                    ? "active active-pro"
                    : activeRoute(prop.layout + prop.path)
                }
                key={key}
              >
                <NavLink
                  to={prop.layout + prop.path}
                  className="nav-link"
                  activeClassName="active"
                >
                  <i className={prop.icon} />
                  <p>{prop.name}</p>
                </NavLink>s
              </li>
            );
          return null;
        })}
      </Nav> */}
        <div style={{marginLeft:'15px'}}>
          <Nav>
            <div className="sideBarAlign">
              <NavLink
                to="/"
                className={`${location.pathname === '/' ? "selectedDashboardContainer" : "dashboardContainer"} text-decoration-none`}
              >
                <p className="sidebarTxt">Dashboard</p>
              </NavLink>
            </div>
            <div className="sideBarAlign">

              <NavLink
                to="/jobs"
                className={`${location.pathname === '/jobs' ? "selectedDashboardContainer" : "dashboardContainer"} text-decoration-none`}
              >
                <p className="sidebarTxt">Jobs</p>
              </NavLink>
            </div>
            <div className="sideBarAlign">

              <NavLink
                to="/my-loads"
                className={`${location.pathname === '/my-loads' ? "selectedDashboardContainer" : "dashboardContainer"} text-decoration-none`}
              >
                <p className="sidebarTxt">My Loads</p>
              </NavLink>
            </div>
            <div className="subSideBarAlign">
              <Accordion>
                <Card style={{ backgroundColor: "rgb(0, 126, 229)", border: "0px" }}>
                  <Card.Header style={{ backgroundColor: "rgb(0, 126, 229)", }}>
                    <CustomToggle eventKey="0">Management</CustomToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body style={{marginTop:'10px'}}>
                      <NavLink
                        to="/bank-details-and-invoices"
                        className={`${location.pathname === '/bank-details-and-invoices' ? "selectedDashboardContainer" : "dashboardContainer"} text-decoration-none`}
                      >
                        <p className="subSideBarAlign">Bank Details & Payments</p>
                      </NavLink></Card.Body >
                  </Accordion.Collapse>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body > <NavLink
                      to="/vehicle-management"
                      className={`${location.pathname === '/vehicle-management' ? "selectedDashboardContainer" : "dashboardContainer"} text-decoration-none`}
                    >
                      <p className="subSideBarAlign">Vehicle Management</p>
                    </NavLink></Card.Body>
                  </Accordion.Collapse>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body ><NavLink
                      to="/driver-management"
                      className={`${location.pathname === '/driver-management' ? "selectedDashboardContainer" : "dashboardContainer"} text-decoration-none`}
                    >
                      <p className="subSideBarAlign">Driver Management</p>
                    </NavLink></Card.Body>
                  </Accordion.Collapse>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body > <NavLink
                      to="/order-history"
                      className={`${location.pathname === '/order-history' ? "selectedDashboardContainer" : "dashboardContainer"} text-decoration-none`}
                    >
                      <p className="subSideBarAlign">Order History & Earnings</p>
                    </NavLink></Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
          </Nav>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
