import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getTruckType } from "store/vechileType/actions";
import weight from "../../assets/img/weight.png";
import "../../views/Track.css";

const OrderDetail = (props) => {
  const {
    materialValue,
    materialQty,
    truckType,
    onSave,
    list,
    time,
    date,
    status,
    truckName,
    payload,
    level,
  } = props;
  const dispatch = useDispatch();
  const [vehicleNo, setVehicleNo] = useState("");
  const [saved, setSaved] = useState('');

  const filteredList = list?.filter((item) => {
    if (
      // payload <= item?.vehicle_type?.payload &&
      level >= item?.vehicle_type?.level
    ) {
      return true;
    }
  });

  useEffect(() => {
    dispatch(getTruckType())
  }, []);

  const onClickSave = () => {
    setSaved(filteredList[vehicleNo]?.rego)
    onSave(filteredList[vehicleNo])
  }

  const maxLengthArray =  filteredList?.map(i => {
    // const driverN = i?.vehicle_driver ? i?.vehicle_driver?.driver?.name : 'kajal hirani'
    // const myArray = driverN.split(" ");
    // console.log("myArray",myArray[0]);
    // const arr = myArray[1].charAt(0);
    // console.log("arr",arr);
    // const Dname =  i?.vehicle_driver ? myArray[0] +` ` +arr+`.` : '--'
    let text =  `${i?.rego} | ${i?.vehicle_type?.axles} Axles | ${i?.gvm}/1000t  Gvm | ${i?.payload}/1000t Payload`
    return text.length
  }) || [];

  const maxLength = maxLengthArray?.length > 0 ? Math.max(...maxLengthArray) : 0

  return (
    <tr style={styles.tableFirstValue}>
      <td>{materialValue}</td>
      <td>
        <div style={{ flexDirection: "row" }}>
          <img src={weight} alt="" style={styles.weightStyle} />
          <span style={styles.materialQtyTxt}>{materialQty}</span>
        </div>
      </td>
      <td>{truckType}</td>
      <td>
        {(status && !saved) ? (
          <select
            style={styles.customSelect}
            value={vehicleNo}
            onChange={(e) => setVehicleNo(e.target.value)}
          >
            <option
              value="Select"
              selected={location.pathname !== "/jobs/details" ? true : false}
            >
              Select Vehicle
            </option>
                    <option disabled>{'-'.repeat(maxLength)}</option>
            {location.pathname !== "/jobs/details"
              ? filteredList?.map((i, ind) => {
                // console.log("i",i);
                // const driverN = i?.vehicle_driver ? i?.vehicle_driver?.driver?.name : 'kajal hirani'
                // const myArray = driverN.split(" ");
                // console.log("myArray",myArray[0]);
                // const arr = myArray[1].charAt(0);
                // console.log("arr",arr);
                // const Dname =  i?.vehicle_driver ? myArray[0] +` ` +arr+`.` : '---'

                  return (
                    <>
                    <option key={ind.toString()} className="options" value={ind}>
                      {(i?.vehicle_driver ? (i?.vehicle_driver?.driver?.name.length > 10 ? i?.vehicle_driver?.driver?.name : i?.vehicle_driver?.driver?.name)  : '-----') +` | `+i?.rego+ ` | `+i?.vehicle_type?.axles+` Axles | `+i?.gvm/1000+`t  Gvm | `+i?.payload/1000+`t Payload `}
                    </option>
                    <option disabled>{'-'.repeat(maxLength)}</option>
                    </>
                  );
                })
              : null}
          </select>
        ) : (
          truckName || saved
        )}
      </td>
      <td>{time + ` @ ` + date}</td>

      <td>
        <div>
          {(status && location.pathname !== "/jobs/details" && !saved) ? (
            <button onClick={onClickSave}>
              Save
            </button>
          ) : null}
        </div>
      </td>
    </tr>
  );
};

const styles = {
  tableFirstValue: {
    fontSize: "12px",
    color: "rgb(109,109,109)",
  },
  weightStyle: {
    width: "20px",
    height: "17px",
  },
  materialQtyTxt: {
    fontSize: "12px",
    color: "rgb(109,109,109)",
    fontFamily: "Montserrat",
  },
  customSelect: {
    color: "rgb(114, 110, 110)",
    fontSize: "12px",
    fontFamily: "Montserrat",
    fontWeight: 400,
    border: "none",
    boxShadow: "1px 1px 1px 1px rgba(0,0,0, 0.16)",
    borderRadius: "3px",
    width:'90px'
  },
  pickerStyle: {
    flexDirection: "row",
    justifyContent: "center",
    border: "none",
    boxShadow: "0px 0.5px 0.5px 0.5px  rgba(0,0,0,0.2)",
    padding: 0,
    alignItems: "center",
    alignContent: "center",
  },
  buttonTrackContainerStyle: {
    width: "30px",
    height: "10px",
    borderRadius: "7px",
    border: "none",
    color: "white",
    fontSize: "15px",
    fontWeight: "600",
    backgroundColor: "rgb(255,111,26)",
  },
};

export default OrderDetail;
