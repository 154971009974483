import React, {
  Component,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation, Link } from "react-router-dom";
import { IoChevronDownOutline } from "react-icons/io5";
import { IoMdPin, IoIosSearch } from "react-icons/io";
import _ from "lodash";
// import { useSelector, useDispatch } from 'react-redux';
import { DropdownItem } from "reactstrap";
import {
  Navbar,
  Container,
  Nav,
  Dropdown,
  Button,
  Modal,
  Spinner,
  NavItem,
  NavLink,
} from "react-bootstrap";
import { getNotificationList, fireMarkAllAsRead, getNotificationStatus } from "store/auth/actions";
import NotificationContent from "components/NotificationContent/NotificationContent";
import DrawerContent from "components/DrawerContent/DrawerContent";
import SwitchToggle from "components/SwitchToggle/SwitchToggle";
import routes from "routes.js";
import ProfileImg from "../../assets/img/profile.png";
import Bell from "../../assets/img/bell.png";
import user from "../../assets/img/jobs.png";
import camera from "../../assets/img/camera.png";
import logout from "../../assets/img/logout.png";
import { setUserDetails } from "../../store/auth/slice";
import "../../assets/css/demo.css";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "store/auth/actions";
import { GoBell } from "react-icons/go";
import { AiOutlineBell } from "react-icons/ai";
import { updateNotificationStatus } from "store/auth/actions";

const styles = {
  userTxt: {
    fontSize: "17px",
    fontFamily: "Montserrat",
    color: "black",
    fontWeight: "450",
    // height:'20px'
  },
  profileImgStyle: {
    width: "25px",
    height: "25px",
    borderRadius: "12.5px",
    // marginTop: "8px",
  },
  newCastleTxt: {
    color: "rgb(255, 111, 26)",
    fontFamily: "Montserrat",
    letterSpacing: 0.95,
    fontWeight: 500,
    paddingLeft: "5px",
  },
};

function Header() {
  const listInnerRef = useRef();
  const location = useLocation();
  const dispatch = useDispatch();
  const { notificationList, totalUnread, userSettings, up } = useSelector(
    (state) => state.authReducer
  );

  const userSet = useSelector((state) => state.authReducer.userSettings);
  console.log("statusFromReducer",userSet);
  const [status,setStatus] = useState('')

  const [state, setState] = useState({
    offset: 0,
    limit: 10,
    notifyLoader: false,
    statusSwitch: false,
    pushNotificationSwitch: status,
  });

  console.log("userSettings",state.pushNotificationSwitch,'local value');//local value
  console.log("userSettings",userSet?.notification,'reducer value');//reducervalue
  console.log("userSet?.notification === 1 ?  true : fals",userSet?.notification === 1 ?  true : false);

  useEffect(() => {
    getNotificationListData(state.offset, state.limit);
  }, [state.limit, state.offset]);

  useEffect(() => {
    dispatch(getNotificationStatus()).then((response) =>{
      console.log("response", response)
      setState((prev) => ({ ...prev, pushNotificationSwitch: userSet?.notification === 1 ? true : false }));
  })},
  [userSet?.notification]);
  
  const getNotificationListData = (offset, limit) => {
    setState((prev) => ({ ...prev, notifyLoader: true }));
    let queryParams = `?readState=unread&offset=${offset}&limit=${limit}`;
    dispatch(getNotificationList(queryParams)).then((res) => {
      setState((prev) => ({ ...prev, notifyLoader: false }));
    });
  };

  let newNotificationList = [...notificationList];

  let newNotificationListData = _.groupBy(newNotificationList, (item) => {
    var REFERENCE = moment();
    var TODAY = REFERENCE.clone().startOf("day");
    var YESTERDAY = REFERENCE.clone().subtract(1, "days").startOf("day");

    if (moment(item?.updated_at).isSame(TODAY, "d")) {
      return "Today";
    } else if (moment(item?.updated_at).isSame(YESTERDAY, "d")) {
      return "Yesterday";
    } else {
      return "Older";
    }
  });

 


  const mobileSidebarToggle = (e) => {
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    node.onclick = function () {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle("nav-open");
    };
    document.body.appendChild(node);
  };

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (
        scrollTop + clientHeight === scrollHeight &&
        notificationList?.length <= totalUnread &&
        !state.notifyLoader
      ) {
        setState((prev) => ({
          ...prev,
          offset: prev.limit + 1,
          limit: prev.limit + 10,
          notifyLoader: true,
        }));
      }
    }
  };

  const markAllAsRead = () => {
    dispatch(
      fireMarkAllAsRead({
        all: true,
      })
    );
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const onPressLogout = () => {
    localStorage.removeItem("token");
    dispatch(setUserDetails(null));
  };

  const toggleSwitchButton = (mainKey) => {
    setState((prev) => ({ ...prev, [mainKey]: !prev[mainKey] }));
    let data = {
      notification: !state.pushNotificationSwitch === false ? 0 : 1
    }
    dispatch(updateNotificationStatus(data)).then((res) => {
      // setState((prev) => ({ ...prev, pushNotificationSwitch: res.payload.data.notification }));
    })
  };

  

  useEffect(() => {
    dispatch(getUserProfile()).then((response) =>
      console.log("response", response)
    );
  },[]);

  const [toggleswitch, setToggleswitch] = useState(true);
  const userDetails = useSelector((state) => state.authReducer.userDetails);

  return (
    <Navbar
      bg="light"
      expand="lg"
      style={{
        paddingTop: 0,
        paddingBottom: 0,
        backgroundColor: "red",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Container
        fluid
        style={{ flexDirection: "row", display: "flex" }}
        className="d-flex justify-content-space-between align-items-center ml-2 ml-lg-0"
      >
        <Navbar.Collapse
          id="basic-navbar-nav p-0"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <Nav className="nav mr-auto" navbar>
            <Nav.Item>
              <Nav.Link data-toggle="dropdown" className="m-0 p-0">
                <div
                  style={{
                    border: "1px solid gray",
                    width: "150px",
                    height: "40px",
                    borderRadius: "10px",
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    alignContent: "center",
                    paddingLeft: "5px",
                  }}
                >
                  <IoMdPin size={22} color={"rgb(0,126,229)"} />
                  <span style={styles.newCastleTxt}>Newcastle</span>
                </div>
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink className="m-0 p-0">
                <Dropdown as={Nav.Item}>
                  <Dropdown.Toggle
                    aria-expanded={true}
                    aria-haspopup={true}
                    as={Nav.Link}
                    data-toggle="dropdown m-0"
                    // style={{width:'500px'}}
                  >
                    {/* <span style={styles.userTxt} className="p-0 m-0">
                  {userDetails?.businessName} */}
                    <div>
                      <IoChevronDownOutline
                        size={20}
                        color={"black"}
                        className="m-0 p-0"
                        // style={{ marginTop: "10px" }}
                      />
                    </div>
                    {/* </span> */}
                  </Dropdown.Toggle>
                  <Nav.Link className="m-0">
                    <div
                      className="m-0 p-0 mt-0"
                      style={{ ...styles.userTxt, height: "20px" }}
                      src={""}
                      alt=""
                      // style={{ ...styles.profileImgStyle }}
                    >
                      <span className="mb-3">{userDetails?.businessName}</span>
                    </div>
                  </Nav.Link>
                  <Nav.Link className="m-0">
                    <div>
                      <img
                        src={
                          userDetails?.image ? userDetails?.image : ProfileImg
                        }
                        alt=""
                        style={{ ...styles.profileImgStyle }}
                      />
                    </div>
                  </Nav.Link>
                  <Nav.Link className="m-0" onClick={handleShow}>
                    <div className="m-0">
                      <AiOutlineBell
                        style={{ ...styles.profileImgStyle }}
                        color={"black"}
                        size={20}
                      />
                      {notificationList?.length > 0 ? (
                        <div
                          style={{
                            position: "absolute",
                            width: "10px",
                            height: "10px",
                            backgroundColor: "red",
                            top: 17,
                            right: 20,
                            bottom: 0,
                            borderRadius: "5px",
                          }}
                        ></div>
                      ) : null}
                    </div>
                  </Nav.Link>
                  <Dropdown.Menu
                    aria-labelledby="navbarDropdownMenuLink w-600"
                    role="menu"
                    style={
                      {
                        // width: "60px",
                        // height: "530px",
                      }
                    }
                  >
                    <Dropdown.Item
                      as={Link}
                      // to="/jobs"
                    >
                      <div className="profileCompleteContainer mt-1">
                        <div className="profileContainer pt-1 pe-3">
                          <img src={user} className="user-img" alt=""></img>
                          {/* <img src={camera} className="imageContent" alt=""></img> */}
                          <p className="profileTxt">
                            {userDetails?.businessName}
                          </p>
                        </div>
                      </div>
                    </Dropdown.Item>
                    <div className="divider p-0 mt-0 m-0"></div>
                    <Dropdown.Item as={Link} to="/profile">
                      <DrawerContent drawerTxt={"Profile"} to={"/Profile"} />
                    </Dropdown.Item>
                    <div className="divider p-0 m-0"></div>
                    <SwitchToggle
                      className="mt-3"
                      drawerTxt={"Push Notification"}
                      toggleSwitch={state.pushNotificationSwitch}
                      onValueChange={() =>
                        toggleSwitchButton("pushNotificationSwitch")
                      }
                    />
                    <div className="divider p-0 m-0"></div>
                    <Dropdown.Item as={Link} to="/HelpSupport">
                      <DrawerContent
                        drawerTxt={"Help & Support"}
                        to={"/HelpSupport"}
                      />
                    </Dropdown.Item>
                    <div className="divider p-0 m-0"></div>
                    <Dropdown.Item as={Link} to="/ChangePassword">
                      <DrawerContent
                        drawerTxt={"Change Password"}
                        to={"/ChangePassword"}
                      />
                    </Dropdown.Item>
                    <div className="divider p-0 m-0"></div>
                    <Dropdown.Item as={Link} to="/About">
                      <DrawerContent drawerTxt={"About"} to={"/About"} />
                    </Dropdown.Item>
                    <DropdownItem>
                      <div style={{ marginTop: "70px" }}>
                        <div className="logout" onClick={onPressLogout}>
                          <Link
                            to="/"
                            style={{
                              border: 0,
                              backgroundColor: "transparent",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={logout}
                              className="logout-img mt-2 ms-2"
                              alt=""
                            ></img>
                            <p className="logoutTxt">Logout</p>
                          </Link>
                        </div>
                        <div className="drawer-bottom-content">
                          <Link
                            style={{ color: "black", textDecoration: "none" }}
                            to="/termsCondition"
                            // target={'_blank'}
                            className={`${
                              location.pathname === "/termsCondition"
                            } text-decoration-none`}
                          >
                            <p style={{ fontSize: "12px" }}>Terms of Service</p>
                          </Link>
                          <Link
                            style={{ color: "black", textDecoration: "none" }}
                            to="/privacyPolicy"
                            // target={'_blank'}
                            className={`${
                              location.pathname === "/privacyPolicy"
                            } text-decoration-none`}
                          >
                            <p style={{ fontSize: "12px" }}>Privacy Policy</p>
                          </Link>
                        </div>
                      </div>
                    </DropdownItem>
                  </Dropdown.Menu>
                </Dropdown>
              </NavLink>
            </NavItem>
          </Nav>
        </Navbar.Collapse>
        <Modal
          className="modal-body modal-header border-top-0-20w"
          show={show}
          onHide={handleClose}
          backdropClassName="modalBackdrop"
          style={{
            border: 0,
            position: "absolute",
            left: "20px",
            backgroundColor: "transparent",
            opacity: 2,
            backdrop: "false",
            borderTopColor: "white",
          }}
        >
          <div>
            <div className="notificationCompleteContainer">
              <div className="notifi">
                <p style={{ paddingTop: "10px" }}>Notifications</p>
              </div>
              <div
                className="notification_scroll"
                onScroll={onScroll}
                ref={listInnerRef}
              >
                {Object.keys(newNotificationListData).map((item, index) => {
                  let mainArray = newNotificationListData[item];
                  return (
                    <>
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          paddingTop: "5px",
                          flex: 1,
                          justifyContent: "space-between",
                        }}
                        className="day_msg"
                      >
                        <h5 className="dayTxt">{item}</h5>
                        {index === 0 && (
                          <h5 className="msgTxt" onClick={markAllAsRead}>
                            Mark all as read
                          </h5>
                        )}
                      </div>
                      {mainArray.map((val) => (
                        <NotificationContent content={val?.message} />
                      ))}
                    </>
                  );
                })}
                {state.notifyLoader && <NotificationContent loader={true} />}
                {notificationList?.length === 0 && !state.notifyLoader && (
                  <div style={{ margin: "20px auto" }}>
                    <h5 className="dayTxt" style={{ textAlign: "center" }}>
                      {"No unread notification message"}
                    </h5>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal>
      </Container>
    </Navbar>
  );
}

export default Header;
