import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { Row, Col, Container } from "react-bootstrap";
import { IoIosSearch, IoIosClose } from "react-icons/io";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "components/CustomTable/CustomTable";
import DropDownToggle from "components/DropDownToggle/DropDownToggle";
import { getJobList, assignOrderToMe } from "store/driver/actions";
import Toast, { MainToastContainer } from "utils/Toast";
import "../assets/css/demo.css";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";

const User = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const jobListData = useSelector((state) => state.driverReducer.jobList);
  const { loadAssumption } = useSelector((state) => state.driverReducer);
  const loadRate = loadAssumption?.[0]?.transporter_commission;
  const location = useLocation();

  const [state, setState] = useState({
    showModalOrderId: "",
    loading: false,
    data: [],
  });

  useEffect(() => {
    getJobListData();
  }, []);

  useEffect(() => {
    if (jobListData) {
      setState((prev) => ({ ...prev, data: jobListData }));
    }
  }, [jobListData]);

  const getJobListData = useCallback(() => {
    toggleLoader();
    dispatch(getJobList()).then(() => toggleLoader());
  }, []);

  const hitAssignMe = () => {
    if (state.showModalOrderId) {
      let newData = state.data?.filter(
        (item) => item.order_id !== state.showModalOrderId
      );
      setState((prev) => ({ ...prev, data: newData }));

      dispatch(assignOrderToMe({ order_id: state.showModalOrderId })).then(
        (res) => {
          if (res?.payload?.success === true) {
            Toast(res?.payload?.message);
          } else {
            setState((prev) => ({ ...prev, data: jobListData }));
            Toast(res?.payload?.message);
          }
        }
      );
      toggleHandle();
    }
  };

  const toggleHandle = (orderId) => {
    if (orderId) {
      setState((prev) => ({ ...prev, showModalOrderId: orderId }));
    } else {
      setState((prev) => ({ ...prev, showModalOrderId: "" }));
    }
  };
  const toggleLoader = () =>
    setState((prev) => ({ ...prev, loading: !prev.loading }));

  const getFixedDate = (start, end) => {
    let startDate = moment(start).format("D/M/Y");
    let endDate = moment(end).format("D/M/Y");

    if (startDate === endDate) {
      return `${startDate}`;
    } else {
      return `${startDate} to ${endDate}`;
    }
  };

  const getFixedTime = (end) => {
    let startTime = moment(end).subtract(1, "hour").format("h:mma");
    let endTime = moment(end).format("h:mma");

    return `b/w ${startTime} - ${endTime}`;
  };

  const openViewDetail = (item) => {
    history.push("/jobs/details", { state: { item: item } });
  };

  const DataSheet = () => {
    if (state.loading) {
      return (
        <div style={styles.spinnerContainer}>
          <Spinner animation="border" variant="primary" />
        </div>
      );
    }
    return (
      <Row sm={6} xl={12} className="mt-3 pe-2">
        <Col md={12} className="ms-2 ps-3">
          <div className="table-responsive-sm pe-3 me-2">
            <table
              className="table border-none p-0 m-0"
              style={{
                tableLayout: "fixed",
                width: "100%",
              }}
            >
              <thead style={{ borderRadius: "10px" }}>
                <tr style={{ ...styles.trHeading, borderRadius: "20px" }}>
                  <th style={styles.textHeading}>OrderId</th>
                  <th style={styles.textHeading}>Material</th>
                  <th style={styles.textHeading}>Loading</th>
                  <th style={styles.textHeading}>Distance</th>
                  <th style={styles.textHeading}>Unloading</th>
                  <th style={styles.textHeading}>Delivery Date</th>
                  <th style={styles.textHeading}>Earnings</th>
                  <th
                    style={{
                      ...styles.textHeading,
                      color: "rgb(109,109,109)",
                    }}
                  >
                    {"heading"}
                  </th>
                </tr>
              </thead>
              {state.data.map((item) => {
                let totalPrice = item?.total_price;
                let quantity = item?.quantity;
                let rate = item?.supplier_material?.rate;
                let givenLoad = loadRate;
                let loadAssumptionRate = 100 - (givenLoad || 0);
                let mainAmount =
                  ((totalPrice - rate * quantity) * loadAssumptionRate) / 100;
                let amount = mainAmount > 0 ? mainAmount.toFixed(2) : "0.00";
                let time = moment(item?.shipments[0]?.date)
                  .subtract(1, "hour")
                  .format("h:mma");
                let time1 = moment(
                  item?.shipments[item?.shipments?.length - 1]?.date
                ).format("hh: mm a");
                // console.log('givenLoad', loadAssumption)
                // console.log("time",moment(item?.shipments[0]?.date).format("hh:mm a"))
                // console.log("time1",(moment(item?.shipments[item?.shipments?.length - 1]?.date).format("hh: mm a")))

                return (
                  <CustomTable
                    orderId={item?.order_id}
                    materialName={item?.supplier_material?.material?.name}
                    quantity={`${item?.quantity}${item?.unit}`}
                    distance={`${item?.distance || 0}${item?.d_unit}`}
                    loaderName={item?.supplier_material?.supplier?.name}
                    loaderNumber={
                      item?.supplier_material?.supplier?.user?.address
                    }
                    unloaderName={item?.site?.name}
                    unloaderNumber={item?.site?.address}
                    deliveryDate={getFixedDate(
                      item?.shipments?.[0]?.date,
                      item?.shipments?.[item?.shipments?.length - 1]?.date
                    )}
                    deliveryTime={time}
                    deliveryLastTime={time1}
                    startedTime={moment(item?.created_at).fromNow()}
                    backgroundColor={"rgb(0,126,229)"}
                    amount={amount}
                    seeDetails={true}
                    assignMe={true}
                    onPressAssignMe={() => toggleHandle(item?.order_id)}
                    onPressSeeDetails={() => openViewDetail(item)}
                  />
                );
              })}
            </table>
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <Container fluid>
      <ul className="breadcrumb ps-0 ms-0" style={styles.breadcrumb}>
        <li className="p-0">
          <Link to="/" style={{ ...styles.dashboardTxt }}>
            Dashboard
          </Link>
        </li>
        <li>
          <a href="/jobs" style={styles.jobsTxt}>
            Jobs
          </a>
        </li>
      </ul>
      <Row style={{ backgroundColor: "rgb(247,247,247)" }}>
        <Col md="12">
          <div className="d-flex justify-content-between"></div>
          <Row className="m-0 mb-0 p-0" style={{ display: "flex" }}>
            <span className="resultTxt">{state.data.length} Result</span>
          </Row>
          <DataSheet />
        </Col>
      </Row>
      <Modal
        className="modal-mini w-100 mt-5"
        show={state.showModalOrderId}
        onHide={() => toggleHandle()}
      >
        <div style={styles.modalContainer}>
          <div style={styles.customHeader}>
            <Modal.Title className="m-2 font-weight-bold">
              Are you sure?
            </Modal.Title>
            <IoIosClose size={"25px"} onClick={() => toggleHandle()} />
          </div>
          <p style={styles.CustomTitle}>
            You want to Assign yourself to this job?
          </p>
        </div>
        <div
          className="d-flex flex-row justify-content-end"
          style={{ paddingBottom: "10px" }}
        >
          <button
            variant="none"
            onClick={() => toggleHandle()}
            style={styles.backBtnStyle}
          >
            Back
          </button>
          <button
            variant="none"
            onClick={() => hitAssignMe()}
            style={styles.buttonStyle}
          >
            Yes Assign Me
          </button>
        </div>
      </Modal>
      <MainToastContainer />
    </Container>
  );
};

const styles = {
  trHeading: {
    backgroundColor: "rgb(109,109,109)",
    fontFamily: "Montserrat",
    fontWeight: "600",
    color: "white",
    borderRadius: "30px",
    marginTop: "10px",
  },
  textHeading: {
    fontSize: "12px",
    fontFamily: "Montserrat",
    fontWeight: "700",
    color: "white",
    textTransform: "none",
  },
  dashboardTxt: {
    fontSize: "14px",
    fontFamily: "Montserrat",
    color: "rgb(139,139,139)",
  },
  jobsTxt: {
    fontSize: "14px",
    fontFamily: "Montserrat",
    textDecoration: "underline",
    fontWeight: "500",
  },
  breadcrumb: {
    padding: 0,
  },
  searchJobScreen: {
    width: "300px",
    height: "28px",
    backgroundColor: "white",
    border: "1px solid rgb(223,223,223)",
  },
  searchIcon: {
    paddingLeft: "3px",
  },
  searchJobInput: {
    border: "0px solid",
    outline: "none",
    height: "17px",
    fontSize: "14px",
    fontFamily: "Montserrat",
    color: "rgb(223,223,223)",
  },
  modalTextStyle: {
    color: "rgb(109, 109, 109)",
    fontSize: "13px",
    fontWeight: "500",
    opacity: "60",
    fontFamily: "Montserrat",
  },
  backBtnStyle: {
    color: "rgb(0, 118, 255)",
    border: "1px solid rgb(223, 223, 223)",
    fontFamily: "Montserrat",
    backgroundColor: "#fff",
    borderRadius: "7px",
    fontSize: "15px",
    width: "80px",
    height: "30px",
  },
  myModal: {
    width: "100px",
  },
  modalHeader: {
    borderBottom: "none",
  },
  modalFooter: {
    borderTop: "none",
  },
  buttonStyle: {
    backgroundColor: "rgb(0, 126, 229)",
    borderRadius: "7px",
    border: "none",
    color: "white",
    marginLeft: "21px",
    marginRight: "10px",
    borderRadius: "7px ",
    width: "120px",
    height: "30px",
  },
  customHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  CustomTitle: {
    color: "rgb(109, 109, 109)",
    fontSize: "12px",
    fontWeight: "500",
    opacity: "60",
    fontFamily: "Montserrat",
    marginLeft: "10px",
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "10px",
    paddingTop: "8px",
  },
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
};

export default User;
