import React from "react";

const ProfileForm = (props) => {
  const { label_name, placeholder_name, dataSave, value, maxLength } = props;

  return (
    <>
      <div className="formContent-container">
        <div className="form-content">
          <label className="label_name">{label_name} </label>
          <input
            type="txt"
            placeholder={placeholder_name} onChange={dataSave} value={value}
            className="placeholder_name"
            maxLength={maxLength}
          ></input>
        </div>
      </div>
    </>
  );
};

export default ProfileForm;
