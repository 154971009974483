import Dashboard from "views/Dashboard.js";
import Jobs from "views/jobs.js";
import MyLoads from "views/myLoads.js";
import Upgrade from "views/Upgrade.js";
import Track from "views/Track";
import BankDetail from "views/bankDetailAndInvoices/bankDetail";
import VehicleManagement from "views/vehicleManagement/vehicleManagement";
import DriverManagement from "views/driverManagement/driverManagement";
import OrderHistory from "views/orderHistory/OrderHistory";
import Profile from "views/Profile";
import HelpSupport from "views/HelpSupport";
import ChangePassword from "views/ChangePassword";
import About from "views/About";
import TermsCondition from "views/TermsCondition";
import PrivacyPolicy from "views/PrivacyPolicy";
import AddVehicle from "views/AddVehicle";
import AddDriver from "views/AddDriver";
import CancelledJobs from "views/CancelledJobs";
import OrderHistoryDetails from "views/orderHistoryDetails/orderHistoryDetails";

const dashboardRoutes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    upgrade: true,
    path: "/upgrade",
    name: "Upgrade to PRO",
    icon: "nc-icon nc-alien-33",
    component: Upgrade,
  },
  {
    path: "/jobs",
    name: "Jobs",
    component: Jobs,
  },
  {
    path: "/jobs/details",
    name: "Job Details",
    component: Track,
  },
  {
    name: "My Loads",
    path: "/my-loads",
    component: MyLoads,
  },
  {
    path: "/track",
    name: "My Loads",
    component: Track,
  },
  {
    path: "/bank-details-and-invoices",
    name: "Bank Details & Payments",
    component: BankDetail,
  },
  {
    path: "/vehicle-management",
    name: "Vehicle Management",
    component: VehicleManagement,
  },
  {
    path: "/driver-management",
    name: "Driver Management",
    component: DriverManagement,
  },
  {
    path: "/order-history",
    name: "Order History & Earnings",
    component: OrderHistory,
  },
  {
    path: "/profile",
    component: Profile,
  },
  {
    path: "/helpSupport",
    component: HelpSupport,
  },
  {
    path: "/changePassword",
    component: ChangePassword,
  },
  {
    path: "/about",
    component: About,
  },
  {
    path: "/addVehicle",
    name: "My Loads",
    component: AddVehicle,
  },
  {
    path: "/addDriver",
    name: "My Loads",
    component: AddDriver,
  },
  {
    path: "/cancelledJobs",
    name: "My Loads",
    component: CancelledJobs,
  },
  {
    path: "/orderHistoryDetails",
    // name: "Order History Details",
    component: OrderHistoryDetails,
  },
];

export default dashboardRoutes;
