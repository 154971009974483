import axios from 'axios'
const { REACT_APP_API_URL } = process.env;
import { createUrlParams } from 'utils/isValidate';

let access_token = localStorage.getItem('token');
axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;

export const driverListApi = async (token) => {
    return axios.get(`${REACT_APP_API_URL}drivers/list`)
};

export const addDriverApi = async (payload) => {
    return axios.post(`${REACT_APP_API_URL}driver/create`, payload)
};

export const editDriverApi = async (payload) => {
    return axios.patch(`${REACT_APP_API_URL}driver/update/${payload.driverId}`, payload)
};

export const getJobListApi = async () => {
    return axios.get(`${REACT_APP_API_URL}orders/transporter`)
};

export const cancelledJobsApi = async () => {
    return axios.get(`${REACT_APP_API_URL}orders/cancelled`)
};

export const deleteCancelledJobsApi = async (data) => {
    return axios.delete(`${REACT_APP_API_URL}cancelled-orders/delete/${data?.id}`)
};


export const deleteDriverApi = async (driverId) => {
    return axios.delete(`${REACT_APP_API_URL}driver/delete/${driverId}`)
}

export const assignOrderApi = async (data) => {
    return axios.post(`${REACT_APP_API_URL}orders/assign/transporter`, data)
};

export const listOfLoadingApi = async (data) => {
    return axios.get(`${REACT_APP_API_URL}orders/transporter/loadings?status=assigned`)
};

export const completedListOfLoadingApi = async (data) => {
    return axios.get(`${REACT_APP_API_URL}orders/transporter/loadings?status=delivered`)
};

export const invoiceListApi = async (queryParams) => {
    return axios.get(`${REACT_APP_API_URL}payment/list?${createUrlParams(queryParams)}`)
};

export const getBankDetailApi = async (data) => {
    return axios.get(`${REACT_APP_API_URL}bank-detail`)
};

export const addBankDetailApi = async (data) => {
    return axios.post(`${REACT_APP_API_URL}bank-detail/add`, data)
};

export const getLoadAssumptionsApi = async () => {
    return axios.get(`${REACT_APP_API_URL}load-assumption`)
};
